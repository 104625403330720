<template>
    <div class="sidebar-widget">
        <h2>Kapcsolatok (élek)</h2>
        <div class="widget-content">
            <p v-for="(edge, index) in edges" :key="index">{{edge.from}} - {{edge.to}}</p>
        </div>
    </div>
</template>

<script>

export default {
  name: 'EdgeList',
  props:{
    edges:Array
  },
  components: {
  }
}
</script>

<style scoped>
    .sidebar-widget{
        background: #790252;
        color: #A1C298;
        font-family: 'Josefin Sans', sans-serif;
        padding: 15px;
        margin: 15px 0;
        border-radius: 5px;
    }
    .sidebar-widget h2{
        font-style: italic;
        font-weight: 700;
        font-size: 1.2rem;
    }
    .sidebar-widget p{
        font-weight: 400;
        font-size: medium;
        line-height: 1;
    }
    .widget-content{
        max-height: 300px;
        overflow: auto;
    }
</style>
