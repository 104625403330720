<template>
    <div class="title">
        <h1>Beküldte: {{item.created_at}}</h1>
        <h3 v-if="item.created_in != null">{{item.created_in != '0000-00-00' ? 'Készült: ' + item.created_in : '' }}</h3>
    </div>
</template>

<script>

export default {
    name: 'Title',
    props:{
        item:Object
    },
    components: {
    },
    methods:{
    }
}
</script>

<style scoped>
    .title{
        color:white;
        font-family: 'Josefin Sans', sans-serif;
    }
    .title h1{
        font-size: clamp(1rem, 10vw, 2rem); 
        position: relative;
        padding-bottom: 10px;
    }
    .title h1:before{
        content:'';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 100px;
        max-width: 100%;
        background:#790252;
    }
    .title h3{
        color: grey;
    }
</style>
