<template>
    <!-- Header -->
    <header class="pm_header fixed_header">
      <div class="pm_fleft">
          <router-link class="pm_logo" style="color: white;" to="/">Értékmentők</router-link>
      </div><!-- pm_fleft -->
      <div class="pm_fright">
          <div class="pm_innerpadding_menu">
              <div class="pm_menu_cont">
                  <ul class="menu">
                      <li class="menu-item">
                          <router-link to="/kepek">Képek</router-link>
                      </li>
                      <li class="menu-item">
                          <router-link to="/csaladok">Családfa kutatás</router-link>
                      </li>
                  </ul><!-- menu -->
              </div><!-- pm_menu_cont -->
              <a class="pm_menu_mobile_toggler" href="#"></a>
              <span class="pm_share_button"></span>
          </div><!-- pm_innerpadding_menu -->
      </div><!-- pm_fright -->
  </header>

  <router-view style="padding-top: 74px;box-sizing: border-box;"></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>

</style>
