<template>
    <div class="pm_album_descriptions">

        <div class="pm_navigation_container">
            <div class="pm_prev_slide_button" @click="prev_slide()">
                <div class="pm_prev_thumb_cont"></div>
                <div class="pm_prev_button_fader"></div>
            </div>
            <div class="pm_next_slide_button" @click="next_slide()">
                <div class="pm_next_thumb_cont"></div>
                <div class="pm_next_button_fader"></div>
            </div>
        </div>

        <div class="pm_gallery_container gallery_descriptions">
            <div class="pm_gallery_wrapper">
                <div class="pm_visibility_block">
                    <ul class="pm_gallery effect_slide">
                        <li class="pm_gallery_item" id="item_1" :data-name="item.created_in" data-thumbnail="img/clipart/thumbs/thumb-04.jpg">
                            <img :src="item.drive_link" class="pm_mobile_image" alt="">
                            <div class="pm_slider_image_cont" :style="{'background' : 'url('+item.drive_link+') no-repeat center'}"></div>
                            <div class="pm_content_block">
                                <div class="pm_slider_description_cont cscrollbar">
                                    <div class="pm_slider_description_wrapper">
                                        <custom-title :item="item"></custom-title>
                                        <description :item="item"></description>
                                    </div>
                                </div>
                            </div>
                            <like-button></like-button>
                        </li>
                    </ul>
                    <div class="clear"></div>
                </div><!-- pm_visibility_block -->
            </div><!-- pm_gallery_wrapper -->
        </div><!-- pm_gallery_container -->
    </div><!-- pm_album_descriptions -->
</template>

<script>
// import jQuery from 'jquery';
// import { picturesData } from '../data';
import FirebaseStore from '../../store/FirebaseStore';
import LikeButton from '../elements/LikeButton';
import Title from '../elements/Title';
import Description from '../elements/Description';

export default {
    name: 'DescriptionPage',
    components: {
    LikeButton,
    "custom-title": Title,
    Description,
},
    data()
        {
        return {
            pictures:[],
            index: 2,
        }
    },
    computed: {
        item() {
            let n = this.pictures.length;
            return this.pictures[(this.index % n + n) % n];
        }
    },
    created() {
        this.store = new FirebaseStore();
        let my = this;
        this.store.getPicturesPaginated().then(function(result){
            console.log(result);
            my.pictures = result;
        });
    },
    mounted() {
        console.log(this.store);
    },
    updated() {
    
    },
    methods:{
        prev_slide(){
            this.index--;
        },
        next_slide(){
            this.index++;
        }
    }
}
</script>

<style scoped>
    .gallery_descriptions ul.pm_gallery li .pm_slider_image_cont {
        background-size: contain!important;
    }
    .gallery_descriptions ul.pm_gallery li .pm_slider_description_cont .pm_slide_title_wrapper{
        font-size: 21px;
        padding:0;
        line-height: normal;
    }
    .pm_slide_description p{
        color:white;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 20px;
    }
</style>