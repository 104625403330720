import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, doc, getDoc, getDocs, query, orderBy, limit, startAfter} from 'firebase/firestore';


class FirebaseStore{

    lastVisible = false;

    constructor() {
        const firebaseConfig = {
            apiKey: "AIzaSyD_hCpW7wlQeNkXIsNUc7rIZt15afJ7sF0",
            authDomain: "kepes-mult.firebaseapp.com",
            projectId: "kepes-mult",
            storageBucket: "kepes-mult.appspot.com",
            messagingSenderId: "814929335837",
            appId: "1:814929335837:web:86f7752f7f7a09d6b8d8f4",
            measurementId: "G-HRE59NY04R"
        };
        this.firebase = initializeApp(firebaseConfig);
        this.db = getFirestore(this.firebase);
        this.analytics = getAnalytics(this.firebase);
    }

    async savePicture(picture){
        console.log(this.db);
        console.log(this.$route);
        try {
            const docRef = await addDoc(collection(this.db, "pictures"), picture);
            console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    async saveDoc(entity, data){
        try {
            const docRef = await addDoc(collection(this.db, entity), data);
            console.log("Document written with ID: ", docRef.id);
            return docRef.id;
        } catch (e) {
            console.error("Error adding document: ", e);
            return false;
        }
    }

    async readDoc(entity, id){
        const docRef = doc(this.db, entity, id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
           return docSnap.data();
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    async readDocs(entity){
        const docSnap = await getDocs(collection(this.db, entity));
        return docSnap.docs.map(doc => doc.data());
    }

    async getPictures(){
        const docSnap = await getDocs(collection(this.db, "pictures"));
        return docSnap.docs.map(doc => doc.data());
    }

    async getPicturesPaginated(){
        if(this.lastVisible){
            const next = query(collection(this.db, "pictures"),
                orderBy("add_date"),
                startAfter(this.lastVisible),
                limit(25));

            const documentSnapshots = await getDocs(next);
            this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];

            return documentSnapshots.docs.map(doc => doc.data());
        }else{
            const first = query(collection(this.db, "pictures"), orderBy("add_date"), limit(25));
            const documentSnapshots = await getDocs(first);

            this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
            return documentSnapshots.docs.map(doc => doc.data());
        }
    }

}

export default FirebaseStore;