import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";

import App from './App.vue'
import VueExcelEditor from 'vue3-excel-editor'
import DescriptionPage from './components/description/DescriptionPage.vue'
import FamilyPage from './components/family/FamilyPage.vue'

const routes = [
    { path: '/', component: DescriptionPage },
    { path: '/kepek', component: DescriptionPage },
    { path: '/csaladok', component: FamilyPage },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const app = createApp(App);
app.use(router);
app.use(VueExcelEditor)
app.mount("#app");