<template>
    <div class="pm_slide_likes_wrapper">
        <div class="pm_add_like_button" @click="like()">
            <i class="pm_likes_icon" :class="{ 'fa fa-heart': liked, 'fa fa-heart-o': !liked }"></i>
            <span class="pm_likes_counter">{{counter}}</span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LikeButton',
    data() {
        return {
            counter:0,
            liked:false
        }
    },
    props:{

    },
    components: {
    },
    methods:{
        like(){
            this.liked ? this.counter-- : this.counter++;
            this.liked = !this.liked;
        }
    }
}
</script>

<style scoped>

</style>
