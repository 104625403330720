<template>
    <div class="main-frame">
        <div class="network-frame">
            <div class="network-canvas">
                <network-canvas
                    v-show="nTab"
                    ref="network"
                    :nodes="network.nodes"
                    :edges="network.edges"
                    :options="network.options"
                    >
                </network-canvas>
                <table-editor v-show="!nTab"></table-editor>
            </div>
            <div class="network-controlls" v-if="false">
                <h2>Network controlls</h2>
            </div>
        </div>
        <div class="sidebar-frame">
            <button v-on:click="nTab= !nTab">Toogle</button>
            <node-list :nodes="network.nodes"></node-list>
            <edge-list :edges="network.edges"></edge-list>
        </div>
    </div>
</template>

<script>
import NetworkCanvas from './NetworkCanvas.vue';
import NodeList from './NodeList.vue';
import EdgeList from './EdgeList.vue';
import TableEditor from './TableEditor.vue';
export default {
  name: 'FamilyPage',
  components: {
    NetworkCanvas,
    NodeList,
    EdgeList,
    TableEditor
  },
  data: () => ({
    nTab:true,
    network: {
      nodes: [
        { id: 1, label: 'J.Cs', name: 'Joó Csaba' },
        { id: 2, label: 'J.N',  name: 'Joó Nándor' },
        { id: 3, label: 'J.Zs',  name: 'Joó Zsuzsánna' },
        { id: 4, label: 'J.Z.Cs',  name: 'Joó Zoltán Csaba' },
        { id: 5, label: 'J.D',  name: 'Joó Dénes' },
        { id: 6, label: 'J.O',  name: 'Joó Ottília' },
        { id: 7, label: 'J.Gy',  name: 'Joó Gyula' },
        { id: 8, label: 'B.M',  name: 'Bencze Mózes' },
        { id: 9, label: 'B.A',  name: 'Bencze Arnold' },
        { id: 10, label: 'B.E',  name: 'Bencze Erzsébet' },
      ],
      edges: [
        {from: 1, to: 2 },
        {from: 1, to: 4 },
        {from: 5, to: 1 },
        {from: 5, to: 7 },
        {from: 3, to: 2 },
        {from: 3, to: 4 },
        {from: 6, to: 1 },
        {from: 6, to: 7 },
        {from: 8, to: 3 },
        {from: 8, to: 9 },
        {from: 10, to: 3 },
        {from: 10, to: 9 },
      ],
      options: {
        nodes: {
            shape: 'circle',
        },
        edges:{
            arrows: {
                to: {
                    enabled: true,
                    type: "arrow"
                },
            },
        },
        manipulation: {
            enabled: true
        },
        // layout:{
        //     hierarchical:{
        //         enabled: true,
        //         parentCentralization: true,
        //         direction: 'UD',        // UD, DU, LR, RL
        //         sortMethod: 'directed',  // hubsize, directed
        //         shakeTowards: 'roots'  // roots, leaves
        //     }
        // }
      },
    },
  }),
  created(){

  }
}
</script>

<style>
    .main-frame{
        display: flex;
        height: 100vh;
    }
    .network-frame{
        flex-grow: 2;
        display: flex;
        flex-direction: column;
    }
    .network-canvas{
        height: 100%;
        background: white;
    }
    .network-controlls{
        border: 1px solid #790252;
        padding: 10px;
    }
    .sidebar-frame{
        flex-grow: 1;
        padding: 15px;
        overflow: auto;
    }
    </style>
