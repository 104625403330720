<template>
    <div class="description">
        <p>{{item.meta_keys}}</p>
        <div class="badge-container" v-if="item.present_people != ''">
            <span v-for="person in people" :key="person" class="person-badge">{{person}}</span>
        </div>
        <p class="long">{{item.description}}</p>
    </div>
</template>

<script>

export default {
    name: 'Description',
    props:{
        item:Object
    },
    computed: {
        people() {
            if(this.item.present_people){
                return this.item.present_people.split(',');
            }
            return [];
        }
    },
    components: {
    },
    methods:{
    }
}
</script>

<style scoped>
.description p{
    color:white;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
}
.description .long{
    font-size: 25px;
}
.badge-container{
    text-align: justify;
}
.person-badge{
    color:#790252;
    font-weight: bold;
    background: white;
    padding: 5px;
    margin: 5px;
    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
}
</style>
