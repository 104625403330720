<template>
    <div class="table">
        <vue-excel-editor v-model="data">
            <vue-excel-column field="page" label="Oldal" type="string" :autoFillWidth="true" />
            <vue-excel-column field="main" label="Családfő" type="string" :autoFillWidth="true" />
            <vue-excel-column field="child" label="Gyerek" type="string" :autoFillWidth="true" />
            <vue-excel-column field="village" label="Település" type="string" :autoFillWidth="true" />
            <vue-excel-column field="hint" label="Megjegyzés" type="string" :autoFillWidth="true" />
        </vue-excel-editor>
    </div>
</template>

<script>
import {data} from '../../utils/family-data';

export default {
    name: 'Table Editor',
    data() {
        return {
            data:[],
        }
    },
    components: {
    },
    created(){
        // this.data = data.slice(0,50);
        this.data = data;
    }
}
</script>

<style>

</style>
