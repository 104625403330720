export const data = [
{
	"page": "I.1", 
	"main": "Bató Sándor 1843-", 
	"child": "Bató Lidia 1871-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Molnár Juliánna", 
	"child": "Bató Mihály 1874-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.2", 
	"main": "Ipó Péter 1851-", 
	"child": "Ipó Péter 1875-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Lakatos Rebeka 1854-", 
	"child": "Ipó Klára 1877-1970", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Ipó Rebeka 1879-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Ipó Lajos 1882-1890", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Ipó József 1884-1887", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Ipó Juliánna 1886-", 
	"village": "Városfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Ipó Mária 1888", 
	"village": "Telekfalva", 
	"hint": "Ilyés Ferencné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Ipó Zsuzsánna 1889-", 
	"village": "Városfalva", 
	"hint": ""}, 
{
	"page": "I.3", 
	"main": "Molnár István 1842-", 
	"child": "Molnár Ferenc 1865-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Ipó Borbára 1842-", 
	"child": "Molnár Ágnes 1868-", 
	"village": "", 
	"hint": " -lásd I.138.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Molnár Gergely 1870-", 
	"village": "Fogaras", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Molnár Lidia 1873-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Molnár Mária 1875-1894", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Molnár Imre 1878-1938", 
	"village": "Amerikában meghalt", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Molnár Borbára 1881-1881", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Molnár Borbára 1883-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Molnár Sándor 1887-", 
	"village": "", 
	"hint": "-lásd II.1. lap"}, 
{
	"page": "I.4", 
	"main": "Gelért Ferenc 1836-", 
	"child": "Gelért Lajos 1862-", 
	"village": "", 
	"hint": "-lásd I.140.lap"}, 
{
	"page": "", 
	"main": "Gelért Sára 1837-   lásdI.125", 
	"child": "Gelért Ferenc 1864-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért János 1866-1884", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Márton 1868-1869", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Ágnes 1870-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Pál", 
	"village": "", 
	"hint": "házasságon kivül sz."}, 
{
	"page": "I.5", 
	"main": "ifj.Balázsi Sándor 1836-", 
	"child": "legifj.Balázsi Sándor 1863-", 
	"village": "", 
	"hint": "lásd I.159.lap"}, 
{
	"page": "", 
	"main": "Gelért Juliánna 1841-", 
	"child": "Balázsi Rozália 1869-1869", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Ágnes 1872-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Miklós 1875-1941", 
	"village": "", 
	"hint": "lásd  I.186.lap"}, 
{
	"page": "I.6", 
	"main": "Balázsi Péter 1828-1888", 
	"child": "Balázsi Mária 1849-", 
	"village": "Olasztelek", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Sata Mária ", 
	"child": "Balázsi István 1852-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Rákhel 1854-", 
	"village": "", 
	"hint": "lásd I.122.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi András 1857-1858", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi András 1859-1859", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Péter 1861-1861", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Péter 1863-", 
	"village": "", 
	"hint": "lásd I.142.lap"}, 
{
	"page": "I.7", 
	"main": "ifj.Demeter János 1821-", 
	"child": "Demeter József 1845-1866", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Juliánna 1823-1883", 
	"child": "Demeter Rozália 1848-", 
	"village": "", 
	"hint": "lásd I.14.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Demeter Péter 1850-1851", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Demeter Mária 1852-", 
	"village": "Szentkatolna", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Demeter Péter 1855-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Demeter Juliánna 1857-", 
	"village": "Dálya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Demeter János 1860-", 
	"village": "Dálya ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Demeter Ferenc 1862-1883", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.8", 
	"main": "Pál Sámuel           -1886", 
	"child": "Bencze Mózes 1851-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Rebeka 1818-", 
	"child": "Pál Mária 1858-1865", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.9", 
	"main": "id.Bencze Mihály 1832-", 
	"child": "Bencze Gergely 1855-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Mária 1836-", 
	"child": "Bencze Mária 1858-", 
	"village": "", 
	"hint": "lásd I.31.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Juliánna 1860-", 
	"village": "", 
	"hint": "lásd I.52.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze András 1862-1862", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Zsigmond 1864-", 
	"village": "", 
	"hint": "lásd I.150.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Rozália 1866-1866", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Rozália 1867-", 
	"village": "", 
	"hint": "lásd I. 151.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mózes 1870-", 
	"village": "Amerikában meghalt", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze János 1873-1874", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Lidia 1875-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze János 1876-", 
	"village": "Amerikában meghalt", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Krisztina 1878-1879", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Lajos 1883-", 
	"village": "Kolozsváron halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mária 1887-1888", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.10", 
	"main": "Bencze István 1814-1885", 
	"child": "Bencze Anna 1835-1836", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Czerják Mária        -1892", 
	"child": "Bencze Rozália 1836-1838", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "(Lókodban született )", 
	"child": "Bencze Sándor 1838-", 
	"village": "Lókod", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Rákhel 1841-", 
	"village": "", 
	"hint": "lásd I.44.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mózes 1843-1844", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze András 1845-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Lidia 1848-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.11", 
	"main": "ifj.Orbán József 1851-1935", 
	"child": "Orbán Lajos 1876-1935", 
	"village": "Háborúban meghalt", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Rozália 1857-", 
	"child": "Orbán Anna 1879-", 
	"village": "Fogaras", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán József 1884-1884", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán István 1884-1884", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Berta 1884-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Mária 1885-1972", 
	"village": "Hosszúfalú", 
	"hint": "gyermektelen"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Zsuzsánna 1887-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Rozália 1890-1890", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Rozália 1891-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Eszter 1894-1973", 
	"village": "", 
	"hint": "gyermektelen"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Irma 1897-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.12", 
	"main": "id. Orbán József 1820-1894", 
	"child": "Orbán Lajos 1840-", 
	"village": "", 
	"hint": "lásd I.66.lap"}, 
{
	"page": "", 
	"main": "Derzsi Mária              -1874", 
	"child": "Orbán Mária 1843-", 
	"village": "", 
	"hint": "lásd I.61.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Véri 1846-1935", 
	"village": "", 
	"hint": "lásd I.84.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Rozália 1848-", 
	"village": "", 
	"hint": "lásd I.34.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán József 1851-1935", 
	"village": "", 
	"hint": "lásd I.11.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Mózes 1855-1934", 
	"village": "", 
	"hint": "lásd I.13.lap "}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Anna 1859-", 
	"village": "", 
	"hint": "lásd I.117.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Juliánna 1862-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.13", 
	"main": "Orbán Mózes 1855-1934", 
	"child": "Orbán Juliánna 1880-1972", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázs Juliánna ", 
	"child": "Orbán János 1883-", 
	"village": "", 
	"hint": "lásd I.183.lap"}, 
{
	"page": "", 
	"main": "(Lókodban született )", 
	"child": "Orbán Rebeka 1885-", 
	"village": "Bukarest", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Mózes ", 
	"village": "Háborúban meghalt", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán József 1896-", 
	"village": "", 
	"hint": "lásd II.31.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Mihály 1898-1969", 
	"village": "", 
	"hint": "lásd II.31.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Ágnes ", 
	"village": "Budapesten halt meg", 
	"hint": ""}, 
{
	"page": "I.14", 
	"main": "Gelért Mózes 1845-1885", 
	"child": "Gelért Mária 1872-1874", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Demeter Rozália 1845-", 
	"child": "Gelért Mária 1874-1880", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Márton 1876-", 
	"village": "Budapesten halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Tamás 1879-1968", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Anna 1883-", 
	"village": "Budapesten halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Ágnes  ", 
	"village": "", 
	"hint": "lásd I.146.lap"}, 
{
	"page": "I.15", 
	"main": "Gelért Lajos 1833-1874", 
	"child": "Gelért Pál 1863-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Biró Zsuzsa        -1875", 
	"child": "Gelért Lajos 1865-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Zsigmond 1867-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.16", 
	"main": "Gelért Sándor 1805-1854", 
	"child": "Gelért Lajos 1833-", 
	"village": "", 
	"hint": "lásd I.15.lap"}, 
{
	"page": "", 
	"main": "Lőrincz Rákhel 1808-1870", 
	"child": "Gelért Ferenc 1836-", 
	"village": "", 
	"hint": "lásd I.4.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Sándor 1837-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Rebeka 1840-", 
	"village": "", 
	"hint": "lásd I.54.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Mihály 1842-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Dávid 1845-", 
	"village": "Homoródszentpál", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért István 1848-", 
	"village": "Barót", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Rákhel 1851-", 
	"village": "", 
	"hint": "lásd I.36.lap"}, 
{
	"page": "I.17", 
	"main": "Csoma Ferenc 1847-1873", 
	"child": "Csoma Ferenc ", 
	"village": "Fogaras", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Biró (Nagy) Anna", 
	"child": "Csoma Rebeka 1873-1875", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Biró (Nagy) Lajos 1876-1936", 
	"village": "Brassó", 
	"hint": "a férj halála után sz."}, 
{
	"page": "", 
	"main": "", 
	"child": "Biró (Nagy) Rebeka 1881-", 
	"village": "", 
	"hint": "a férj halála után sz."}, 
{
	"page": "I.18", 
	"main": "Fodor János 1813-1864", 
	"child": "Fodor János 1839-1863", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Forró Mária          -1874", 
	"child": "Fodor Mária 1841-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Zsofi 1844-1863", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Liza 1847-", 
	"village": "Dálya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Mihály 1851-1851", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Mihály 1854-", 
	"village": "", 
	"hint": "lásd I.132.lap"}, 
{
	"page": "I.19/1", 
	"main": "id.Fodor Mózes 1822-1887", 
	"child": "Fodor Mózes 1848-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Rákhel 1820-1875", 
	"child": "Fodor Mózes 1850-", 
	"village": "", 
	"hint": "lásd I.19.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Rozália 1859-1888", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Gábor 1862-", 
	"village": "", 
	"hint": "lásd I.148.lap"}, 
{
	"page": "I.19/2", 
	"main": "Fodor Mózes 1850-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Sándor Mária 1857-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "I.20", 
	"main": "Zolya Ferenc1842-", 
	"child": "Zolya Ferenc 1867-", 
	"village": "", 
	"hint": "lásd I.151.lap"}, 
{
	"page": "", 
	"main": "Veres Rákhel", 
	"child": "Zolya József 1870-1871", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zolya József 1871-1871", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zolya István 1873-1874", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zolya József 1875-", 
	"village": "Amerika", 
	"hint": ""}, 
{
	"page": "I.21", 
	"main": "Zolya Mózes 1835-1886", 
	"child": "Zolya Lidia 1862-1883", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Kelemen Mária", 
	"child": "Zolya Gergely 1863-", 
	"village": "", 
	"hint": "lásd I.143.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zolya Juliánna 1865-", 
	"village": "", 
	"hint": "lásd I.135.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zolya Mária 1867-1875", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zolya Véri 1871-", 
	"village": "Ége ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zolya József 1873-1874", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zolya Mária 1876-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zolya józsef 1810-1884", 
	"village": "", 
	"hint": "a családfő atyja"}, 
{
	"page": "I.22", 
	"main": "Gelért Péter 1837-", 
	"child": "Gelért Mária ", 
	"village": "", 
	"hint": "lásd .104.lap"}, 
{
	"page": "", 
	"main": "Kuti Anna", 
	"child": "Gelért Anna 1866-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelért Sándor 1890-", 
	"village": "a fenti G.Anna házas-", 
	"hint": "ságon kivüli fia"}, 
{
	"page": "I.23", 
	"main": "Dáné Mózes 1893-", 
	"child": "Dáné Mária 1857-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "első neje", 
	"main": "Balázsi Judith 1834-1868", 
	"child": "Dáné Mózes 1860", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné József 1862-", 
	"village": "Amerikában meghalt", 
	"hint": "lásd I.152.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Ferenc 1865-", 
	"village": "", 
	"hint": "lásd I.160.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Zsigmond 1868-1868", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2. neje", 
	"main": "Orbán Anna       -1879", 
	"child": "Dáné Zsigmond 1869-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Áron 1873-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Anna 1879-1879", 
	"village": "", 
	"hint": ""}, 
{
	"page": "3. neje ", 
	"main": "Szente Anna", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.24", 
	"main": "id. Balási Lajos 1818-1881", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "1. neje", 
	"main": "Orbán Mária 1810-1859", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2. neje", 
	"main": "Simó Rozália ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Áron 1795-1886", 
	"child": "a családfő öreg atyja", 
	"village": "", 
	"hint": "lásd I.94.lap"}, 
{
	"page": "I.25", 
	"main": "Dáné Lajos 1832-", 
	"child": "Dáné Mária 1855-1855", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Anna 1836-", 
	"child": "Dáné Anna 1857-", 
	"village": "", 
	"hint": "lásd I.68. lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Lajos 1859-", 
	"village": "", 
	"hint": "lásd I.133.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Lidia 1861-", 
	"village": "Dálya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Károly 1863-1864", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné József 1865-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Regina 1867-", 
	"village": "", 
	"hint": "lásd I.137.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Sándor 1869-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Eliza 1873-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Mária 1874-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Zsuzsánna 1876-", 
	"village": "Ége ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Rebeka 1881-1881", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.26", 
	"main": "Kelemen Sándor 1830-", 
	"child": "Kelemen Mária 1863-1863", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Kovács Rebeka 1843-1879", 
	"child": "Kelemen Rozália 1864-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kelemen Mária 1866-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kelemen Lajos 1868-1935", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kelemen Ferenc1870-1889", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kelemen Ágnes 1872-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kelemen Juliánna 1875-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kelemen Rebeka 1876-1877", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.27", 
	"main": "Bathó Márton 1809-1895", 
	"child": "Bathó István 1833-1834", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Farkas Zsuzsánna ", 
	"child": "Bató Mária 1835-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Zsuzsánna 1837-1837", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Rebeka 1840-1841", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató István 1842-", 
	"village": "", 
	"hint": "lásd I.28.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Lajos 1844-1866", 
	"village": "", 
	"hint": "csatába esett el"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Zsuzsánna 1851-", 
	"village": "", 
	"hint": "lásd I.98.lap"}, 
{
	"page": "", 
	"main": "Bató Zsuzsánna 1805-1893", 
	"child": "a családfő öreg,özv.testvére", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.28", 
	"main": "Bató István 1842-", 
	"child": "Bató Rebeka 1866-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zakariás Rebeka ", 
	"child": "Bató Lajos 1868-", 
	"village": "", 
	"hint": "öngyilkosság"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Regina 1870-1870", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató János 1871-", 
	"village": "Telekfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Márton 1874-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató József 1876-", 
	"village": "Dálya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Regina 1878-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Sándor 1880-", 
	"village": "Sárkány ", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Zsuzsa 1883-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Ferenc 1888-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "I.29", 
	"main": "Baczó Sándor 1833-", 
	"child": "Baczó Klára 1859-1861", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Beke Liza 1836-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I. 30", 
	"main": "Lőrincz András 1828-", 
	"child": "Lőrincz Rozália 1839-", 
	"village": "a családfő világtalan", 
	"hint": "nővére"}, 
{
	"page": "", 
	"main": "Timár Rozália ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.31", 
	"main": "Demeter István 1852-", 
	"child": "Demeter Zsigmond 1875-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Mária 1858-", 
	"child": "Demeter Rebeka 1878-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Demeter András 1806-1892", 
	"village": "", 
	"hint": "a családfő nagyatyja"}, 
{
	"page": "", 
	"main": "", 
	"child": "Demeter Lajos 1860-", 
	"village": "Dálya", 
	"hint": "a családfő testvére"}, 
{
	"page": "I.32", 
	"main": "id.Sándor Lajos 1843-", 
	"child": "Sándor Anna 1867-", 
	"village": "", 
	"hint": "lásd I.133lap"}, 
{
	"page": "1.neje ", 
	"main": "Beke Zsuzsánna 1843-1890", 
	"child": "Sándor Lajos 1870-1871", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Sándor Lajos 1872-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Zsigmond 1876-1877", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Zsigmond 1878-1878", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Zsigmond 1880-1913", 
	"village": "Marosvásárhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Miklós 1884-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Mária 1886-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Fóris Zsuzsánna (Gyepes)", 
	"child": "idős korukba házasodtak", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.33", 
	"main": "Sándor Mózes 1818-", 
	"child": "id.Sándor Lajos 1843-", 
	"village": "", 
	"hint": "lásd I.32.lap"}, 
{
	"page": "", 
	"main": "Nagy Mária", 
	"child": "Sándor Mária 1845-", 
	"village": "", 
	"hint": "lásd I.110.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Anna 1855-1858", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.34", 
	"main": "Nagy József 1850-", 
	"child": "Nagy József 1874-", 
	"village": "Fogaras", 
	"hint": "házasság előtt sz."}, 
{
	"page": "", 
	"main": "Orbán Rozália 1848-", 
	"child": "Nagy Sándor 1876-", 
	"village": "Fogaras", 
	"hint": "Fogarasban halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy László 1878", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Ignác 1871-", 
	"village": "", 
	"hint": "házasságon kívül sz."}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Mária 1881-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.35", 
	"main": "Nagy János 1817-", 
	"child": "Nagy Juliánna 1840-", 
	"village": "", 
	"hint": "lásd I.50.lap"}, 
{
	"page": "1.neje ", 
	"main": "Balássi Zsuzsánna 1818-1852", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "elváltak", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Bencze Mária 1824-1862", 
	"child": "Nagy Rozália 1845-", 
	"village": "", 
	"hint": "lásd I.49.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy István 1847-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy József 1850-", 
	"village": "", 
	"hint": "lásd I.34.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Gergely 1853-", 
	"village": "", 
	"hint": "lásd I.134.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy János 1855-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Lajos 1859-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Károly 1862-", 
	"village": "Kecset", 
	"hint": ""}, 
{
	"page": "I.36", 
	"main": "Gelérd József 1850-", 
	"child": "Gelérd Juliánna 1873-", 
	"village": "Háromszék", 
	"hint": "házasság előtt sz."}, 
{
	"page": "", 
	"main": "Gelérd Rákhel 1851-", 
	"child": "Gelérd Rebeka 1876-", 
	"village": "Dálya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd József 1881-1881", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Mária 1882-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd József 1885-1887", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Rákhel 1887-", 
	"village": "Almás ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Rozália 1889-1891", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Zsigmond 1891-1892", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd János 1891-1891", 
	"village": "Gelérd Juliánna törvénytelen gyermeke", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd János 1893-1893", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Ágnes 1893-", 
	"village": "Budapesten halt meg", 
	"hint": "az apa törvénytelen gy."}, 
{
	"page": "I.37", 
	"main": "id.Baczó Dénes úr 1841-", 
	"child": "Baczó Zsuzsa 1864-", 
	"village": "Petek", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze JUliánna1845-", 
	"child": "Baczó Albert 1866-1926", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Dénes 1869-1953", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Rozália 1872-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Balázs 1875-", 
	"village": "Sárospatak", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Gyula 1881-", 
	"village": "", 
	"hint": "héborúban elesett"}, 
{
	"page": "", 
	"main": "", 
	"child": "Krizbai Eszter      -1884", 
	"village": "", 
	"hint": "a családapának anyja"}, 
{
	"page": "I.38", 
	"main": "id.Baczó Mózes  úr 1831-", 
	"child": "Baczó Rozália 1855-", 
	"village": "Karácsonyfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Mária 1833-", 
	"child": "Baczó Mária 1857", 
	"village": "Homoródszentpál ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Eszter 1861-", 
	"village": "Szarvas", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Berta 1863-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Katalin 1865-1866", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Katalin 1867-", 
	"village": "Székelykeresztúr", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Mózes 1869- ", 
	"village": "Szászváros ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Gábor 1874-1953", 
	"village": "", 
	"hint": "lásd I.176.lap"}, 
{
	"page": "I.39", 
	"main": "Nagy Zsigmond 1838-", 
	"child": "Nagy Mária 1867-", 
	"village": "Kányád,Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Pál Juliánna 1847-", 
	"child": "Nagy Zsigmond 1869-1870", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Lidia 1872-1956", 
	"village": "", 
	"hint": "lásd I.108.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy zsigmond 1874-", 
	"village": "", 
	"hint": "háborúban elesett"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Dénes 1880-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Nagy Ferenc 1883-1969", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "id.Nagy Ferenc 1807-1882", 
	"village": "", 
	"hint": "a családfő atyja"}, 
{
	"page": "I.40", 
	"main": "Nagy Mózes 1835-", 
	"child": "Nagy Mózes 1863-1935", 
	"village": "", 
	"hint": "lásd I.146.lap"}, 
{
	"page": "", 
	"main": "Bencze Juliánna 1837-1876", 
	"child": "Nagy András 1865-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Lajos 1868-1870", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Lajos 1872-", 
	"village": "Brassó", 
	"hint": "nincs utód"}, 
{
	"page": "I.41", 
	"main": "Gelérd András 1844-", 
	"child": "Gelérd Ferenc 1876", 
	"village": "Fogaras", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Kovács Kata 1852-", 
	"child": "Gelérd Mária 1878-", 
	"village": "", 
	"hint": "férjezetlen "}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Anna 1880-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.42", 
	"main": "Kovács(Varga)András 1849-", 
	"child": "Kovács Mária 1876-", 
	"village": "Fogaras", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Nagy(Pali)Zsuzsánna1854-", 
	"child": "Kovács Rozália 1879-", 
	"village": "Fogaras", 
	"hint": ""}, 
{
	"page": "I.43", 
	"main": "Kovács(Varga)István  1838--", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Molnár Kata 1835-1889", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "I.44", 
	"main": "ifj.Lőrincz János 1834-", 
	"child": "Lőrincz Rebeka 1864-1894", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Rákhel 1841-1892", 
	"child": "Lőrincz Péter 1866-", 
	"village": "Amerikában halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Márton 1869-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz János 1873-1873", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Pál 1880-1881", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Mária 1882-", 
	"village": "Budapest ", 
	"hint": ""}, 
{
	"page": "I.45", 
	"main": "Sípos József 1844-", 
	"child": "Sípos Pál 1873-1891", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje ", 
	"main": "Péter Mária ", 
	"child": "Sípos Mária 1874-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "elváltak", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Görög Rákhel", 
	"child": "Sípos József 1878-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sípos Berta 1880-1969", 
	"village": "", 
	"hint": "lásd I.176.lap"}, 
{
	"page": "I.46", 
	"main": "Veres Ferenc 1828", 
	"child": "", 
	"village": "", 
	"hint": "református lelkész"}, 
{
	"page": "", 
	"main": "Benedek Ágnes 1837-1892", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Fábián Márton      -1910", 
	"child": "", 
	"village": "", 
	"hint": "református lelkész"}, 
{
	"page": "", 
	"main": "Szabó Anna 1872-1913", 
	"child": "Fábián Károly 1894-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Fábián Ferenc 1896-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Fábián Anna 1898-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.47", 
	"main": "ifj.Balázsi József 1837-", 
	"child": "Balázsi András 1860-1860", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Lidia 1837-", 
	"child": "Balázsi Zsigmond 1861-", 
	"village": "", 
	"hint": "lásd I.135.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Áron 1865-", 
	"village": "Kénos ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Domokos 1869-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi András 1873-", 
	"village": "", 
	"hint": "meghalt fiatalon"}, 
{
	"page": "I.48", 
	"main": "ifj.Bencze Zsigmond 1848-", 
	"child": "Bencze Márton 1870-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Beke Rebeka 1850-", 
	"child": "Bencze Imre 1873-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Gábor 1875-1879", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Gergely 1878-", 
	"village": "Amerikában halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Zsuzsa 1881-1882", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Zsuzsa 1883-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Gábor 1886-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Zsigmond 1890-", 
	"village": "", 
	"hint": "háborúban elesett"}, 
{
	"page": "I.49", 
	"main": "Baczó István úr 1846-", 
	"child": "Baczó Ágnes 1869-1887", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Nagy Rozália 1844-", 
	"child": "Baczó László 1872-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Klára 1874-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Vilma 1876-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Sándor 1879-", 
	"village": "Budapest ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Rozália 1882-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "I.50", 
	"main": "id.Bencze Zsigmond 1841-1895", 
	"child": "Bencze Rozália 1863-1894", 
	"village": "Kányád", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Nagy Juliánna 1840-", 
	"child": "Bencze Regina 1868-", 
	"village": "", 
	"hint": "lásd I.143.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Berncze Mária 1870-1871", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Márton 1872-", 
	"village": "Amerikában halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Domokos 1875-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Balázs 1879-", 
	"village": "", 
	"hint": "lásd I.169.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze András 1882-1882", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Lajos 1882-1882", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Dénes 1884-1971", 
	"village": "", 
	"hint": "lásd II.4.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Zsuzsa 1884-", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "I.51", 
	"main": "Beke Ferenc 1834-", 
	"child": "Beke Mária 1857-", 
	"village": "", 
	"hint": "lásd I.121.lap"}, 
{
	"page": "", 
	"main": "Gelérd Ágnes 1835-", 
	"child": "Beke Lidia 1859-1860", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Zsigmond 1860-", 
	"village": "", 
	"hint": "lásd I.137.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke János 1863-", 
	"village": "", 
	"hint": "lásd I.141.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Lidia 1866-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Juliánna 1869-", 
	"village": "Lisznyó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Zsuzsa 1871-1969", 
	"village": "", 
	"hint": "Élt 97 évet"}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Ferenc 1874-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Beke Lidia 1866-", 
	"child": "Beke Gyula 1888-1891", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Beke Lidia hajadon-nak 3", 
	"child": "Beke Zsuzsa 1890-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "házasságon kivüli gyermeke ", 
	"child": "Beke Dénes 1892-", 
	"village": "Amerikában halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "született", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.52", 
	"main": "ifj.Bencze Ferenc 1857-", 
	"child": "", 
	"village": "", 
	"hint": "lásd I.93.lap"}, 
{
	"page": "", 
	"main": "Bencze Juliánna 1860-", 
	"child": "", 
	"village": "", 
	"hint": "lásd I.9.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mária 1882-1895", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Juliánna 1885-1976", 
	"village": "", 
	"hint": "lásd I.168.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Miklós 1890-1916", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ágnes 1893-1969", 
	"village": "", 
	"hint": "lásd II.16.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mária 1898-", 
	"village": "", 
	"hint": "lásd II.24.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Dávid 1902-", 
	"village": "", 
	"hint": "lásd II.58.lap"}, 
{
	"page": "I.53", 
	"main": "Hogyai János 1830-", 
	"child": "Hogyai Rozália 1857-", 
	"village": "Bikafalva ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Juliánna 1835-", 
	"child": "Hogyai Mária 1860-", 
	"village": "", 
	"hint": "lásd I.79.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Hogyai János 1863-1885", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Hogyai Elek 1866-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Hogyai Berta 1868-1884", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Hogyai Juliánna 1870-1879", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Hogyai Zsuzsa 1872-", 
	"village": "", 
	"hint": "lásd I.157.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Hogyai Katalin 1875-1877", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Hogyai Regina 1877-1892", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Hogyai József 1879-1882", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.54", 
	"main": "Barczán János 1828-", 
	"child": "Barczán János 1863-1865", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gelérd Rebeka 1840-", 
	"child": "Barczán János 1865-1865", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Mária 1866-", 
	"village": "", 
	"hint": "lásd I.146.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Zsuzsa 1869-1883", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán János 1872-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Mihály 1875-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Lidia 1880-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.55", 
	"main": "Barczán Pál 1832-1855", 
	"child": "Barczán Lajos 1856", 
	"village": "", 
	"hint": "lásd I. 147.lap"}, 
{
	"page": "1.neje", 
	"main": "Balázsi Juliánna 1835-1870", 
	"child": "Barczán Juliánna 1859-", 
	"village": "Bikafalva ", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Kovács Rebeka 1846-", 
	"child": "Barczán Mózes 1863-1870", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Rákhel 1867-1868", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Mózes 1874-1882", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Ágnes 1878-1892", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán József 1881-1883", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Ferenc 1881-", 
	"village": "Recsenyéd ", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán József 1884-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Rákhel 1888-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.56", 
	"main": "Barczán Sándor 1830-", 
	"child": "Barczán Rozália ", 
	"village": "Lókod ", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Pál Anna ", 
	"child": "Barczán István ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Mária 1866-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Zsigmond 1873-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Hérrci Rozália", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.57", 
	"main": "Barczán István 1835-1891", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Lőrincz Kriska", 
	"child": "", 
	"village": "", 
	"hint": "elváltak"}, 
{
	"page": "2.neje ", 
	"main": "Bánki Juliánna      -1876", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "3.neje", 
	"main": "Jó Mária 1853-", 
	"child": "Barczán József 1876-1876", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Juliánna 1878-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Lajos 1883-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.58", 
	"main": "Ipó Rozália 1827-", 
	"child": "Ipó Ferenc 1846-", 
	"village": "Fogaras", 
	"hint": ""}, 
{
	"page": "", 
	"main": "férjtelen ", 
	"child": "Ipó Mózes 1848-1876", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "a gyermekek mind házasságon ", 
	"child": "Ipó Péter 1851-", 
	"village": "", 
	"hint": "lásd I.2-.lap"}, 
{
	"page": "", 
	"main": "kívül születtek", 
	"child": "Ipó Rákhel 1854-1855", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Ipó József 1856-", 
	"village": "Recsenyéd ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Ipó Lajos 1859-", 
	"village": "Recsenyéd ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Ipó Klára 1862-1864", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Ipó Rozália 1865-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.59", 
	"main": "Kovács Mózes 1850-", 
	"child": "Kovács Ágnes 1874-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Terézia 1851-", 
	"child": "Kovács Domokos 1876-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács János 1879-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "egy fia halva született 1881", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.60", 
	"main": "ifj.Bencze Mihály 1844-", 
	"child": "Bencze Rozália 1865-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bató Lidia 1845-", 
	"child": "Bencze Dénes 1868-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Albert 1870-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Borbála 1873-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze József 1874-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Lidia 1876-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Géza 1879-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ilona 1884-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.61", 
	"main": "Bencze József 1841-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "", 
	"main": "Orbán Mária 1843-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "I.62", 
	"main": "Balázsi József 1823-", 
	"child": "Balázsi Juliánna 1847-1855", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Lidia 1827-", 
	"child": "Balázsi Ferenc 1850-", 
	"village": "", 
	"hint": "lásd I.63.lap"}, 
{
	"page": "I.63", 
	"main": "Balázsi Ferenc 1850-1929", 
	"child": "Balázsi János 1876-1912", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Krisztina 1852-1936", 
	"child": "Balázsi Berta 1878-1882", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Dénes 1881-1882", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Dénes 1883-1883", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Berta 1884-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi József 1892-", 
	"village": "", 
	"hint": "háborúban elesett"}, 
{
	"page": "I.64", 
	"main": "Sándor József 1840-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "", 
	"main": "Menyhárt Mária ", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "I.65", 
	"main": "Sándor Elek 1819-", 
	"child": "Sándor Juliánna 1847-1879", 
	"village": "", 
	"hint": "lásd I.71.lap"}, 
{
	"page": "", 
	"main": "Sándor Juliánna ", 
	"child": "Sándor Borbára 1849-1850", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Borbára 1851-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Rozália 1856-1861", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Rozália 1864-", 
	"village": "", 
	"hint": "lásd I.131.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Lajos 1866-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "a fenti Sándor Borbára ", 
	"child": "Sándor István 1886-", 
	"village": "", 
	"hint": "törvénytelen gyermeke "}, 
{
	"page": "", 
	"main": "a fenti Sándor Rozália ", 
	"child": "Sándor Lidia 1888-", 
	"village": "", 
	"hint": "törvénytelen gyermeke "}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor András 1892-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.66", 
	"main": "Orbán Lajos 1840-", 
	"child": "Orbán Rebeka 1862-1884", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Rebeka 1843-", 
	"child": "Orbán Ferenc 1867-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán István 1870-1871", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Mária 1874-", 
	"village": "Kányád", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Lidia 1876-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Zsigmond 1881-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "a fenti,hajadon Orbán Rebeka ", 
	"child": "Orbán Dénes 1884-1884", 
	"village": "", 
	"hint": "törvénytelen gyermeke "}, 
{
	"page": "I.67", 
	"main": "Beke István 1838-", 
	"child": "Beke Lajos 1864-", 
	"village": "", 
	"hint": "lásd I.157.lap"}, 
{
	"page": "", 
	"main": "Bencze Mária 1843-", 
	"child": "Beke Rozália 1866-", 
	"village": "", 
	"hint": "lásd I.142.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke József 1868-1871", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Borbára 1870-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Ágnes 1872-", 
	"village": "Városfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Mária 1874-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Rebeka 1876-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Vilma 1878-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Juliánna1880-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Lidia 1806-1887", 
	"child": "a családfő öreg anyja", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.68", 
	"main": "ifj.Csoma Lajos 1847-", 
	"child": "Csoma Lidia 1869-", 
	"village": "", 
	"hint": "lásd I.141.lap"}, 
{
	"page": "1.neje", 
	"main": "Bencze Mária 1848-1872", 
	"child": "Csoma János 1870-", 
	"village": "", 
	"hint": "lásd I.108.lap"}, 
{
	"page": "2.neje ", 
	"main": "Dánné Anna 1857-", 
	"child": "Csoma Sándor 1875-1915", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Rozália 1877-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Lajos 1879-1880", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Mária 1881-1882", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Mária 1883-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Anna 1885-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Juliánna 1887-", 
	"village": "", 
	"hint": "lásd I.169.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Rebeka 1893-1969", 
	"village": "Sándorfalva", 
	"hint": ""}, 
{
	"page": "I.69", 
	"main": "Sándor István 1822-1869", 
	"child": "Sándor Rozália 1853-1854", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Lőrincz Rozália 1828-1887", 
	"child": "Sándor Mihály 1855-", 
	"village": "", 
	"hint": "lásd I.136.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Mária 1857-", 
	"village": "", 
	"hint": "lásd I.19.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Ferenc 1860-1860", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Rozália 1863-1879", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Ferenc 1866-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Rebeka 1869-1879", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.70", 
	"main": "Zólya István 1840-", 
	"child": "Zólya Rebeka 1870-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Sándor Karolina ", 
	"child": "Zólya János 1874-1970", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Mária 1877-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Lukács 1881-1948", 
	"village": "", 
	"hint": "lásd I.184.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Katalin 1884-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Lidia 1886-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.71", 
	"main": "Balázsi Mózes 1844-", 
	"child": "Balázsi Mária 1867-", 
	"village": "", 
	"hint": "házasság előtt sz."}, 
{
	"page": "1.neje", 
	"main": "Sándor Juliánna 1847-1879", 
	"child": "Balázsi Krisztina 1869-", 
	"village": "Bukarest ", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Ferenc 1871-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Zsuzsa 1872-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Zsigmond 1875-1875", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Ferenc 1875-", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Rozália 1878-1883", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Jényi Juliánna ", 
	"child": "Balázsi Juliánna 1887-", 
	"village": "Bukarest ", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "a fenti Balázsi Krisztina ", 
	"child": "Balázsi Mária 1891-", 
	"village": "", 
	"hint": "törvénytelen gyermeke "}, 
{
	"page": "I.72", 
	"main": "ifj.Balázsi Lajos 1844-", 
	"child": "Balázsi Mózes 1874-", 
	"village": "Budapesten halt meg", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Bálint Judit          -1882", 
	"child": "Balázsi Lajos 1878-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Gergely 1881-1881", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Nagy Anna ", 
	"child": "Balázsi Rebeka 1888-", 
	"village": "Budapesten halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.87.lap", 
	"child": "Balázsi Ferenc 1893-", 
	"village": "", 
	"hint": "háborúban elesett"}, 
{
	"page": "I.73", 
	"main": "Nagy (Pali)Rebeka 1817-1890", 
	"child": "", 
	"village": "Sárkány ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "férj nélkül szaporított családot", 
	"child": "Nagy Mózes 1837-1842", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Sándor 1841-1842", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Juliánna 1843-1867", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Sándor 1846-1849", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Mózes 1847-1868", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Zsuzsa 1849-1852", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Zsuzsa 1854-", 
	"village": "Fogaras", 
	"hint": "lásd I.42.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Sándor 1858-", 
	"village": "Dálya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Lajos 1865-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "a fenti Nagy Juliánna ", 
	"child": "Nagy Rebeka 1863-", 
	"village": "", 
	"hint": "lásd I.134.lap"}, 
{
	"page": "", 
	"main": "házasságon kivüli gyermeke ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.74", 
	"main": "Bencze Péter 1845-1904", 
	"child": "Bencze Ágnes 1867-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje ", 
	"main": "Zólya Mária 1846-1880", 
	"child": "Bencze János 1869-", 
	"village": "Amerikában halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Berta 1872-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mária 1875-1875", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mária 1878-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Mezei Zsuzsa ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Kisfaludi Anna    -1894", 
	"child": "", 
	"village": "", 
	"hint": "lásd I.128.lap"}, 
{
	"page": "", 
	"main": "a fenti K.A-nak első férje a ", 
	"child": "fia Jó József 1853-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "családfő atyja volt", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.75/1", 
	"main": "id. Csoma Lajos 1812-1883", 
	"child": "Csoma Rozália 1843-", 
	"village": "Homoródújfalu", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Rákhel 1816-", 
	"child": "Csoma Lajos 1847-", 
	"village": "", 
	"hint": "lásd I.68.lap"}, 
{
	"page": "", 
	"main": "lásd I.115.lap", 
	"child": "Csoma Rákhel 1850-", 
	"village": "Lókod ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Rebeka 1852-", 
	"village": "", 
	"hint": "lásd I.115.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma János 1855-1864", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.75/2", 
	"main": "Kovács Lajos 1855-", 
	"child": "Kovács Imre 1879-", 
	"village": "Sárkány ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.107.lap", 
	"child": "Kovács Regina 1885-1885", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Lőrincz Mária 1855-", 
	"child": "Kovács Domokos 1891-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.77.lap", 
	"child": "Juliánna 1893-", 
	"village": "Pál Mózestől született", 
	"hint": "törvénytelen gyermeke "}, 
{
	"page": "I.76", 
	"main": "id.Demeter János 1810-", 
	"child": "Demeter István 1833-", 
	"village": "", 
	"hint": "eltávozott"}, 
{
	"page": "1.neje ", 
	"main": "Sándor Juliánna      -1882", 
	"child": "Demeter Borbára 1836-", 
	"village": "", 
	"hint": "eltávozott"}, 
{
	"page": "", 
	"main": "", 
	"child": "Demeter Mária 1842-", 
	"village": "", 
	"hint": "eltávozott 2 fiával"}, 
{
	"page": "2.neje ", 
	"main": "Doce Mária ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.77", 
	"main": "id. Lőrincz Mózes1810-1882", 
	"child": "Lőrincz János 1834-", 
	"village": "", 
	"hint": "lásd I.44.lap"}, 
{
	"page": "1.neje ", 
	"main": "Bencze Anna 1807-1836", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Jó Lidia 1817-1884", 
	"child": "Lőrincz Mózes 1839-1839", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Anna 1841-", 
	"village": "Telekfalva ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Rebeka 1843-1845", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Mózes 1846-", 
	"village": "", 
	"hint": "lásd I.78.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Ferencz 1848-1891", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Mária 1850-1855", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz András 1853-1862", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Mária 1855-", 
	"village": "Sárkány ", 
	"hint": "lásd I.75b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Rebeka 1860-1862", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.78", 
	"main": "ifj.Lőrincz Mózes 1846-", 
	"child": "Lőrincz Imre 1877-", 
	"village": "Fogaras", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "Major Juliánna        Égei", 
	"child": "Lőrincz Regina 1883-", 
	"village": "Fogaras", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz András 1885-", 
	"village": "Amerikában halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Mózes 1888-1888", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Juliánna 1889-", 
	"village": "Amerikában halt meg", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Anna1889-", 
	"village": "Amerikában halt meg", 
	"hint": "ikrek"}, 
{
	"page": "I:79/1", 
	"main": "Lőrincz Márton 1816-1865", 
	"child": "Lőrincz Rákhel 1853-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Béla Borbára          Lókodi", 
	"child": "Lőrincz György 1855-", 
	"village": "", 
	"hint": "lásd I.80.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Juliánna 1857-1875", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Mihály 1859-", 
	"village": "", 
	"hint": "lásd I.79/2.lap alól"}, 
{
	"page": "I.79/2", 
	"main": "Lőrincz Mihály 1859-", 
	"child": "Lőrincz Berta 1855-1891", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Hogyai Mária 1860-", 
	"child": "id.Lőrincz Balázs 1893-1955", 
	"village": "", 
	"hint": "lásd II.36.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Berta 1899-", 
	"village": "Recsenyéd ", 
	"hint": "Fekete Mózesné"}, 
{
	"page": "I.80", 
	"main": "Lőrincz György 1855-", 
	"child": "Lőrincz György 1880-1880", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "1.neje", 
	"main": "Bomhér Juliánna     elvált ", 
	"child": "Lőrincz Juliánna 1880-1880", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Lajos 1883-1883", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Juliánna 1883-1883", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "2.neje", 
	"main": "Kis Rozália            elvált", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.81", 
	"main": "Kovács (Varga )Bori 1818-", 
	"child": "Kovács István 1838-", 
	"village": "", 
	"hint": "lásd I.43.lap"}, 
{
	"page": "", 
	"main": "házasságon kívül szaporított ", 
	"child": "Kovács Juliánna 1840-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "családot", 
	"child": "Kovács János 1843-", 
	"village": "Fogaras", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács Rebeka 1846-", 
	"village": "", 
	"hint": "lásd I.55.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács András 1849-", 
	"village": "", 
	"hint": "lásd I.42.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács Kata 1852-", 
	"village": "", 
	"hint": "lásd I.41.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács Mihály 1856-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács Ferencz 1858-", 
	"village": "Fogaras", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács Mária 1861-1939", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "a fenti hajadon Kovács Máriának ", 
	"child": "Kovács Lajos 1885-", 
	"village": "Budapesten halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "3 törvénytelen gyermeke született", 
	"child": "Kovács Mária 1888-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács Juliánna 1891-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.82", 
	"main": "Jó József 1834-1894", 
	"child": "Jó József 1866-", 
	"village": "", 
	"hint": "lásd I.144.lap"}, 
{
	"page": "", 
	"main": "Molnár Lidia 1846-1901", 
	"child": "Jó Imre 1868-1870", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Sándor 1870-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Imre 1873-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Ferencz 1875-1876", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Juliánna 1877-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Lidia 1879-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Gergely 1881-1882", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Gergely 1883", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "I.83", 
	"main": "Nagy István 1844-", 
	"child": "Nagy Lajos 1867-1867", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Sándor Rebeka 1843-1881", 
	"child": "Nagy Ferencz 1869-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Mária 1822-1890", 
	"village": "", 
	"hint": "a családfő anyja"}, 
{
	"page": "I.84", 
	"main": "Bencze (Pál) János 1847", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Orbán Véri 1846-", 
	"child": "Orbán Mária 1866-", 
	"village": "az anyának törvény-", 
	"hint": "lásd I.141.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán József 1877-", 
	"village": "telen gyermekei", 
	"hint": "lásd I.141.lap"}, 
{
	"page": "I.85", 
	"main": "Dáné József 1846-1924", 
	"child": "Dáné Zsigmond 1874-1874", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Rózália 1855-1937", 
	"child": "Dáné Zsigmond 1875-1876", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Mária 1877-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Zsigmond 1880-1960", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Mózes 1883-1883", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Lidia 1892-1892", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.86", 
	"main": "Jó Gergely 1856-1936", 
	"child": "özv.Jó Ferencné", 
	"village": "", 
	"hint": "a családfőnek "}, 
{
	"page": "", 
	"main": "Bara Borbára        Sándorfalvi", 
	"child": "Kovács Juliánna 1815-1885", 
	"village": "", 
	"hint": "öreg anyja"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Mihály 1883-1891", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó András 1885-", 
	"village": "Székelyudvarhely", 
	"hint": "nincs utód "}, 
{
	"page": "", 
	"main": "", 
	"child": "ikrek", 
	"village": "", 
	"hint": "lásd II.23.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Áron 1885-1972", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Mihály 1891-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Juliánna 1895-", 
	"village": "Karácsonyfalva ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Krisztina ", 
	"village": "Karácsonyfalva ", 
	"hint": ""}, 
{
	"page": "I.87", 
	"main": "Jó Ferencz 1845-1882", 
	"child": "Jó Zsigmond 1870-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje ", 
	"main": "Bencze Lidia      -1872", 
	"child": "Jó Ignác 1872-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Nagy Anna ", 
	"child": "Jó Juliánna 1873-1874", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.72.lap", 
	"child": "Jó Ignác 1875-", 
	"village": "", 
	"hint": "lásd I.72.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Ferencz 1880-1886", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.88", 
	"main": "Lőrincz László 1858-", 
	"child": "", 
	"village": "", 
	"hint": "lásd I.120.lap"}, 
{
	"page": "", 
	"main": "Bokor Rozália          Bögözi", 
	"child": "Lőrincz Ilona 1884-1884", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Ilona 1886-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz József 1888-1889", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.89", 
	"main": "id.Lőrincz János unokája", 
	"child": "Lőrincz Rebeka 1870-", 
	"village": "", 
	"hint": "lásd I.160.lap"}, 
{
	"page": "1.neje", 
	"main": "Bencze Rákhel 1847-1879", 
	"child": "Lőrincz Zsuzsa 1872-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz János 1875-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.90", 
	"main": "id.Bencze János 1838-1881", 
	"child": "Bencze Zsuzsa 1860-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Veres Mária ", 
	"child": "Bencze Ágnes 1862-", 
	"village": "Énlaka", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Juliánna 1864-", 
	"village": "Bikafalva ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ferencz 1867-", 
	"village": "", 
	"hint": "lásd I.156.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mária 1870-1879", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "id.Bencze Márton 1808-1890", 
	"village": "", 
	"hint": "öreg nagyapja"}, 
{
	"page": "I.91", 
	"main": "Bencze András 1848-", 
	"child": "Bencze Mária 1874-1874", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "", 
	"main": "Nagy Rebeka 1850-", 
	"child": "id.Bencze Sándor 1813-1886", 
	"village": "", 
	"hint": "a családfő öreg apja"}, 
{
	"page": "I.92", 
	"main": "Bencze Ferencz 1846-", 
	"child": "Bencze Miklós 1871-1895", 
	"village": "Kolozsvári egyetemen", 
	"hint": "halt meg"}, 
{
	"page": "", 
	"main": "Bencze Lidia 1849-", 
	"child": "Bencze László 1873-1873", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze László 1874-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Sándor 1879-1889", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Rozália 1881-", 
	"village": "Dálya", 
	"hint": ""}, 
{
	"page": "I.93", 
	"main": "A.Bencze István 1825-1870", 
	"child": "Bencze Mária 1848-1872", 
	"village": "", 
	"hint": "lásd I.68.lap"}, 
{
	"page": "", 
	"main": "Nagy Juliánna 1827-", 
	"child": "Bencze János 1850-", 
	"village": "agglegény", 
	"hint": "nincs utód "}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Juliánna 1852-1881", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze József 1855-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ferencz 1857-", 
	"village": "", 
	"hint": "lásd I.52.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze István 1860-", 
	"village": "", 
	"hint": "lásd I.149.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Zsigmond 1863-", 
	"village": "Szászvároson tanár", 
	"hint": "Hódmezővásárhelyt halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Miklós 1866-", 
	"village": "", 
	"hint": "lásd I.155.lap"}, 
{
	"page": "I.94", 
	"main": "Simó István ", 
	"child": "Simó Lajos 1871-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Juliánna 1851-", 
	"child": "Simó József 1874-", 
	"village": "", 
	"hint": "háborúban elesett"}, 
{
	"page": "", 
	"main": "", 
	"child": "Simó Dénes 1883-1886", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Simó Rozália 1886-1886", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Simó Rozália 1887-1887", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Rebeka 1854-", 
	"child": "", 
	"village": "az anyának elmebeteg", 
	"hint": "testvére"}, 
{
	"page": "", 
	"main": "Balázsi Áron 1795-1886", 
	"child": "", 
	"village": "az anyának nagyapja", 
	"hint": "lásd I.24.lap"}, 
{
	"page": "I.95", 
	"main": "Bencze Ábel 1854-1882", 
	"child": "Bencze Ferenc 1879-1886", 
	"village": "", 
	"hint": "lásd.I.136.lap"}, 
{
	"page": "", 
	"main": "Balázsi Rozália 1859-", 
	"child": "Bencze Ábel 1881-1882", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "lásd I.136.lap", 
	"child": "Bencze Lajos 1881-1882", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "I.96", 
	"main": "Bencze Sándor 1859-1889", 
	"child": "Bencze Regina 1882-", 
	"village": "Fogarasban lakik a ", 
	"hint": "nagyapjánál"}, 
{
	"page": "1.neje", 
	"main": "Almássi Juliánna 1860-1887", 
	"child": "Bencze Miklós 1886-", 
	"village": "Fogarasban lakik a ", 
	"hint": "nagyapjánál"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mózes 1821-1892", 
	"village": "", 
	"hint": "a családfőnek öreg atyja"}, 
{
	"page": "2.neje ", 
	"main": "Csont Anna ", 
	"child": "", 
	"village": "", 
	"hint": "elváltak"}, 
{
	"page": "I.97", 
	"main": "Bencze Lajos 1857-", 
	"child": "Bencze Rebeka 1883-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Imre Jula           Középajtai", 
	"child": "Bencze Krisztina 1885-1885", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Zsigmond 1888-", 
	"village": "", 
	"hint": "háborúban elesett"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Krisztina 1890-1890", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Sándor 1894-", 
	"village": "Budapesten halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Sata Judit /Bencze Pálné -1885", 
	"child": "", 
	"village": "", 
	"hint": "a családfőnek öreg anyja"}, 
{
	"page": "I.98", 
	"main": "ifj.Bencze Mózes 1850-", 
	"child": "Bencze Mózes 1877-1877", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "", 
	"main": "Bató Zsuzsa 1851-", 
	"child": "Bencze Mózes 1879-1882", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Rebeka     -1883", 
	"village": "özv.Bencze Lajosné", 
	"hint": "a családfőnek öreg anyja"}, 
{
	"page": "I.99", 
	"main": "Sándor Gergely 1835-", 
	"child": "Sándor Lidia 1858-", 
	"village": "Telekfalva ", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Bencze Rozália 1839-1881", 
	"child": "Sándor János 1859-1881", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Ferencz 1862-1862", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Zsigmond 1863-1864", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Ágnes 1865-1866", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Rebeka 1867-1867", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Mihály 1869-1882", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor József 1872-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Veronika 1875-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Lajos 1879-1880", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.100", 
	"main": "Zsombori Zsigmond ", 
	"child": "Zsombori Mihály 1856-", 
	"village": "Fogaras", 
	"hint": "ott halt meg"}, 
{
	"page": "1.neje ", 
	"main": "Lukács Lidia 1836-1876", 
	"child": "Zsombori Mária 1858-", 
	"village": "Abásfalva ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Rebeka 1861-", 
	"village": "Bikafalva ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori János 1863-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Ferencz 1866-", 
	"village": "", 
	"hint": "lásd I.145.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Lidia 1869-", 
	"village": "Fogaras", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Juliánna 1872-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Sándor 1876-1876", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Szabó Anna      -1894", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.101", 
	"main": "Gelérd János 1823-", 
	"child": "Gelérd Lajos 1849-1849", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Szabó Juliánna ", 
	"child": "Gelérd Ferencz 1851-1874", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Márton 1854-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Lajos 1856-1856", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd József 1858-", 
	"village": "", 
	"hint": "lásd I.130.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Rebeka 1861-1863", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Rozália 1863-", 
	"village": "", 
	"hint": "lásd I.106.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Rebeka 1866-", 
	"village": "", 
	"hint": "lásd I.140.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Lajos 1870-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.102", 
	"main": "Jó Lőrincz 1829-", 
	"child": "Jó János 1864-1864", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Hogyai Juliánna 1842-", 
	"child": "Jó Juliánna 1865-", 
	"village": "Lókod ", 
	"hint": "lásd I. 159.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó János 1870-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Mária 1875-1876", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "a fenti Jó Juliánna törvénytelen", 
	"child": "Jó Mária 1890-", 
	"village": "", 
	"hint": "ifj.nőtlen Balázsi Sándortól"}, 
{
	"page": "", 
	"main": "gyermeke ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.103", 
	"main": "id.Jó Márton 1830-1877", 
	"child": "ifj.Jó Márton 1862-", 
	"village": "", 
	"hint": "házasság előtt született"}, 
{
	"page": "", 
	"main": "Ipó Rebeka 1842-", 
	"child": "Jó János 1863-1864", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Krisztina 1865-1879", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Juliánna 1867-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Lidia 1869-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Ágnes 1872-1891", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Lajos 1875-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Ipó Rebeka 1880-", 
	"village": "        a férj halála után", 
	"hint": "házasságon kívül született"}, 
{
	"page": "", 
	"main": "a fenti Jó Lidia hajadonnak ", 
	"child": "Jó Mária 1890-1891", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "2 törvénytelen gyermeke született", 
	"child": "Jó Ferencz 1891-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.104/1", 
	"main": "Jó György 1831-1881", 
	"child": "Jó Ferencz 1859-1862", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Mária 1830-1885", 
	"child": "Jó Ferencz 1862-", 
	"village": "", 
	"hint": "lásd I. 104/2.lap"}, 
{
	"page": "I.104/2", 
	"main": "Jó Ferencz 1862-", 
	"child": "Jó Regina 1885-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gelérd Mária ", 
	"child": "Jó Ferencz 1887-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.22.lap", 
	"child": "Jó Katalin 1889-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Péter ", 
	"village": "id.Joó Árpád apja", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Lőrincz ", 
	"village": "", 
	"hint": "legényül meghalt"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Zsigmond 1900-1976(alsó)", 
	"village": "id.Joó Attila apja", 
	"hint": "lásd II.118.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó László 1905-1950", 
	"village": "Joó Mariska apja", 
	"hint": "lásd II.79.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Jenő 1909-1978", 
	"village": "Joó Dénes J. apja", 
	"hint": "lásd II.128/a.lap"}, 
{
	"page": "I.105", 
	"main": "Balázsi Mihály 1856-", 
	"child": "Balázsi Mihály 1879-1879", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Forró Mária ", 
	"child": "Balázsi Mária 1881-", 
	"village": "Dálya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Forró Rozáliától született ", 
	"child": "Balázsi Ferencz 1883-", 
	"village": "", 
	"hint": "vadházasságból született"}, 
{
	"page": "", 
	"main": "2 gyermek", 
	"child": "Balázsi Mihály 1885-", 
	"village": "", 
	"hint": "vadházasságból született"}, 
{
	"page": "", 
	"main": "Rácz Annától született gyermek", 
	"child": "Balázsi Mária 1889-", 
	"village": "", 
	"hint": "vadházasságból született"}, 
{
	"page": "I.106", 
	"main": "Pál Mihály 1836-", 
	"child": "Pál Juliánna 1882-1884", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.106.lap", 
	"child": "Pál Lajos 1886-1886", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gelérd Rozália 1836-", 
	"child": "id.Pál Lajos 1887-1979", 
	"village": "", 
	"hint": "lásd II.2.lap"}, 
{
	"page": "", 
	"main": "lásd I.101.lap", 
	"child": "id.Pál János 1891-1963", 
	"village": "", 
	"hint": "lásd II.20.lap"}, 
{
	"page": "I.107", 
	"main": "id.Kovács József 1825-1884", 
	"child": "Kovács József 1853", 
	"village": "", 
	"hint": "lásd I.108.lap"}, 
{
	"page": "", 
	"main": "Orbán Juliánna ", 
	"child": "Kovács Lajos 1855-", 
	"village": "Sárkány ", 
	"hint": "lásd I.75.lap"}, 
{
	"page": "", 
	"main": "lásd I.35.lap", 
	"child": "Kovács Márton 1858-1859", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács Rozália 1860-1860", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács János 1863-1864", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács Rozália 1863-1864", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács Ferencz 1868-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.108/1", 
	"main": "ifj. Kovács József 1853-1932", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "", 
	"main": "Pál Rebeka 1851-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "I.108/2", 
	"main": "Csoma János 1870-1929", 
	"child": "Csoma Lidia 1895-1895", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.68.lap", 
	"child": "Csoma József 1896-", 
	"village": "", 
	"hint": "lásd II.70.lap"}, 
{
	"page": "", 
	"main": "Nagy Lidia 1872-1956", 
	"child": "Csoma Zsigmond 1898-1925", 
	"village": "Budapesten halt meg", 
	"hint": "ételmérgezésben"}, 
{
	"page": "", 
	"main": "lásd I.39.lap", 
	"child": "Csoma Irma 1900-1904", 
	"village": "", 
	"hint": "gyermekkorban meghalt"}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Dénes 1902-1905", 
	"village": "", 
	"hint": "gyermekkorban meghalt"}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Irma 1904-1964", 
	"village": "Brassó", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Dénes 1907-1907", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Pál 1907-1967", 
	"village": "", 
	"hint": "lásd II.81.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Emma 1912-1912", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Dénes 1912-1912", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "I.109", 
	"main": "ifj.Bencze József 1842-", 
	"child": "Bencze József 1864-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Judit 1843-", 
	"child": "BenczeMárton 1866-1866", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Márton 1868-", 
	"village": "", 
	"hint": "lásd I.162.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Berta 1870-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Gyula 1872-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze József 1873-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Juliánna 1876-1940", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Lajos 1878-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Gyula 1880-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Sándor 1882-1882", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Dénes 1883-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.110", 
	"main": "Beke József 1841-", 
	"child": "Beke József 1876-", 
	"village": "", 
	"hint": "háborúban elesett"}, 
{
	"page": "", 
	"main": "Sándor Mária 1845-1936", 
	"child": "", 
	"village": "nincs utód ", 
	"hint": ""}, 
{
	"page": "I.111", 
	"main": "Bató Ferencz 1847- ", 
	"child": "Bató János 1873-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje ", 
	"main": "Balázsi Anna 1847-1890", 
	"child": "Bató Ferencz 1876-", 
	"village": "Amerika ", 
	"hint": "Székelyudvarhelyt halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Ágnes 1879-", 
	"village": "Homoródszentpál", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Domokos 1881-", 
	"village": "Lókod ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Lidia 1884-", 
	"village": "Budapesten halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Juliánna 1889-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Orbán Borbára ", 
	"child": "", 
	"village": "", 
	"hint": "a családfőnek öreg anyja"}, 
{
	"page": "", 
	"main": "Bató József özvegye ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.112", 
	"main": "Szabados István 1849-", 
	"child": "Szabados Dénes 1876-", 
	"village": "Apáca ", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "Andrási Mária ", 
	"child": "Szabados Mária 1880-1880", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Szabados Lajos 1881-1883", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Szabados Mária 1886-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Török Lidia 1836-", 
	"child": "", 
	"village": "a családfő anyjának", 
	"hint": "özvegy testvére"}, 
{
	"page": "I.113", 
	"main": "Orbán Sándor 1840-", 
	"child": "Orbán Lidia 1859-", 
	"village": "Dálya", 
	"hint": "házasság előtt született"}, 
{
	"page": "", 
	"main": "Dáné Krisztina 1842-1893", 
	"child": "Orbán József 1863-", 
	"village": "", 
	"hint": "lásd I.154.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Mária 1866-", 
	"village": "", 
	"hint": "lásd I.137.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Zsigmond 1868-1871", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Elek 1870-1872", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Juliánna 1874-1874", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Sándor 1875-1896", 
	"village": "Déva", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Elek 1878-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Rebeka 1881-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Zsigmond 1885-", 
	"village": "", 
	"hint": "háborúban elesett"}, 
{
	"page": "I.114", 
	"main": "Bencze Gergely 1828-1887", 
	"child": "Bencze Zsigmond 1848-", 
	"village": "", 
	"hint": "lásd I.48.lap"}, 
{
	"page": "1.neje ", 
	"main": "Gelérd Mária        -1852", 
	"child": "Bencze Rebeka 1850-1859", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze József 1852-", 
	"village": "", 
	"hint": "lásd I.115lap"}, 
{
	"page": "2.neje ", 
	"main": "Dáné Lidia 1839-1873", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "3.neje", 
	"main": "Molnár Mária 1829-1892", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.115", 
	"main": "Bencze József (felső) 1852-", 
	"child": "Bencze Mária 1875-1875", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Csoma Rebeka 1852-1934", 
	"child": "Bencze Rebeka 1876-1876", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.75a lap", 
	"child": "Bencze László 1877-1877", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze László 1878-1883", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mária 1880-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Juliánna 1882-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze László 1885-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Dénes 1887-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Elek 1889-1891", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze József 1893-1893", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Elek 1893-1893", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Rózália ", 
	"village": "Sándorfalva", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "Bencze Rákhel 1816-", 
	"child": "lásd I.75a.lap", 
	"village": "", 
	"hint": "az anyának öreg anyja"}, 
{
	"page": "I.116", 
	"main": "Almási Dániel", 
	"child": "Almási Elek 1857-", 
	"village": "", 
	"hint": "lásd I.117.lap"}, 
{
	"page": "", 
	"main": "Balázsi Lidia 1834-", 
	"child": "Almási Lajos 1858-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Almási Juliánna 1860-", 
	"village": "", 
	"hint": "lásd I.96.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Almási Mária 1867-1867", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Almási Mária 1869-", 
	"village": "", 
	"hint": "lásd I.149.lap"}, 
{
	"page": "I.117", 
	"main": "Almási Elek 1857-", 
	"child": "Almási Lajos 1883-", 
	"village": "Amerikában halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Orbán Anna 1859-", 
	"child": "Almási Elek 1885-", 
	"village": "", 
	"hint": "gyenge elméjű"}, 
{
	"page": "", 
	"main": "lásd I.12.lap", 
	"child": "Almási Mária 1890-", 
	"village": "Székelyudvarhely", 
	"hint": "Bencze Gáborné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Almási Juliánna 1894-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Almási Berta 1896-1976", 
	"village": "", 
	"hint": "lásd II.29.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Almási Ferencz 1898-", 
	"village": "Amerika ", 
	"hint": "nincs utód "}, 
{
	"page": "I.118", 
	"main": "ifj.Balázsi Mózes 1853-1936", 
	"child": "Balázsi Zsuzsa 1882-", 
	"village": "Székelyudvarhely", 
	"hint": "Bencze Dompkosné"}, 
{
	"page": "1.neje", 
	"main": "Balázsi Juliánna 1858-", 
	"child": "Balázsi Ferencz 1885-", 
	"village": "Amerikában halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Rozália 1887-", 
	"village": "Székelyudvarhely", 
	"hint": "baptista"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Anna 1890-", 
	"village": "Fogaras", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Mózes 1893-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Vécsi Karolina", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.119", 
	"main": "id.Balázsi Sándor 1810-1893", 
	"child": "Balázsi Anna 1836-", 
	"village": "", 
	"hint": "lásd I.25.lap"}, 
{
	"page": "1.neje ", 
	"main": "Lőrincz Rebeka 1812-1862", 
	"child": "Balázsi Judit 1843-", 
	"village": "", 
	"hint": "lásd I.109.lap"}, 
{
	"page": "2.neje", 
	"main": "Kovács Mária 1818-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.120", 
	"main": "Lőrincz József 1824-1876", 
	"child": "Lőrincz Juliánna 1849-1849", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Sándor Rozália ", 
	"child": "Lőrincz Rebeka 1850-1851", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz József 1852-", 
	"village": "", 
	"hint": "lásd I.131.lap"}, 
{
	"page": "2.neje", 
	"main": "Ferencz Eszter         Égei", 
	"child": "Lőrincz Lajos 1856-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz László 1858-", 
	"village": "", 
	"hint": "lásd I.88.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Lidia 1860-", 
	"village": "Fogaras", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Ágnes 1862-", 
	"village": "", 
	"hint": "lásd I.139.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Rozália", 
	"village": "", 
	"hint": "lásd I.145.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Sándor 1866-1867", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Sándor 1868-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Eszter 1870-", 
	"village": "Barót", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Márton 1873-1873", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Anna 1874-1886", 
	"village": "", 
	"hint": "lásd I.128.lap"}, 
{
	"page": "I.121", 
	"main": "Bencze (Miklós)János 1853-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "", 
	"main": "Beke Mária 1857-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "", 
	"main": "Sándor Mária 1829-", 
	"child": "", 
	"village": "a családfőnek ", 
	"hint": "özvegy anyja"}, 
{
	"page": "I.122", 
	"main": "Fodor Lajos 1844-", 
	"child": "Fodor Lajos 1876-1932", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "telekfalvi", 
	"child": "Fodor Mária 1883-1918", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Rákhel 1854-", 
	"child": "Fodor Rozália 1888-1888", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Veronika 1888-1888", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "I.123", 
	"main": "Balázsi János 1831-", 
	"child": "Balázsi János 1856-", 
	"village": "Háromszék", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Rákhel 1836", 
	"child": "Balázsi Juliánna 1858-", 
	"village": "Sándorfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Mihály 1860-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Sándor 1862-", 
	"village": "Fogaras", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Lajos 1864-", 
	"village": "", 
	"hint": "lásd I.153.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Mária 1868-", 
	"village": "", 
	"hint": "lásd I.144.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Rozália 1871-1871", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Rákhel 1871-", 
	"village": "", 
	"hint": "lásd I 161.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Márton 1873-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Rozália 1875-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Imre 1879-", 
	"village": "Székelyudvarhely", 
	"hint": "ott halt meg"}, 
{
	"page": "I.124", 
	"main": "Balázsi István 1836-", 
	"child": "Balázsi József 1863-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Pálfi Juliánna ", 
	"child": "Balázsi Dániel 1865-", 
	"village": "Recsenyéd ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi István 1869-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.125", 
	"main": "Balázsi András 1821-", 
	"child": "Balázsi Zsuzsa 1876-1877", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gelérd Sára 1837-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.4.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.126", 
	"main": "Jó Sándor 1846-", 
	"child": "Jó Mária 1877-1877", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Szőke Judith          Remetei", 
	"child": "Jó Mária 1880-1882", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Albert 1883-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Mária 1885-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Anna 1887-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.127", 
	"main": "Jó Péter 1822-1887", 
	"child": "id.Jó Lajos 1849-1888", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Almási Klára ", 
	"child": "ifj. Jó Lajos 1873-", 
	"village": "id.Jó Lajos 1.nejétől", 
	"hint": "Bikafalvi Benczúr Zsuzsától"}, 
{
	"page": "I.128", 
	"main": "Jó János 1828-", 
	"child": "Jó József 1853-", 
	"village": "", 
	"hint": "lásd I.74.lap"}, 
{
	"page": "1.neje ", 
	"main": "Kisfaludi Anna    -1894", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.78.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Orbán Anna        -1878", 
	"child": "Jó Lajos 1858-1873", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Rozália 1861-", 
	"village": "", 
	"hint": "lásd I.130.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Mária  1864-1879", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó János 1871-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "3.neje", 
	"main": "Ferencz Eszter         Égei", 
	"child": "Lőrincz Anna 1874-1886", 
	"village": "", 
	"hint": "első férjétől"}, 
{
	"page": "", 
	"main": "lásd I.120.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.129", 
	"main": "Bencze László 1854-1929", 
	"child": "Bencze Márton ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Juliánna 1857-", 
	"child": "Bencze Róza", 
	"village": "Keményfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Endre ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Sándor ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ida ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Emma ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Margit", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Irén ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Berta", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.130", 
	"main": "Gelérd József 1858-", 
	"child": "Gelérd Imre 1883-1883", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.101.lap", 
	"child": "Gelérd Mária 1884-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Jó Rozália 1861-", 
	"child": "Gelérd Juliánna 1887-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.128.lap", 
	"child": "Gelérd Ágnes 1889-1889", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Anna 1890-", 
	"village": "", 
	"hint": "lásd II.1.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Gelérd Ágnes 1896-", 
	"village": "", 
	"hint": "Lásd II.26.lap"}, 
{
	"page": "I.131", 
	"main": "Lőrincz József 1852-1938", 
	"child": "Lőrincz József 1883-1884", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Major Lidia          -1884", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Sándor Rozália 1864-", 
	"child": "Lőrincz Mária 1889-", 
	"village": "elválás után született", 
	"hint": "férjétől valónak állítja"}, 
{
	"page": "", 
	"main": "lásd I.69.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "3.neje", 
	"main": "Szabó Juliánna ", 
	"child": "", 
	"village": "jött Dályából ", 
	"hint": "elváltak visszament"}, 
{
	"page": "4.neje ", 
	"main": "István Anna", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "5.neje ", 
	"main": "Szőke Mária           Árvátfalvi", 
	"child": "magával hozott gyermeke", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál József 1890-", 
	"village": "", 
	"hint": "lásd II.55.lap"}, 
{
	"page": "I.132", 
	"main": "Fodor Mihály 1854-", 
	"child": "Fodor Mihály 1882-", 
	"village": "Recsenyéd ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Fekete Judith     Recsenyédi", 
	"child": "Fodor Domokos 1885-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "a család Recsenyédre", 
	"child": "költözött", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.133", 
	"main": "ifj.Dáné Lajos 1859-", 
	"child": "Dáné Lajos 1886-", 
	"village": "", 
	"hint": "háborúban elesett"}, 
{
	"page": "", 
	"main": "lásd I.25.lap", 
	"child": "Dáné Károly 1887-1971", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Sándor Anna 1867-", 
	"child": "Dáné Katalin 1888-1972", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.32.lap", 
	"child": "Dáné Rozália 1890-", 
	"village": "", 
	"hint": "15 éves korában meghalt"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Gábor 1892-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Sándor 1897-", 
	"village": "", 
	"hint": "háborúban elesett"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Anna 1899-", 
	"village": "Dálya", 
	"hint": "Sándor Mózesné"}, 
{
	"page": "I.134", 
	"main": "Nagy Gergely 1853-", 
	"child": "Nagy János 1884-", 
	"village": "", 
	"hint": "háborúban elesett"}, 
{
	"page": "", 
	"main": "Nagy Rebeka 1863-", 
	"child": "Nagy Rozália 1886-1886", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Gergely 1888-", 
	"village": "", 
	"hint": "20 éves korában meghalt"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Sándor 1892-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.135", 
	"main": "Balázsi Zsigmond 1861-1938", 
	"child": "Balázsi Ilona 1886-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zólya Juliánna 1865-", 
	"child": "Balázsi Mária 1887-1888", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Zsigmond 1888-1973", 
	"village": "", 
	"hint": "baptista"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Mária 1897-", 
	"village": "Székelyudvarhely", 
	"hint": "Jó Andrásné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi András", 
	"village": "", 
	"hint": "meghalt gyermekkorban"}, 
{
	"page": "I.136", 
	"main": "Sándor Mihály 1855-", 
	"child": "Bencze Ferencz 1879-1886", 
	"village": "az anyának első", 
	"hint": "férjétől való"}, 
{
	"page": "", 
	"main": "Balázsi Rozália 1859-", 
	"child": "Sándor Dénes 1884-1884", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Ábel özvegye", 
	"child": "Sándor Anna 1889-1889", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.95.lap", 
	"child": "Sándor Zsigmond1890-1890", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Dénes 1885-1895", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Ilona 1892-", 
	"village": "", 
	"hint": "lásd II.5.lap"}, 
{
	"page": "I.137", 
	"main": "Beke Zsigmond 1860-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Orbán Mária 1866-1889", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.113.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Farkas Juliánna         Abránfalvi", 
	"child": "elváltak", 
	"village": "férjhez ment ", 
	"hint": "Balázsi Domokoshoz"}, 
{
	"page": "3.neje", 
	"main": "Dáné Regina ", 
	"child": "Beke Regina 1896-1975", 
	"village": "", 
	"hint": "lásd II.5.lap"}, 
{
	"page": "", 
	"main": "lásd I.26.lap", 
	"child": "Beke Gyula 1897-1947", 
	"village": "", 
	"hint": "lásd II.60.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Zsigmond 1899-", 
	"village": "Budapesten halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Berta 1901-", 
	"village": "4 éves korába", 
	"hint": "a vályúba fulladt"}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Károly 1902-1954", 
	"village": "felesége Beke Eszter", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke István 1908", 
	"village": "", 
	"hint": "lásd II.89.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Ferencz 1910-", 
	"village": "", 
	"hint": "tanár Zilahon"}, 
{
	"page": "I.138", 
	"main": "Pál Sándor 1859-", 
	"child": "Pál Juliánna 1886-1890", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Molnár Ágnes 1868-", 
	"child": "Pál Mózes 1889-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.3.lap", 
	"child": "Pál Juliánna 1891-1894", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Mária 1893-", 
	"village": "", 
	"hint": "Erkeden halt meg"}, 
{
	"page": "2.neje ", 
	"main": "Molnár Lidia 1846-1901", 
	"child": "Pál Regina", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Molnár Ágnes testvére ", 
	"child": "Pál Ilona", 
	"village": "Sepsiszentgyörgy", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Zsuzsánna", 
	"village": "Joó Ignáctól", 
	"hint": "Sándor Dénesné"}, 
{
	"page": "I.139", 
	"main": "Szőcs Sándor ", 
	"child": "Szőcs Ilona 1885-1888", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Lőrincz Ágnes 1862-", 
	"child": "Szőcs Sándor 1887-", 
	"village": "", 
	"hint": "Fogarasban halt meg"}, 
{
	"page": "", 
	"main": "lásd I.120.lap", 
	"child": "Szőcs Ilona 1889-", 
	"village": "", 
	"hint": "Fogarasban halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Szőcs Ferencz kb.1894-", 
	"village": "12 éves korában", 
	"hint": "meghalt (nyavalyatörésben)"}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": "szívbetegségben"}, 
{
	"page": "", 
	"main": "", 
	"child": "Szőcs Rebeka ", 
	"village": "", 
	"hint": "Fogarasban halt meg"}, 
{
	"page": "I.140", 
	"main": "Gelérd Lajos 1862-", 
	"child": "Gelárd Árpád 1888-", 
	"village": "Zetelaka", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "lásd I.4.lap", 
	"child": "Gelérd Ferenc1891-", 
	"village": "Budapesten halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gelérd Rebeka 1866-", 
	"child": "Gelérd Ilona 1893-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.101.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.141", 
	"main": "Beke János 1863-1912", 
	"child": "Beke Kata1888-1888", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.51.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Csoma Lidia 1869-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.68.lap / lásd I.150.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Orbán Bencze Mária 1866-", 
	"child": "Beke Ferencz 1887-", 
	"village": "Amerikában halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Zsigmond 1889-", 
	"village": "Amerikában halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Berta 1891-", 
	"village": "Amerikában halt meg", 
	"hint": "Balázsi Ferencné"}, 
{
	"page": "I.142", 
	"main": "Balázsi Péter 1861-", 
	"child": "Balázsi Rozália 1889-1889", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.6.lap", 
	"child": "Balázsi Berta 1890-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Beke Rozália 1866-", 
	"child": "Balázsi Rozália 1892-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.67.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.143", 
	"main": "Zólya Gergely 1863- ", 
	"child": "Zólya Mária 1889-1889", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.21.lap", 
	"child": "Zólya Mária 1890-", 
	"village": "Ége", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Regina 1868-", 
	"child": "Zólya Zsuzsánna ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.50.lap", 
	"child": "Zólya Berta 1911-", 
	"village": "", 
	"hint": "lásd II.91.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Mózes 1895-", 
	"village": "", 
	"hint": "önakasztás a 40-es években"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya József 1898-", 
	"village": "Parajd", 
	"hint": ""}, 
{
	"page": "I.144", 
	"main": "Jó József 1866-1947", 
	"child": "Jó Mária 1889-1963", 
	"village": "Székelykeresztúr", 
	"hint": "lásd I.184.lap"}, 
{
	"page": "", 
	"main": "lásd I.82.lap", 
	"child": "Jó Rozália 1892-1972", 
	"village": "Bukarest ", 
	"hint": "itthon van eltemetve"}, 
{
	"page": "", 
	"main": "Balázsi Mária 1868-", 
	"child": "Jó József 1894-", 
	"village": "", 
	"hint": "lásd II.29.lap"}, 
{
	"page": "", 
	"main": "lásd I.123.lap", 
	"child": "Jó Juliánna 1900-1967", 
	"village": "", 
	"hint": "lásd II.40.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Zsigmond 1903-", 
	"village": "", 
	"hint": "lásd II.57.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Gergely 1905-", 
	"village": "", 
	"hint": "lásd II.63.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Berta 1907-", 
	"village": "", 
	"hint": "lásd II.lap 89.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Gyula 1910-1999", 
	"village": "", 
	"hint": "lásd II.103.lap"}, 
{
	"page": "I.145", 
	"main": "id.Zsombori Ferencz1866-1931", 
	"child": "ifj.Zsombori Ferencz 1891-1962", 
	"village": "", 
	"hint": "lásd II.9.lap"}, 
{
	"page": "", 
	"main": "lásd I.100.lap", 
	"child": "Zsombori Berta 1894-", 
	"village": "Kecskemét", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "Lőrincz Rozália 1864-1951", 
	"child": "Zsombori Pál 1897-1957", 
	"village": "", 
	"hint": "lásd II.39.lap"}, 
{
	"page": "", 
	"main": "lásd I.120.lap", 
	"child": "Zsombori Zsigmond 1899-", 
	"village": "Budapesten halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori József 1904-1987", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.146", 
	"main": "Nagy Mózes 1863-1935", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.40.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje ", 
	"main": "Gelérd Ágnes 1835-", 
	"child": "", 
	"village": "", 
	"hint": "elváltak"}, 
{
	"page": "", 
	"main": "lásd I.14.lap-lásd I.155.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Barczán Mária 1866-1919", 
	"child": "Nagy Miklós 1892-1893", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.54.lap", 
	"child": "Nagy Berta 1894-", 
	"village": "", 
	"hint": "ifj.Balázsi Zsigmondné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Mária 1896-", 
	"village": "Lókod ", 
	"hint": "György Lászlóné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Rózália 1898-", 
	"village": "", 
	"hint": "Joó Imréné"}, 
{
	"page": "I.147", 
	"main": "Barczán Lajos1856-", 
	"child": "Barczán Mária 1883-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.55.lap ", 
	"child": "Barczán Balázs 1885-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zsombori Rozália ", 
	"child": "Barczán Zsuzsa 1888-1889", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Sándor 1892-1892", 
	"village": "", 
	"hint": "Rákoson születtet ott halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Juliánna 1893-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.148", 
	"main": "Fodor Gábor 1862-", 
	"child": "Fodor Mária 1895-", 
	"village": "Székelyudvarhely", 
	"hint": "Zólya Dénesné"}, 
{
	"page": "", 
	"main": "lásd I.19.lap", 
	"child": "Fodor Mózes 1897-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gábor Juliánna    Recsenyédi", 
	"child": "Fodor Dénes ", 
	"village": "", 
	"hint": "gyermekkorában meghalt"}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Sándor ", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Gábor ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.149", 
	"main": "Bencze István 1860-", 
	"child": "Bencze Ilona 1890-1913", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.93.lap", 
	"child": "Bencze Mária 1892-1973", 
	"village": "Rugonfalva ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Almási Mária 1869-", 
	"child": "Bencze Zsuzsa 1895-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.116.lap", 
	"child": "Bencze Juliánna 1897-1920", 
	"village": "Kecskemét", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze István 1899-1958", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Róza 1901-1917", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Gyula( II.)1903-", 
	"village": "", 
	"hint": "lásd II.74.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Károly 1905-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "I.150", 
	"main": "legifj.Bencze Zsigmond 1864-", 
	"child": "Bencze Mária 1891-1933", 
	"village": "", 
	"hint": "lásd I.185.lap-lásd II.34.lap"}, 
{
	"page": "", 
	"main": "lásd I.9.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Csoma Lidia 1869-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.141.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.151", 
	"main": "Zólya Ferencz 1867-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.20.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje ", 
	"main": "Bencze Rozália 1867-", 
	"child": "", 
	"village": "", 
	"hint": "elváltak"}, 
{
	"page": "", 
	"main": "lásd I.9.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Beke Zsuzsa 1871-1969", 
	"child": "Zólya Berta 1895-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.51 lap", 
	"child": "Zólya Juliánna 1900-", 
	"village": "", 
	"hint": "lásd II.41.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Dénes 1898-", 
	"village": "", 
	"hint": "lásd II.34.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Katalin 1884-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.152", 
	"main": "ifj. Dáné József 1862-", 
	"child": "Dáné Lidia 1889-1889", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.23.lap", 
	"child": "Dáné Lidia 1890-", 
	"village": "Felsővericze", 
	"hint": "Bukarestben halt meg"}, 
{
	"page": "", 
	"main": "Demeter Rebeka ( más falusi)", 
	"child": "Dáné Anna 1892-", 
	"village": "Felsővericze", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Krisztina 1899-1971", 
	"village": "Dálya ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné József ", 
	"village": "", 
	"hint": "Telekfalvi hatéron halt meg"}, 
{
	"page": "I.153", 
	"main": "Balássi Lajos 1864-1936", 
	"child": "Balássi Lajos 1890-", 
	"village": "", 
	"hint": "Magyarországon halt meg"}, 
{
	"page": "", 
	"main": "lásd I.123.lap", 
	"child": "Balássi Zsigmond 1893-1967", 
	"village": "", 
	"hint": "lásd II.17.lap"}, 
{
	"page": "", 
	"main": "Gelérd Ágnes 1835-", 
	"child": "Balássi Vilma 1896-1975", 
	"village": "", 
	"hint": "lásd II.13.lap"}, 
{
	"page": "", 
	"main": "lásd I.146.lap", 
	"child": "Balássi Regina 1898-1975", 
	"village": "", 
	"hint": "lásd II.21.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balássi Mária 1901-1938", 
	"village": "", 
	"hint": "lásd II.33.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balássi Dénes 1903-1944", 
	"village": "", 
	"hint": "lásd II.100lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balássi József 1910-1993", 
	"village": "", 
	"hint": "lásd II.86.lap"}, 
{
	"page": "I.154", 
	"main": "Orbán József 1863-", 
	"child": "Orbán Ferencz 1891-", 
	"village": "", 
	"hint": "Budapesten halt meg"}, 
{
	"page": "", 
	"main": "lásd I.113.lap", 
	"child": "Orbán Dénes 1892-", 
	"village": "Kőhalom ", 
	"hint": "itthon van eltemetve"}, 
{
	"page": "", 
	"main": "Béla Rebeka              Szentpáli", 
	"child": "Orbán Sándor 1893-", 
	"village": "", 
	"hint": "Budapesten halt meg"}, 
{
	"page": "I.155", 
	"main": "Bencze Miklós 1866-", 
	"child": "Bencze Pál 1890-", 
	"village": "háborúban elesett", 
	"hint": "itthon van eltemetve"}, 
{
	"page": "", 
	"main": "lásd I.93.lap", 
	"child": "Bencze István 1892-1893", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Mihály Juliánna           Bögözi", 
	"child": "Bencze Lidia 1876-", 
	"village": "Dálya ", 
	"hint": "lásd II.14.lap"}, 
{
	"page": "I.156", 
	"main": "Bencze Ferencz 1867-", 
	"child": "Bencze Domokos 1891-", 
	"village": "Marosvásárhely", 
	"hint": "jegyző"}, 
{
	"page": "", 
	"main": "lásd I.90.lap ", 
	"child": "Bencze Gyula I.", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Toásó Ágnes 1871-     Bögözi", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.157", 
	"main": "Beke Lajos 1864-1945", 
	"child": "Beke Zsuzsánna 1894-", 
	"village": "", 
	"hint": "lásd II.2.lap"}, 
{
	"page": "", 
	"main": "lásd I.67.lap", 
	"child": "Beke Ilona 1896-1978", 
	"village": "", 
	"hint": "lásd II.12.lap"}, 
{
	"page": "1.neje", 
	"main": "Hogyai Zsuzsa 1872-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.53.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Gelérd Anna ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.158", 
	"main": "legifj.Dáné József 1865-", 
	"child": "Dáné Berta ", 
	"village": "Városfalva ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.25.lap", 
	"child": "Dáné József 1903-1972", 
	"village": "", 
	"hint": "lásd II.55.lap"}, 
{
	"page": "", 
	"main": "Kovács Regina            Égei", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.159", 
	"main": "legifj.Balázsi Sándor 1863-", 
	"child": "Balázsi Mária 1890-", 
	"village": "Kénos ", 
	"hint": "lásd I.102.lap"}, 
{
	"page": "", 
	"main": "lásd I.5.lap", 
	"child": "Balázsi Ilona 1892-1893", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Jó Juliánna 1865-", 
	"child": "Balázsi Sándor 1896-1978", 
	"village": "", 
	"hint": "lásd II.47.lap"}, 
{
	"page": "", 
	"main": "lásd I.102.lap", 
	"child": "Balázsi Ilona kb.1898-", 
	"village": "Homoródújfalu", 
	"hint": "ott halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Bálint kb.1902-", 
	"village": "", 
	"hint": "felaksztotta magát"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Berta ", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "I.160", 
	"main": "Dáné Ferencz 1865-", 
	"child": "Dáné Mária 1892-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.23.lap", 
	"child": "Dáné Zsuzsa 1894-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Lőrincz Rebeka 1870-", 
	"child": "Dáné Regina 1897-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.89.lap", 
	"child": "Dáné Zsigmond 1899-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Ferencz ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.161", 
	"main": "ifj.Jó Márton 1862-1937", 
	"child": "id.Jó Ferencz 1894-", 
	"village": "", 
	"hint": "Joó Gézáné Ida apja"}, 
{
	"page": "", 
	"main": "lásd I.103.lap", 
	"child": "Jó Ágnes 1896", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Rákhel 1871-", 
	"child": "Jó Juliánna ", 
	"village": "Városfalva ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.123.lap", 
	"child": "Jó Lina ", 
	"village": "", 
	"hint": "id.Balázsi János 2.neje"}, 
{
	"page": "", 
	"main": "", 
	"child": "Jó Imre", 
	"village": "", 
	"hint": "Joó Viktor bácsiék apja"}, 
{
	"page": "I.162", 
	"main": "Bencze Márton ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje ", 
	"main": "Ilyés………….Bögözi   ( elváltak)", 
	"child": "Bencze István 1890-", 
	"village": "háborúban elesett", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Sándor Berta   Dályai   (elváltak)", 
	"child": "Bencze Béla 1893-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "3.neje ", 
	"main": "Bencze Rozália ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.163", 
	"main": "Kovács Ferencz ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Ferencz Kata    Égei    meghalt", 
	"child": "Kovács Miklós 1893-", 
	"village": "háborúban elesett", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Orbán Lidia ", 
	"child": "Kovács Berta 1896-", 
	"village": "", 
	"hint": "Budapesten halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács Ágnes 1897-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács József ", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kovács Sándor ", 
	"village": "Szeben", 
	"hint": ""}, 
{
	"page": "I.164", 
	"main": "ifj.Sándor Lajos 1872-1958", 
	"child": "Sándor Berta 1901-1978", 
	"village": "", 
	"hint": "lásd II.46.lap   nincs utód"}, 
{
	"page": "", 
	"main": "Bencze Mária 1880-1958", 
	"child": "Sándor Károly 1904-1942", 
	"village": "Brassó", 
	"hint": "1 fia volt,annak nincs utódja"}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Dénes 1907-", 
	"village": "", 
	"hint": "lásd II.88.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Anna 1910-", 
	"village": "", 
	"hint": "lásd II.86.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Ilona 1912-1912", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.165", 
	"main": "Derzsi Géza 1877-", 
	"child": "Fábián Károly 1894-", 
	"village": "", 
	"hint": "első férjétől"}, 
{
	"page": "1.neje ", 
	"main": "Szabó Anna 1872-1913", 
	"child": "Fábián Ferencz 1896-", 
	"village": "", 
	"hint": "első férjétől"}, 
{
	"page": "", 
	"main": "özv.Fábián Mártonné", 
	"child": "Fábián Anna 1898-", 
	"village": "", 
	"hint": "első férjétől"}, 
{
	"page": "", 
	"main": "", 
	"child": "Derzsi Klára 1901-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Derzsi Sára 1903-1903", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Derzsi Sarolta 1904-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Derzsi Mariska 1906-1911", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Derzsi Géza 1910-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Derzsi Béla 1913-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Debreczi Ilona 1885-", 
	"child": "Derzsi Ilona 1915-1916", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "tanítónő", 
	"child": "Derzsi Albert 1916-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Derzsi Aranka 1917-", 
	"village": "", 
	"hint": "1925-ben elköltöztek"}, 
{
	"page": "I.166", 
	"main": "Bencze Gyula 1880-1929", 
	"child": "Bencze Ilona 1901-", 
	"village": "Kénos ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Zsuzsánna 1883-", 
	"child": "Bencze József 1905-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Erzsébet 1907-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Gyula 1919-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.167", 
	"main": "Barczán Mihály 1875-1960", 
	"child": "Barczán Zsuzsa 1905-", 
	"village": "", 
	"hint": "gyermekkorában meghalt"}, 
{
	"page": "", 
	"main": "Nagy Mária 1881-1955", 
	"child": "Barczán Mihály 1907-1908", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Vilma 1909-1990", 
	"village": "", 
	"hint": "Dáné Béla és Ferencz anyja"}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Zsuzsa 1911-1995", 
	"village": "", 
	"hint": "lásd II.137.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Dénes 1914-1915", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.168", 
	"main": "ifj.Fodor Lajos 1876-1932", 
	"child": "Fodor Lajos 1906-1951", 
	"village": "", 
	"hint": "lásd II.117.lap"}, 
{
	"page": "", 
	"main": "Bencze Juliánna 1885-1976", 
	"child": "Fodor Berta 1908-1908", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.2.lap", 
	"child": "Fodor Ákos 1909-", 
	"village": "", 
	"hint": "lásd II.92.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Mihály 1912-", 
	"village": "", 
	"hint": "lásd II.93.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Vilma 1915-", 
	"village": "id.Bara Sándorné", 
	"hint": "lásd II.87.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Berta 1919-1967", 
	"village": "", 
	"hint": "lásd II.69.lap"}, 
{
	"page": "I.169", 
	"main": "Bencze Balázs 1879-1957", 
	"child": "Bencze Vilma 1907-", 
	"village": "Sándorfalva ", 
	"hint": "Balázsi Lajosné"}, 
{
	"page": "", 
	"main": "Csoma Juliánna 1887-1958", 
	"child": "Bencze Béla 1904-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Rozália 1913-1913", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Balázs 1915-", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Gábor 1917-1985", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Eszter 1918-", 
	"village": "Kőhalom ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Juliánna 1921-", 
	"village": "", 
	"hint": "Bató Gézáné"}, 
{
	"page": "I.170", 
	"main": "Sándor Zsigmond 1880-", 
	"child": "Sándor Rozália 1906-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Ilona 1886-", 
	"child": "Sándor Mózes ", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "I.171", 
	"main": "id.Bencze Dénes 1883-", 
	"child": "Bencze Gizella 1907-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Sándor Mária 1886-", 
	"child": "Bencze András 1908-", 
	"village": "", 
	"hint": "lásd II.78.lap "}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Etelka 1910-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Albert 1912-1913", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "id.Bencze Albert 1914-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Dénes 1916-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Matild 1919-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Sándor 1922-", 
	"village": "huszár Sándor", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mária 1924-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Póli 1926-", 
	"village": "", 
	"hint": "lásd II.128.lap"}, 
{
	"page": "I.172", 
	"main": "Nagy Ferencz1883-1969", 
	"child": "Nagy Ida 1908-1908", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Szabados Mária 1886-1953", 
	"child": "Nagy Márton 1909-1950", 
	"village": "", 
	"hint": "Nagy Vilmos apja "}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Rebeka 1911-", 
	"village": "Magyarország", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Ida 1916-1952", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Sándor 1918-1923", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.173", 
	"main": "id.Joó Albert 1883-", 
	"child": "ifj.Joó Albert 1907-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gelérd Juliánna 1887-1961", 
	"child": "Joó Margit 1910-", 
	"village": "Magyarország", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Gábor 1913-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Juliánna 1915-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Irén 1918-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Lajos 1920-1923", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Anna 1925-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.174", 
	"main": "legid.Barczán Lajos 1883-", 
	"child": "Barczán Gizella 1908-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gellérd Mária 1882-", 
	"child": "id.Barczán Lajos 1910-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Berta 1911-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.175/a", 
	"main": "Joó Gergely 1883-", 
	"child": "Bencze István 1912-1913", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Zsuzsánna 1884-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.175/b", 
	"main": "Baczó Dénes 1869-", 
	"child": "Baczó Áron 1898-", 
	"village": "", 
	"hint": "lásd II.124.lap"}, 
{
	"page": "", 
	"main": "Máté Eliza Lókodi papleány", 
	"child": "Baczó Albert 1902-1972", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó József 1904-1975", 
	"village": "", 
	"hint": "agglegény"}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Juliánna 1906-", 
	"village": "Budapest", 
	"hint": "Zsombori Zsigmondné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Rozália 1908-", 
	"village": "Homoródszentmárton", 
	"hint": "Balázs Vilmosné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Klára 1910-", 
	"village": "Városfalva ", 
	"hint": "Szabó Dénesné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Géza 1912-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Dénes 1917-1930", 
	"village": "", 
	"hint": "belefulladt a Homoródba "}, 
{
	"page": "I.176/a", 
	"main": "Almási Lajos 1883-", 
	"child": "Almási Regina 1912-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "elment Amerikába ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Beke Zsuzsa1890-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.176/b", 
	"main": "Baczó Gábor 1874-1953", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.38.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Kádár Eszter  Homoródsz.", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Sípos Berta  tanítólány ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.177", 
	"main": "Bencze László 1885-1925", 
	"child": "Bencze Géza 1908-1968", 
	"village": "Nagyvárad ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Katalin 1888-1972", 
	"child": "Bencze Rozália 1912-", 
	"village": "Bencze Gyuláné II.", 
	"hint": "lásd II.74.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Anna 1918-", 
	"village": "Ége ", 
	"hint": "Nagy Sándorné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Berta 1920-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.178", 
	"main": "Zsombori Sándor 1881-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód "}, 
{
	"page": "", 
	"main": "Orbán Mária 1884-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.179", 
	"main": "Nagy Elek ", 
	"child": "", 
	"village": "", 
	"hint": "elment Amerikába "}, 
{
	"page": "", 
	"main": "Balázsi Rozália 1887-", 
	"child": "Nagy Pál 1909-", 
	"village": "Székelyudvarhely", 
	"hint": "agglegény"}, 
{
	"page": "I.180", 
	"main": "Orbán Lajos 1876-1935", 
	"child": "Orbán Margit 1910-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Regina 1885-1929", 
	"child": "Orbán Balázs 1913-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "I.181", 
	"main": "legid.Orbán Elek 1878-1919", 
	"child": "Orbán Rozália 1909-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Koszta Rozália ", 
	"child": "id. Orbán Elek 1913-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Margit 1915-1917", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Mária 1919-", 
	"village": "Petek", 
	"hint": ""}, 
{
	"page": "I.182", 
	"main": "Bencze János 1876-", 
	"child": "Bencze János 1910-1969", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Ilona 1890-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.183", 
	"main": "Orbán János 1883-", 
	"child": "Orbán Ida 1910-", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Lőrincz Zsuzsa ", 
	"child": "Orbán Irén ", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "I.184", 
	"main": "Zólya Lukács 1881-1942", 
	"child": "Zólya Ferencz 1910-", 
	"village": "Bardoc", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.70.lap", 
	"child": "Zólya Gergely 1912-1941", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Mária 1889-1963", 
	"child": "Zólya Mózes 1915-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.144.lap", 
	"child": "Zólya Ilka 1919-", 
	"village": "Székelykeresztúr", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Eszter 1922-1950", 
	"village": "Recsenyéd ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Dénes 1924", 
	"village": "Barót", 
	"hint": "lásd II.148.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Gyula 1926-", 
	"village": "Székelykeresztúr", 
	"hint": ""}, 
{
	"page": "I.185", 
	"main": "Bencze Zsigmond 1888-", 
	"child": "Bencze Aladár 1910-1990", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Mária 1891-1933", 
	"child": "Bencze Albert 1913-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.34.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.186/a", 
	"main": "Barczán Zsigmond 1873-", 
	"child": "Barczán Zsuzsánna 1914-", 
	"village": "Dobó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gellérd Mária 1884-1921", 
	"child": "Barczán Mária 1920-1922", 
	"village": "", 
	"hint": ""}, 
{
	"page": "I.186/b", 
	"main": "id.Balázsi Miklós 1875-1941", 
	"child": "Balázsi Károly ", 
	"village": "", 
	"hint": "skarlátban meghalt"}, 
{
	"page": "", 
	"main": "Sándor Mária           Égei", 
	"child": "Balázsi józsef ", 
	"village": "", 
	"hint": "skarlátban meghalt"}, 
{
	"page": "", 
	"main": "Balázsi Sanyi bácsi nagyszülei", 
	"child": "Balázsi Ida 1906-", 
	"village": "Abránfalva ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Balázsi Miklós 1909-1987", 
	"village": "", 
	"hint": "lásd II.131.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Mária 1913-", 
	"village": "", 
	"hint": "kétoldali csípőficamos"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Juliánna 1921-", 
	"village": "Daróc", 
	"hint": ""},
  {
	"page": "II.1/a", 
	"main": "Molnár Sándor 1887-", 
	"child": "Molnár Eszter 1911-1912", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gellérd Anna 1890", 
	"child": "Molnár Eszter 1913-1917", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd a 32 oldal", 
	"child": "Molnár Sándor 1916-", 
	"village": "Ugra", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.1/b", 
	"main": "id. Pál József 1890-1974", 
	"child": "Pál Juliánna  1921-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Szőcs Juliánna 1900-1976", 
	"child": "Pál Ágnes  1923-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Abásfalva", 
	"child": "Pál Berta   1928-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.2", 
	"main": "id.Pál Lajos 1887-1979      I.106.lap", 
	"child": "Pál József 1925-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "1,neje", 
	"main": "Beke Zsuzsa 1893-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2, neje", 
	"main": "Nagy Mária 1897-1920", 
	"child": "Pál Lajos 1914-1914", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Anna 1915-1915", 
	"village": "", 
	"hint": ""}, 
{
	"page": "3, neje", 
	"main": "Nagy Berta 1899-1971", 
	"child": "ifj.Pál Lajos 1921-", 
	"village": "Kénos", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Sándor 1923-", 
	"village": "", 
	"hint": "lásd 161,oldal"}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Ida 1924-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Elek 1927-", 
	"village": "Székelyudvarhely", 
	"hint": "lásd 148,oldal"}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Irma 1931-2015", 
	"village": "", 
	"hint": "lásd 150,oldal"}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Albert 1936-", 
	"village": "", 
	"hint": "lasd 127, oldal"}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.3", 
	"main": "Bencze Gábor 1886-", 
	"child": "", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Almási Mária 1890-", 
	"child": "", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Áron 1885-1972", 
	"child": "Joó Imre 1913-1994", 
	"village": "Homoródszentpéter", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Mária 1892-1978", 
	"child": "Joó Margit 1915-", 
	"village": "Csíkszentgyörgy", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Regina 1917-", 
	"village": "Vargyas", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Áron 1918-", 
	"village": "Karácsonyfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Ferencz 1920-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Áron Dénes  1925-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Eszter 1928-1988 férjtelen", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Irma 1930-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Gábor 1935-1936", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.4", 
	"main": "Bencze Dénes 1884-1971", 
	"child": "Bencze  Gyula 1912-1977", 
	"village": "", 
	"hint": "lásd 162 oldal"}, 
{
	"page": "", 
	"main": "Balázsi Berta 1890-1970", 
	"child": "önakasztás", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze  Dénes 1913-", 
	"village": "", 
	"hint": "meghalt Homoródon"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Róza 1915-", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Irma 1923-", 
	"village": "Jánosfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Imre 1924-", 
	"village": "", 
	"hint": "lásd 137 oldal"}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II. 5", 
	"main": "Bencze Dénes 1887-1941", 
	"child": "Bencze Berta 1912-", 
	"village": "", 
	"hint": "lásd 98.oldal"}, 
{
	"page": "1, neje", 
	"main": "Sándor Ilona 1892-1911", 
	"child": "Bencze Ilona 1914- 1927", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Zsigmond 1917- 1917", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2-ik neje", 
	"main": "Beke Regina 1896-1975", 
	"child": "Bencze  József 1919-1924", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Eszter 1921-2009", 
	"village": "", 
	"hint": "lásd II.117.lap/69.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Eliza 1924-1950", 
	"village": "", 
	"hint": "Beke Jozsefné volt"}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.6", 
	"main": "Dáné Lajos 1886-", 
	"child": "", 
	"village": "", 
	"hint": "I.világháboruba elveszett"}, 
{
	"page": "", 
	"main": "Balázs Amália 1886-", 
	"child": "Dáné Rózália", 
	"village": "Lókod", 
	"hint": "Lókodban él"}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Pál György 1913-1961 ", 
	"child": "Pál Margit 1939-", 
	"village": "Detta", 
	"hint": "Bágyba temették"}, 
{
	"page": "", 
	"main": "Csíkszentgyörgy szül.", 
	"child": "Pál Gábor 1941- ", 
	"village": "", 
	"hint": "Bágyba temették"}, 
{
	"page": "", 
	"main": "Joó Margit 1915-", 
	"child": "Pál Ferenc 1943-", 
	"village": "", 
	"hint": "Bágyba temették"}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Gyula András 1947-", 
	"village": "Nagyvárad", 
	"hint": "Bágyba temették"}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Áron 1948-", 
	"village": "", 
	"hint": "Bágyba temették"}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Irén 1951-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Gizella 1952-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.7", 
	"main": "Bencze Pál 1890-1914", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Berta 1891-", 
	"child": "", 
	"village": "Városfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.8", 
	"main": "Balázsi Ferencz 1885-", 
	"child": "", 
	"village": "", 
	"hint": "Amerikába halt meg"}, 
{
	"page": "", 
	"main": "Beke Berta 1891-", 
	"child": "", 
	"village": "", 
	"hint": "Amerikába halt meg"}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.9", 
	"main": "Bencze Zsigmond 1890-", 
	"child": "Bencze Miklós 1912-1912", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Háboruban elhalt", 
	"child": "", 
	"village": "", 
	"hint": "ikerpár"}, 
{
	"page": "", 
	"main": "Almási Juliánna 1894-1986", 
	"child": "Bencze Eszter  1912-1912", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2-ik férje", 
	"main": "ifj.Zsombori Ferencz 1891-1962", 
	"child": "Zsombori Berta 1919-", 
	"village": "Olthévíz", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Vilma 1921-1923", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Vilma 1923-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Dénes 1925-", 
	"village": "Nyárádszereda", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Lajos 1927-1927", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Lajos 1928-2012", 
	"village": "", 
	"hint": "lásd II .126 .lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Erzsébet1930-1930", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.10", 
	"main": "id.Dáné Károly 1887-1971", 
	"child": "Dáné Berta 1915-", 
	"village": "", 
	"hint": "lásd 92 oldal"}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Ákosné", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Beke Zsuza 1890-1913 ", 
	"child": "Dáné Irma 1923-", 
	"village": "", 
	"hint": "lásd 130 oldal"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Jánosné", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Dáné Károly 1930-1993", 
	"village": "", 
	"hint": "lásd 138 oldal"}, 
{
	"page": "", 
	"main": "", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.11", 
	"main": "Bató Ferencz 1888-", 
	"child": "Bató Vilma 1915-1917", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dombi Rebeka 1884-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.12", 
	"main": "Pál Mózes 1889-1964", 
	"child": "Pál Dénes 1915-", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Beke Ilona 1896-1978", 
	"child": "Pál Ferencz 1917-", 
	"village": "", 
	"hint": "háborúba elesett"}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Anna 1919-1922", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Róza 1922-1923", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Ignácz 1924-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Vilma 1926-", 
	"village": "Craiova", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Mózes 1928-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Anna 1931-", 
	"village": "Fehéregyháza", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Gábor 1933-", 
	"village": "", 
	"hint": "lásd II.147.lap"}, 
{
	"page": "II.13", 
	"main": "Bencze Elek 1893-1972", 
	"child": "Bencze Juliánna 1919-", 
	"village": "Sándorfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Vilma 1896-1975", 
	"child": "Bencze Elek 1920-1944", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Pál 1922-", 
	"village": "", 
	"hint": "lásd II.131.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ida 1923-1970", 
	"village": "", 
	"hint": "lásd II.141.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze József  1928-1928", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze József  1929-1929", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze  Vilma 1929-1929", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze József 1932-1936", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.14", 
	"main": "Balázsi József 1892-1916", 
	"child": "Balázsi József 1915-", 
	"village": "", 
	"hint": "lásd II.106.lap"}, 
{
	"page": "", 
	"main": "Bencze Lidia 1895-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.15", 
	"main": "Bencze Béla 1893-", 
	"child": "", 
	"village": "", 
	"hint": "háborúba elesett"}, 
{
	"page": "", 
	"main": "Zólya Zsuzsa 1893-", 
	"child": "", 
	"village": "férjhez ment ", 
	"hint": "Balázsi Mózeshez"}, 
{
	"page": "II.16", 
	"main": "Dáné Gábor 1892-1955", 
	"child": "Dáné Borbála 1920-", 
	"village": "id.Nagy Mártonné", 
	"hint": "lásd II.102.lap"}, 
{
	"page": "", 
	"main": "Bencze Ágnes 1893-1969", 
	"child": "", 
	"village": "később Petek", 
	"hint": "Jánosi Lajosné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Vilma 1922-", 
	"village": "Homoródszentmárton", 
	"hint": "Csala Dénesné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Lajos 1924-1924", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Anna1925-", 
	"village": "Balázsi Andrásné", 
	"hint": "lásd II.158/a lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Lajos 1927-", 
	"village": "", 
	"hint": "lásd II.139.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Róza 1929-1929", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.17", 
	"main": "Balázsi Zsigmond 1893-1967", 
	"child": "Balázsi Vilma 1919-1995", 
	"village": "id.Bencze Dezsőné", 
	"hint": "lásd II.107.lap"}, 
{
	"page": "", 
	"main": "Nagy Berta 1894-1956", 
	"child": "Balázsi Lajos 1922-1922", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Róza 1923-1995", 
	"village": "Joó Jenőné", 
	"hint": "lásd II.128.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Regina 1926-1986", 
	"village": "vadházasság Nagy Zsigával", 
	"hint": "lásd II.162.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Berta 1928-", 
	"village": "Csernáton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Ida 1930-1930", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Lajos 1931-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.18", 
	"main": "Simó Dénes 1899-1955", 
	"child": "Simó Róza 1917-", 
	"village": "Farcád", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Berta 1900-1994", 
	"child": "Simó József 1919-1927", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Simó Berta 1922-", 
	"village": "Magyarország", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Simó Mária 1924-", 
	"village": "Székelyudvarhely", 
	"hint": "Csoma Dénesné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Simó Vilma 1929-", 
	"village": "Barót ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Simó Lajos 1930-1930", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.19", 
	"main": "Joó Ferencz 1892-1953", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Balázsi Berta 1897-1922", 
	"child": "Joó Erzsébet 1919-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Jakab Mária (Ége)1891-1973", 
	"child": "Joó Ferencz 1925-1944", 
	"village": "", 
	"hint": "háborúba elesett"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Márton 1929-1929", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Ida 1932-", 
	"village": "Joó Gézáné", 
	"hint": "lásd II.146/a lap"}, 
{
	"page": "II.20", 
	"main": "id.Pál János 1891-1963", 
	"child": "ifj.Pál János 1919-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.106.lap", 
	"child": "Pál Ida 1921-1923", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje ", 
	"main": "Benedek Anna(Gyepes ) -1923", 
	"child": "Pál Áron 1922-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Sándor Zsuzsa 1900-1988", 
	"child": "Pál Ilona 1929-1929", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Lázár 1930-1931", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Imre 1935-", 
	"village": "Pál Lajos apja", 
	"hint": "lásd II.149.lap"}, 
{
	"page": "II.21", 
	"main": "id.Bencze Gyula 1894-1979", 
	"child": "Bencze Márta 1919-", 
	"village": "Bencze Albertné (mézes)", 
	"hint": "lásd II.6.lap"}, 
{
	"page": "", 
	"main": "(nadrágos Gyula)", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balási Regina 1898-1975", 
	"child": "Bencze Irma 1923-", 
	"village": "Balázsi Rezsőné", 
	"hint": "lásd II.157.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Regina 1925-1925", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mária 1928-1986", 
	"village": "", 
	"hint": "szellemileg fogyatékos"}, 
{
	"page": "II.22", 
	"main": "Nagy István ", 
	"child": "Nagy Erzsébet 1919-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Lőrincz Juliánna 1893-1978", 
	"child": "Nagy Kálmán ", 
	"village": "Magyarország", 
	"hint": ""}, 
{
	"page": "II.23", 
	"main": "Joó András 1885-", 
	"child": "", 
	"village": "", 
	"hint": "lásd I.86.lap"}, 
{
	"page": "", 
	"main": "Balázsi Mária 1898-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.24", 
	"main": "Balázsi Dénes 1900-1984", 
	"child": "Balázsi Rezső 1920-", 
	"village": "", 
	"hint": "lásd II.157/a lap"}, 
{
	"page": "", 
	"main": "Bencze Mária 1898-1991", 
	"child": "Balázsi Lenke 1924-1981", 
	"village": "világtalan és", 
	"hint": "szellemileg fogyatékos"}, 
{
	"page": "II.25", 
	"main": "Tőzsér István 1897-  ( Kénosi)", 
	"child": "", 
	"village": "Fogaras", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Demeter Mária 1888-", 
	"child": "", 
	"village": "Fogaras", 
	"hint": "nincs utód"}, 
{
	"page": "II.26", 
	"main": "Fodor Mózes 1897-1963", 
	"child": "Fodor Eszter 1920-1923", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gellérd Ágnes 1896", 
	"child": "Fodor Dénes 1922-1923", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.130.lap", 
	"child": "Fodor Eszter 1923-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Dénes 1927-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Berta 1940-", 
	"village": "Homoródszentmárton", 
	"hint": "Bencze Ferencné"}, 
{
	"page": "II.27", 
	"main": "Nagy Sándor 1893-1960", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Fodor Mária 1895-", 
	"child": "", 
	"village": "", 
	"hint": "elváltak"}, 
{
	"page": "2.neje", 
	"main": "Bencze Berta 1898-1963", 
	"child": "Nagy Anna 1920-1977", 
	"village": "Ülke", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Sándor 1921-1936", 
	"village": "", 
	"hint": "önakasztás"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Róza 1923-", 
	"village": "Vargyas", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Gergely 1924-", 
	"village": "Vargyas", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy János 1926-1926", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Ilona 1927-", 
	"village": "Homoród", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Eszter 1929-1929", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy János 1930-", 
	"village": "", 
	"hint": "lásd II.123.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Miklós 1932-1933", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Lajos 1933-1935", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Miklós 1934-1977", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Ida 1936-1939", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Sándor 1939-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Ida 1942-", 
	"village": "Székelyudvarhely", 
	"hint": "Szabó Mózesné"}, 
{
	"page": "II.28", 
	"main": "Lőrincz Márton 1897-", 
	"child": "Lőrincz Jolán 1921-2014", 
	"village": "", 
	"hint": "lásd II.101lap"}, 
{
	"page": "", 
	"main": "Demeter Mária   ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.29", 
	"main": "Joó József 1894-1981", 
	"child": "Joó Irma 1920-", 
	"village": "", 
	"hint": "lásd II.105.lap"}, 
{
	"page": "", 
	"main": "Almási Berta 1896-1976", 
	"child": "Joó Berta 1922-1923", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.117.lap", 
	"child": "Joó Etelka 1923-1924", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Béla 1925-", 
	"village": "Székelyudvarhely", 
	"hint": "lásd II.150.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Aladár 1928-", 
	"village": "Székelyudvarhely", 
	"hint": "lásd II.135/a.lap "}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Jenő 1930-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Gyula 1935-2020", 
	"village": "", 
	"hint": "lásd II.12.lap"}, 
{
	"page": "II.30", 
	"main": "Balázsi Mózes         -1930", 
	"child": "Balázsi Anna 1920-", 
	"village": "", 
	"hint": "lásd II.61.lap"}, 
{
	"page": "", 
	"main": "Zólya Zsuzsa 1893-1961", 
	"child": "Balázsi Emma 1922-1923", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Imre 1924-", 
	"village": "", 
	"hint": "háborúba elesett"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Emma 1926-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Juliánna 1929-", 
	"village": "Székelyudvarhely", 
	"hint": "Joó Béláné"}, 
{
	"page": "II.31/a", 
	"main": "Orbán József 1896-1980", 
	"child": "Orbán Emma 1920-1923", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Irén 1898-1980", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.31/b", 
	"main": "Orbán Mihály 1898-1969", 
	"child": "Orbán Juliánna 1921-", 
	"village": "id.Barczán Lajosné ", 
	"hint": "lásd II.110.lap"}, 
{
	"page": "", 
	"main": "Medgyesi Karolina 1897-1990", 
	"child": "Orbán Sándor 1923-", 
	"village": "", 
	"hint": "lásd II.140.lap"}, 
{
	"page": "", 
	"main": "Dályai", 
	"child": "Orbán Emma 1926-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Mózes 1927-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.32", 
	"main": "Mihály István 1895-1964", 
	"child": "Mihály Vilma 1920-", 
	"village": "Homoród", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Kovács Juliánna 1891-1922", 
	"child": "Mihály Juliánna 1922-1922", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Gellérd Anna 1890-1978", 
	"child": "Mihály Imre 1924-1943", 
	"village": "", 
	"hint": "háborúba elesett"}, 
{
	"page": "", 
	"main": "lásd II.1.lap", 
	"child": "Mihály Eszter 1925-", 
	"village": "Brassó", 
	"hint": "Páll Áronné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Mihály Dénes 1927-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Mihály Berta 1929-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.33", 
	"main": "Zólya Mózes 1895-1944", 
	"child": "Zólya Róza 1921-", 
	"village": "Homoródszentpál", 
	"hint": "özv.Nagy Ferencné"}, 
{
	"page": "", 
	"main": "Balázsi Mária 1901-1938", 
	"child": "Zólya Bálint 1925-", 
	"village": "", 
	"hint": "lásd II.128.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Anna 1930-", 
	"village": "Ége ", 
	"hint": "Orbán Balázsné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Irma 1932-1932", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.34", 
	"main": "Zólya Dénes 1898-1950", 
	"child": "Zólya Dénes 1921-1937", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Bencze Mária 1891-1933", 
	"child": "Zólya József 1925-1926", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Bencze Juliánna 1911-", 
	"child": "Zólya József 1932-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "(Dályai)", 
	"child": "Zólya Dénes 1937-", 
	"village": "Lupény", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Jenő 1939-", 
	"village": "Brassó", 
	"hint": "lásd II:161.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Ottilia 1942-", 
	"village": "Joó Gy.Dénesné", 
	"hint": "lásd II.156.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Irén 1943-", 
	"village": "Szentegyháza ", 
	"hint": "lásd II.160.lap"}, 
{
	"page": "II.35", 
	"main": "Kandó Kálmán  (Dályai) 1920-", 
	"child": "Kandó Kálmán 1921-", 
	"village": "Dálya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Lidia 1895-", 
	"child": "Kandó Lenke 1925-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kandó Mária 1926-1927", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kandó Mária 1928-1928", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.36", 
	"main": "id.Lőrincz Balázs 1893-1955", 
	"child": "Lőrincz Béla 1921-", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.79/2.lap", 
	"child": "Lőrincz Dávid 1926-", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Eszter 1899-1938", 
	"child": "Lőrincz Vilma 1923-", 
	"village": "", 
	"hint": "meghalt a II.vh.idején"}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Lőrincz Balázs 1929-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz József 1937-1939", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.37", 
	"main": "Joó Péter 1892-1964", 
	"child": "Joó Emma          -1924", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Regina 1897-1979", 
	"child": "Joó Endre 1925-1988", 
	"village": "Segesvár", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Emma 1928-", 
	"village": "Barót ", 
	"hint": "lásd II.148.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Árpád 1929-", 
	"village": "Joó Elzáék apja", 
	"hint": "lásd II.163.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Jenő 1932-1932", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Jenő 1935-1936", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Jenő 1939-1939", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.38", 
	"main": "F", 
	"child": "Joó Irma 1922-", 
	"village": "Bencze H. Dénesné", 
	"hint": "lásd II.116.lap"}, 
{
	"page": "", 
	"main": "Nagy Róza 1898-1993", 
	"child": "Joó Emma 1924-1924", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Emma 1925-", 
	"village": "Bencze Pálné", 
	"hint": "lásd II.136.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Imre 1929-1929", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Juliánna 1930-", 
	"village": "Dáné Károlyné", 
	"hint": "lásd II.138.lap"}, 
{
	"page": "II.39", 
	"main": "Zsombori Pál 1897-1957", 
	"child": "Zsombori Sándor 1921-2004", 
	"village": "", 
	"hint": "lásd II.146/a.lap"}, 
{
	"page": "", 
	"main": "lásd I.145.lap", 
	"child": "Zsombori János 1923-2011", 
	"village": "", 
	"hint": "lásd II.142.lap"}, 
{
	"page": "", 
	"main": "Orbán Irma 1897-1984", 
	"child": "Zsombori Irma 1927-2016", 
	"village": "id.Bencze Béláné", 
	"hint": "lásd II.135/a.lap"}, 
{
	"page": "II.40", 
	"main": "Beke Lajos 1901-1990", 
	"child": "Beke  Berta 1921-1921", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Juliánna 1900-1967", 
	"child": "Beke József 1923-", 
	"village": "Városfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Lajos 1924-", 
	"village": "Székelyudvarhely", 
	"hint": "lásd II.141.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Vilma 1926-", 
	"village": "Bencze M.Imréné", 
	"hint": "lásd II.137.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Mária 1929-", 
	"village": "Zsombori Sándorné", 
	"hint": "lásd II.146.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Ferencz 1930-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Dénes 1933-1933", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Dénes 1937-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke János 1942-1943", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Zsigmond 1940-1972", 
	"village": "baleset érte traktorral", 
	"hint": "lásd II.149.lap"}, 
{
	"page": "II.41", 
	"main": "ifj.Joó János 1898-1973", 
	"child": "Joó Eszter 1922-", 
	"village": "Recsenyéd ", 
	"hint": "Magyariné"}, 
{
	"page": "", 
	"main": "Zólya Juliánna 1900-1991", 
	"child": "Joó Matild 1924-", 
	"village": "Marosvásárhely", 
	"hint": "Bencze Jozsefné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Etelka 1926-", 
	"village": "id.Orbán Sándorné", 
	"hint": "lásd II.140/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Ella 1929-", 
	"village": "Dáné Lajosné", 
	"hint": "lásd II.139.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Piroska 1933-1933", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Elza 1931-", 
	"village": "Csíkszereda ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Ödön 1938-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Juliánna 1941-", 
	"village": "Gyimesközéplok", 
	"hint": "Demeter Domokosné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Piroska 1945-", 
	"village": "Csíkszereda ", 
	"hint": "nincs utód"}, 
{
	"page": "II.42", 
	"main": "Balázsi Gergely 1895-1970", 
	"child": "Balázsi Dénes 1923-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Zsuzsa 1894-1967", 
	"child": "Balázsi András 1925-", 
	"village": "", 
	"hint": "lásd II.158/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Gergely 1927-1928", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Gergely 1929-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Lajos 1930-", 
	"village": "", 
	"hint": "lásd II.166.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Vilma 1937-", 
	"village": "Bencze Istvánné", 
	"hint": "lásd II.143.lap"}, 
{
	"page": "II.43", 
	"main": "id. Bencze István 1899-1958", 
	"child": "Bencze József 1924-", 
	"village": "Marosvásárhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Ida 1899-1965", 
	"child": "Bencze Róza 1925-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze László 1928-1997", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Juliánna 1929-1929", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Bencze István 1933-", 
	"village": "", 
	"hint": "lásd II.143.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Juliánna 1936-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.44/1", 
	"main": "Joó Zsigmond 1898-1979", 
	"child": "Joó Juliánna 1925-", 
	"village": "", 
	"hint": "lásd II.138.lap"}, 
{
	"page": "", 
	"main": "Simó Vilma 1903-1949", 
	"child": "Joó Eliza 1926-1926", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Lajos 1933-", 
	"village": "Újmoldova", 
	"hint": "lásd II.44/2.lap"}, 
{
	"page": "II.44/2", 
	"main": "Joó Lajos 1933-", 
	"child": "Joó Éva 1954-", 
	"village": "Újmoldova", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Irén 1933-", 
	"child": "Joó Ildikó 1963-", 
	"village": "Újmoldova", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Ernő bácsiék testvére", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.45", 
	"main": "Bencze Imre 1899-1978", 
	"child": "Bencze Zelma 1924-1978", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Berta 1902-1959", 
	"child": "Bencze Zsigmond 1926-", 
	"village": "Miskolc", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Elvira 1928-1928", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Rezső 1929-", 
	"village": "", 
	"hint": "lásd II.155.lap"}, 
{
	"page": "II.46", 
	"main": "Balázsi Domokos 1900-1958", 
	"child": "", 
	"village": "", 
	"hint": "elváltak"}, 
{
	"page": "", 
	"main": "Bencze (Sándor)Berta1901-1978", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.47", 
	"main": "Balázsi Sándor 1896-1978", 
	"child": "Balázsi Ferencz 1925-2012", 
	"village": "", 
	"hint": "lásd II.145/a.lap"}, 
{
	"page": "", 
	"main": "lásd I.159.lap", 
	"child": "Balázsi Magda 1928-1959", 
	"village": "Bencze Pálné volt", 
	"hint": "lásd II.136/a.lap"}, 
{
	"page": "", 
	"main": "Zólya Katalin 1904-1952", 
	"child": "Balázsi Sándor 1931-", 
	"village": "Székelyudvarhely", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Bálint 1931-1976", 
	"village": "Székelyudvarhely", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Béla 1938-1939", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Béla Elemér 1942-1942", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.48", 
	"main": "id.Lőrincz Gyula 1901-1977", 
	"child": "Lőrincz Irén 1926-", 
	"village": "Telekfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Róza 1907-1984", 
	"child": "ifj.Lőrincz Gyula 1929-2021", 
	"village": "", 
	"hint": "lásd II.170/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Ákos 1934-", 
	"village": "", 
	"hint": "lásd II.155/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz István 1938-", 
	"village": "Marosvásárhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Árpád 1942-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Lőrincz Erzsébet 1944-", 
	"village": "Abásfalva", 
	"hint": ""}, 
{
	"page": "II.49", 
	"main": "id.Dáné Zsigmond 1899-1986", 
	"child": "Dáné Lajos 1926-1927", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Vilma 1902-1976", 
	"child": "Dáné Lajos 1928-1975", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Albert 1929-", 
	"village": "", 
	"hint": "lásd II.150/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Zsigmond 1939-", 
	"village": "kovács", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Vilma(Vera)1941-", 
	"village": "Joó Sándorné", 
	"hint": "lásd II.151/a.lap"}, 
{
	"page": "II.50", 
	"main": "Nagy Gábor 1901-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Rebeka 1906", 
	"child": "", 
	"village": "Recsenyédre költöztek és", 
	"hint": "ott meghaltak"}, 
{
	"page": "II.51", 
	"main": "Joó Dénes 1901-1963", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Lőrincz Berta 1907-", 
	"child": "elváltak", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Nagy Ilona 1907-1992", 
	"child": "Joó Ernő 1934-1934", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Ernő 1935-1936", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Sándor 1941-", 
	"village": "", 
	"hint": "lásd II.151/a.lap"}, 
{
	"page": "II.52", 
	"main": "Bencze Sándor1900-", 
	"child": "Bencze Béla 1927-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Regina 1904-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.53", 
	"main": "Bencze József 1897-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Gergely Juliánna (Dályai)", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.54", 
	"main": "Kovács Pál Mózes 1898-1982", 
	"child": "Pál Ilona 1926-", 
	"village": "Dobó,Béta", 
	"hint": ""}, 
{
	"page": "", 
	"main": "(Füsti Mózsi)", 
	"child": "Pál Etelka 1928-", 
	"village": "Pál Sándorné", 
	"hint": "lásd II.161.lap"}, 
{
	"page": "", 
	"main": "Bencze Mária 1905-1961", 
	"child": "Pál Jenő 1930-1955", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "elváltak", 
	"child": "Pál Dezső 1934-2002", 
	"village": "Hargitabánya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál József 1942-", 
	"village": "Csíkszereda ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Pál Zsigmond 1945-2016", 
	"village": "Csíkszereda ", 
	"hint": "Magyarország"}, 
{
	"page": "II.55", 
	"main": "Dáné József 1903-1972", 
	"child": "Dáné Ella 1929-1929", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gellérd Anna 1910-1991", 
	"child": "Dáné Ella 1930-", 
	"village": "Magyarország", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Ézsaiás 1932-", 
	"village": "", 
	"hint": "lásd II.165.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné József 1933-1933", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné József 1934-", 
	"village": "Érmihályfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Domokos 1936-2015", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Eliza 1938-", 
	"village": "Szatmárnémeti", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Sarolta 1940-", 
	"village": "Máréfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Anna 1943-", 
	"village": "Kőrispatak", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Kálmán 1946-", 
	"village": "Zetelaka", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Sándor 1951-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.56", 
	"main": "Bencze Márton 1904-2005", 
	"child": "Bencze Kálmán 1928-1928", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Irma 1904-1984", 
	"child": "Bencze Irma 1936-", 
	"village": "", 
	"hint": "lásd II.122.lap"}, 
{
	"page": "", 
	"main": "elváltak", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.57", 
	"main": "Joó Zsigmond 1903-1984", 
	"child": "Joó Zsigmond 1928-", 
	"village": "", 
	"hint": "lásd II.136.lap"}, 
{
	"page": "", 
	"main": "Orbán Róza 1907-1968", 
	"child": "Joó Eliza 1929-1951", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Irén 1936-", 
	"village": "Székelyudvarhely", 
	"hint": "lád II.154/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Eszter 194-", 
	"village": "Brassó", 
	"hint": "Fekete Mihályné"}, 
{
	"page": "II.58", 
	"main": "Bencze Dávid 1902-1978", 
	"child": "Bencze Miklós 1929-2021", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Dáné Mária 1908-1971", 
	"child": "Bencze Irén 1933-", 
	"village": "Újmoldova", 
	"hint": "lásd II.44/b"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ernő 1942-2006", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.59", 
	"main": "Zólya Zsigmond 1903-197", 
	"child": "Zólya Zsigmond 1930-", 
	"village": "", 
	"hint": "lásd II.154/b.lap"}, 
{
	"page": "", 
	"main": "Bencze Mária 1905-1981", 
	"child": "Zólya Kálmán 1940-1940", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.60/a", 
	"main": "Beke Károly 1902-1954", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "1.neje", 
	"main": "Dáné Berta 1904-1964", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "2.neje", 
	"main": "Orbán Eszter 1894-1973", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.60/b", 
	"main": "Beke Gyula 1897-1947", 
	"child": "Beke Berta 1923-", 
	"village": "Zsombori Jánosné", 
	"hint": "lásd II.142.lap"}, 
{
	"page": "", 
	"main": "György Zsuzsánna 1899-1975", 
	"child": "Beke Béla ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "(Jásfalvi)", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.61", 
	"main": "Bencze Sándor 1909-1959", 
	"child": "Bencze Margit 1930-1933", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje ", 
	"main": "Orbán Irén           -1941", 
	"child": "Bencze Juliánna 1931-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Margit 1934-1934", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Vilmos 1935-", 
	"village": "Üvegcsűr ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Jolán 1939-1939", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Balázsi Anna 1920-1998", 
	"child": "Bencze József 1943-", 
	"village": "Székelyudvarhely", 
	"hint": "lásd II.169/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Imre 1945-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Lidia 1949-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.62", 
	"main": "id.Kelemen Lajos 1904-199", 
	"child": "ifj.Kelemen Lajos 1931-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Emma 1903-1988", 
	"child": "Kelemen István 1936-", 
	"village": "Balánbánya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kelemen Emma 1938-", 
	"village": "Bencze Antalné ", 
	"hint": "lásd II.153/b"}, 
{
	"page": "II.63", 
	"main": "Joó Gergely 1905-1982", 
	"child": "Joó Gergely 1931-1950", 
	"village": "", 
	"hint": "szellemileg fogyatékos"}, 
{
	"page": "", 
	"main": "Nagy Irma 1906-1979", 
	"child": "Joó József 1933-1943", 
	"village": "", 
	"hint": "szellemileg fogyatékos"}, 
{
	"page": "II.64", 
	"main": "Szabó Bálint Ferencz 1909-1981", 
	"child": "Szabó Ferencz 1930+-1953", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Molnár Berta 1908-1987", 
	"child": "Szabó Dénes 1933-", 
	"village": "Újmoldova", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Szabó Mózes 1939-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Szabó Mária 1944-", 
	"village": "Orbán Elekné ", 
	"hint": "lásd II.159.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Szabó Berta 1935-", 
	"village": "Joó Aladárné ", 
	"hint": "lásd II.135.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Szabó Imre 1947-1950", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.65", 
	"main": "Bencze József 1905-1956", 
	"child": "Bencze Ödön 1933-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Barczán Gizella 1908-1986", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.66", 
	"main": "id. Zsombori József 1904-1987", 
	"child": "Zsombori Mózes 1930-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.145.lap", 
	"child": "Zsombori Zsigmond 1932-", 
	"village": "Kénos ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Erzsébet 1907-1985", 
	"child": "Zsombori Eszter 1935-", 
	"village": "Balázsi Ferencné", 
	"hint": "lásd II.145/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori József 1937-1938", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori József 1939-", 
	"village": "", 
	"hint": "lásd II.134/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Ferencz 1944-", 
	"village": "Medgyes", 
	"hint": ""}, 
{
	"page": "II.67", 
	"main": "Simó József 1908-1966", 
	"child": "Simó József 1931-1931", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Gizella 1907-1985", 
	"child": "Simó Magda 1932-", 
	"village": "Székelyudvarhely", 
	"hint": "Orbán Mózesné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Simó Zakariás 1934-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Simó József 1939-1939", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Simó Ida 1939-1939", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Simó József 1942-1942", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.68", 
	"main": "Bencze Géza 1904-", 
	"child": "Bencze Ida 1932-1933", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Csoma Katalin 1911-1997", 
	"child": "Bencze Géza 1933-1934", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ida 1935-1945", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mózes 1943-2002", 
	"village": "", 
	"hint": "szellemi fogyatékos"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Márta 1941-", 
	"village": "Nagy Vilmosné", 
	"hint": "lásd II.140/a.lap"}, 
{
	"page": "II.69", 
	"main": "Bencze Dénes 1909-1981", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "(Faluvége bá)", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje ", 
	"main": "Simó Ida 1905-1935", 
	"child": "Bencze Elza 1931-", 
	"village": "Zsombori Lajosné ", 
	"hint": "lásd II.126.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ákos 1935-1935", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Fodor Berta 1919-1976", 
	"child": "Bencze Margit 1941-", 
	"village": "ifj.Balázsi Józsefné", 
	"hint": "lád II.131/a.lap"}, 
{
	"page": "", 
	"main": "külön éltek", 
	"child": "Bencze Dénes 1943-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Attila 1946-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze József 1949-", 
	"village": "Homoród", 
	"hint": ""}, 
{
	"page": "II.70", 
	"main": "Csoma József 1896-1981", 
	"child": "Csoma Emma 1917-1926", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.108.lap", 
	"child": "Csoma Ida 1920-1922", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Kacsó Juliánna 1892-1949", 
	"child": "Csoma Dénes 1922-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Nyárádmagyarosi", 
	"child": "Csoma Ida 1925-", 
	"village": "Kányád ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Emma 1926-1928", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Cecilia 1929-2002", 
	"village": "Bató Gáborné", 
	"hint": "lásd II.157.lap"}, 
{
	"page": "2.neje", 
	"main": "Szabó Bálint Zsuzsánna", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Telekfalvi", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.71", 
	"main": "id.Dáné Zsigmnd 1906-1979", 
	"child": "Dáné Aladár 1933-", 
	"village": "", 
	"hint": "lásd II.132/b.lap"}, 
{
	"page": "", 
	"main": "Almási Regina 1912-1997", 
	"child": "Dáné Zsigmond 1935-", 
	"village": "", 
	"hint": "lásd II.160/a.lap"}, 
{
	"page": "II.72", 
	"main": "id.Dáné Ferencz 1907-1979", 
	"child": "ifj.Dáné Ferencz 1933-", 
	"village": "", 
	"hint": "lásd II.139/b.lap"}, 
{
	"page": "", 
	"main": "Barczán Vilma 1909-1990", 
	"child": "Dáné Béla 1938-", 
	"village": "", 
	"hint": "lásd II.167/a.lap"}, 
{
	"page": "II.73", 
	"main": "id.Bencze Lajos 1908-1997", 
	"child": "Bencze Lujza 1932-1984", 
	"village": "Székelyudvarhely", 
	"hint": "Lőrincz Balázsné"}, 
{
	"page": "", 
	"main": "Dáné Lujza 1908-1995", 
	"child": "Bencze Zsigmond 1934-", 
	"village": "", 
	"hint": "lásd II.152/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Pál 1935-1944", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Lajos 1937-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ilona 1939-1939", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.74", 
	"main": "Bencze Gyula II. 1903-1983", 
	"child": "Bencze Károly 1933-", 
	"village": "Csíkszereda ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.149.lap", 
	"child": "Bencze Imre 1934-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Róza 1912-1986", 
	"child": "Bencze Ákos 1937-", 
	"village": "Székelyudvarhely", 
	"hint": "lásd II.133/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mihály 1939-", 
	"village": "Beszterce", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Emma 1941-", 
	"village": "Bencze Józsefné", 
	"hint": "lásd II.125/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Gyula 1946-1964", 
	"village": "", 
	"hint": "traktorista baleset "}, 
{
	"page": "II.75", 
	"main": "Gellérd Márton 1908-1933", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Simó Liza 1910-1975", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.89.lap/a.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.76", 
	"main": "Bencze Géza 1908-1968", 
	"child": "Bencze Jakab 1934-", 
	"village": "", 
	"hint": "lásd II.122/a.lap"}, 
{
	"page": "", 
	"main": "Gellérd Mária 1911-", 
	"child": "Bencze Mária 1935-", 
	"village": "Marosvásárhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mátyás 1938-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Éva 1939-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze László 1941-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Krisztina 1943-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Géza 1944-", 
	"village": "Marosvásárhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Izsák 1949-", 
	"village": "Borszék", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Pál 1953-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.77", 
	"main": "Csoma Lajos 1905-1996", 
	"child": "Csoma Emma 1933-1934", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Etelka 1910-1996", 
	"child": "Csoma Emma 1934-1952", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Sándor 1936-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Anna 1942-", 
	"village": "ifj.Zsombori Józsefné", 
	"hint": "lásd II.134/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Katalin 1948-1962", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma Emma 1954-", 
	"village": "Dálya", 
	"hint": "Sándor Mózesné"}, 
{
	"page": "II.78", 
	"main": "Bencze András 1908-1985", 
	"child": "Bencze Kálmán 1933-", 
	"village": "Nagybánya", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Bató Erzsébet 1910-1939", 
	"child": "Bencze Ella 1934-1934", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Dezső 1935-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ella 1939-", 
	"village": "Bencze Rezsőné", 
	"hint": "lásd II.155/a.lap"}, 
{
	"page": "2.neje", 
	"main": "Bató Irma 1921-", 
	"child": "Bencze József 1942-", 
	"village": "Balánbánya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "elváltak", 
	"child": "Bencze Apolonia 1947-", 
	"village": "Balánbánya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Benjámin 1949-", 
	"village": "Csíkszereda ", 
	"hint": ""}, 
{
	"page": "3.neje", 
	"main": "Albert Zsuzsa 1912-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Nagysolymosi", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.79", 
	"main": "Joó László 1905-1950", 
	"child": "Joó Ida 1933-", 
	"village": "Hargitabánya", 
	"hint": "Pál Dezsőné"}, 
{
	"page": "", 
	"main": "Barczán Berta 1911-", 
	"child": "Joó Erzsébet 1935-2009", 
	"village": "Brassó", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Mária 1939-", 
	"village": "Pál Albertné", 
	"hint": "lásd II.127/a.lap nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Lőrincz 1942-", 
	"village": "Székelyudvarhely", 
	"hint": "nincs utód"}, 
{
	"page": "II.80", 
	"main": "id.Nagy Károly 1908-1944", 
	"child": "Nagy Béla 1934-", 
	"village": "", 
	"hint": "lásd II.133/b.lap"}, 
{
	"page": "", 
	"main": "Joó Berta 1907-1984", 
	"child": "Nagy Ida 1936-1939", 
	"village": "", 
	"hint": "lásd II.156/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Géza 1938-1939", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Árpád 1940-", 
	"village": "Hargitabánya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Berta 1945-", 
	"village": "Csíkszereda ", 
	"hint": "Csedő Péterné"}, 
{
	"page": "II.81/a", 
	"main": "Csoma Pál 1907-1967", 
	"child": "Csoma Aranka 1936-1936", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Sándor Irén 1913-2013", 
	"child": "Csoma Árpád 1937-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Csoma József 1950-1981", 
	"village": "Homoródfürdő", 
	"hint": ""}, 
{
	"page": "II.81/b", 
	"main": "Sándor Miklós 1883-1956", 
	"child": "Sándor József 1909-", 
	"village": "Ége ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Csiszér Erzsébet 1890-1973", 
	"child": "Sándor Irén 1913-2013", 
	"village": "", 
	"hint": "lásd II.81/a.lap"}, 
{
	"page": "", 
	"main": "Égei", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.82", 
	"main": "Bencze Berta 1912-", 
	"child": "Bencze Elvira 1933-1933", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Sándor Ilonáé ", 
	"child": "Balázsi Józseftől", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.98.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.83", 
	"main": "id.Balázsi János 1905-1982", 
	"child": "Balázsi Otilia 1935-1941", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Berta 1911-1943", 
	"child": "Balázsi Gabriella 1937-", 
	"village": "Székelyszentlélek", 
	"hint": "Ozsváth Zsigmondné"}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Balázsi János 1939-", 
	"village": "", 
	"hint": "lásd II.141/b.lap"}, 
{
	"page": "II.84/a", 
	"main": "Tőzsér Lőrincz 1907-", 
	"child": "Tőzsér Vilma 1934-", 
	"village": "Pál Elekné", 
	"hint": "lásd II.148/b.lap"}, 
{
	"page": "", 
	"main": "A spanyol polgárháborúban mh.", 
	"child": "", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Sándor Róza 1905-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.84/b", 
	"main": "Tőzsér János 1872-1949", 
	"child": "Tőzsér Lőrincz 1907-", 
	"village": "", 
	"hint": "lásd II.84/a.lap"}, 
{
	"page": "", 
	"main": "Bencze Rebeka 1880-1977", 
	"child": "Tőzsér Károly 1910-", 
	"village": "", 
	"hint": "lásd II.144/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Tőzsér Ilona 1915-", 
	"village": "Székelyudvarhely", 
	"hint": "Felei Jenőné"}, 
{
	"page": "II.84/c", 
	"main": "Tőzsér Ilona 1915-", 
	"child": "Tőzsér Ilona 1934-", 
	"village": "Székelykeresztúr", 
	"hint": ""}, 
{
	"page": "II.85", 
	"main": "Bencze Aladár 1910-1990", 
	"child": "Bencze Ákos 1934-1934", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Liza 1912-1980", 
	"child": "Bencze Antal 1936-", 
	"village": "Székelyudvarhely", 
	"hint": "lásd II.153/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Aladár 1939-1939", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ottilia 1943-", 
	"village": "Nagy Albertné", 
	"hint": "lásd II.121/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mária 1946-", 
	"village": "ifj.Bató Áronné", 
	"hint": "lásd II.151/b.lap"}, 
{
	"page": "II.86", 
	"main": "Balázsi József 1910-1993", 
	"child": "ifj.Balázsi József 1935-", 
	"village": "Homoródszentmárton", 
	"hint": "lásd II.131/a.lap"}, 
{
	"page": "", 
	"main": "Sándor Anna 1910-1988", 
	"child": "Balázsi Erzsébet 1936-1937", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Juliánna 1938-", 
	"village": "Bencze Zsigmondné", 
	"hint": "lásd II.152/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Béla 1940-1940", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Emma 1946-1947", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.87", 
	"main": "Bara Balázsi Sándor 1911-1976", 
	"child": "Bara Jolán 1935-", 
	"village": "Zólya Zsigmondné", 
	"hint": "lásd II.154/b.lap"}, 
{
	"page": "", 
	"main": "Fodor Vilma 1915-2003", 
	"child": "ifj.Bara Sándor 1937-1993", 
	"village": "", 
	"hint": "lásd II.158/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bara Juliánna 1942-", 
	"village": "Balázsi Jánosné", 
	"hint": "lásd II.141/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bara Vilma 1944-1944", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.88", 
	"main": "Sándor Dénes 1907-1988", 
	"child": "Sándor Dezső 1934-", 
	"village": "Sepsiszentgyörgy", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó (Pál) Zsuzsa 1909-1986", 
	"child": "Sándor Borbála 1938-2021", 
	"village": "Székelykeresztúr", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Ilona 1941-2021", 
	"village": "Csíkszereda ", 
	"hint": "Bedő Kálmánné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Erzsébet 1945-", 
	"village": "Bencze Mózesné", 
	"hint": "lásd II.164/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Sándor Jolán 1947-", 
	"village": "Székelyudvarhely", 
	"hint": "Csáki Sándorné"}, 
{
	"page": "II.89/a", 
	"main": "Beke István 1908-1989", 
	"child": "Beke Erna 1935-", 
	"village": "Balássi Lajosné", 
	"hint": "lásd II.134/b.lap"}, 
{
	"page": "", 
	"main": "lásd I.137.lap", 
	"child": "", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Simó Liza 1910-1975", 
	"child": "Beke Viktor 1940-", 
	"village": "", 
	"hint": "lásd II.89/b.lap"}, 
{
	"page": "II.89/b", 
	"main": "Beke Viktor 1940-", 
	"child": "Beke Tünde 1967-", 
	"village": "Székelyudvarhely", 
	"hint": "Barabás Valérné"}, 
{
	"page": "", 
	"main": "Beke Ilona 1947-", 
	"child": "Beke Zsolt 1975-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.90", 
	"main": "Joó Albert 1907-1985", 
	"child": "Joó Géza 1933-", 
	"village": "", 
	"hint": "lásd II.146/b.lap"}, 
{
	"page": "", 
	"main": "Fancsali Irma 1908-1970", 
	"child": "Joó Tibor 1943-", 
	"village": "", 
	"hint": "lásd II.148/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Emma 1946-1947", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.91", 
	"main": "Bencze Béla 1910-1966", 
	"child": "Bencze József 1936-1937", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zólya Berta 1911-1978", 
	"child": "Bencze Ferencz 1937-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Béla 1940-", 
	"village": "Barót ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Emma 1942-", 
	"village": "Kolozsvár", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Elza 1950-", 
	"village": "Sepsiszentgyörgy", 
	"hint": "György Károlyné"}, 
{
	"page": "II.92/a", 
	"main": "id.Fodor Ákos 1909-1994", 
	"child": "Fodor Vilma 1937-", 
	"village": "ifj.Bencze Lajsné ", 
	"hint": "lásd II.153/a.lap"}, 
{
	"page": "", 
	"main": "Dáné Berta 1915-2001", 
	"child": "Fodor Ákos 1942-1973", 
	"village": "traktoros balesetben mh.", 
	"hint": "lásd II.92/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Berta 1944-", 
	"village": "Brassó", 
	"hint": "Gál Lajosné"}, 
{
	"page": "II.92/b", 
	"main": "ifj.Fodor Ákos 1942-1973", 
	"child": "Fodor Vilmos Elemér 1970-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Pál Elza 1949-", 
	"child": "Fodor Emil Ákos 1973-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.férje", 
	"main": "Bencze Ábel 1949-2011", 
	"child": "Bencze Zsolt", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.93", 
	"main": "id.Fodor Mihály 1912-", 
	"child": "Fodor Olga 1936-1944", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Ida 1918-", 
	"child": "Fodor Juliánna 1938-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Ida 1941-", 
	"village": "Székelyudvarhely", 
	"hint": "Csoma Sándorné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Mihály 1943-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Fodor Olga 1948-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.94/a", 
	"main": "id.Kelemen József 1909-1995", 
	"child": "Kelemen Gizella 1936-1936", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Juliánna 1915-1987", 
	"child": "Kelemen Zelma 1939-", 
	"village": "Pál Gáborné", 
	"hint": "lásd II.147/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Kelemen Ida 1940-2016", 
	"village": "Sorbán Mihályné ", 
	"hint": "lásd II.125/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Kelemen Béla 1942-", 
	"village": "Jásfalva ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Kelemen Ella 1945-", 
	"village": "", 
	"hint": "lásd II.94/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Kelemen József 1950-", 
	"village": "Olthévíz", 
	"hint": ""}, 
{
	"page": "II.94/b", 
	"main": "Kelemen Ella 1945-2004", 
	"child": "Kelemen Mónika Kinga 1989-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "nem volt férje ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.95", 
	"main": "Orbán István 1891-1949", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Sándor Lidia 1910-", 
	"child": "", 
	"village": "Csíkszépvíz aggmenházban", 
	"hint": "nincs utód"}, 
{
	"page": "II.96", 
	"main": "Bencze Albert 1913-2002", 
	"child": "Bencze Ferencz 1938-", 
	"village": "Dálya", 
	"hint": "ref.lelkipásztor"}, 
{
	"page": "", 
	"main": "Bencze Márta 1919-", 
	"child": "Bencze Márta 1942-", 
	"village": "Nagybacon", 
	"hint": "Pál Tiborné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Zelma 1946-1948", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Zelma 1950-", 
	"village": "Máréfalva ", 
	"hint": "Pál Zakariásné"}, 
{
	"page": "II.97", 
	"main": "Nagy Károly 1913-1985", 
	"child": "Nagy Albert 1937-1981", 
	"village": "", 
	"hint": "lásd II.121/b.lap"}, 
{
	"page": "", 
	"main": "Orbán Sarolta 1914-1996", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.98", 
	"main": "id.Orbán Elek 1913-2003", 
	"child": "Orbán Eliza 1938-", 
	"village": "Nagyszalonta ", 
	"hint": "Kubik Sándorné"}, 
{
	"page": "", 
	"main": "Bencze Berta 1912-1986", 
	"child": "Orbán Irma 1939-1939", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.5.lap", 
	"child": "ifj.Orbán Elek 1941-", 
	"village": "", 
	"hint": "lásd II.159/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Gyula 1942-1943", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Sándor 1949-2011", 
	"village": "Brassó", 
	"hint": "Bágyba temetve"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Ferencz 1954-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.99", 
	"main": "Bencze János 1910-1969", 
	"child": "Bencze Mária 1940-", 
	"village": "Kápolnás ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Juliánna 1920-", 
	"child": "Bencze Aladár 1941-", 
	"village": "", 
	"hint": "lád II.165/b.lap"}, 
{
	"page": "", 
	"main": "lásd II.141/a.lap", 
	"child": "Bencze Ézsaiás 1945-2018", 
	"village": "Székelyudvarhely-Bágy", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ilona 1947-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "II.100", 
	"main": "id.Balázsi Dénes 1903-", 
	"child": "Balázsi Irma 1925-", 
	"village": "Székelyudvarhely", 
	"hint": "Bencze(Kúti)Béláné"}, 
{
	"page": "", 
	"main": "háborúba elesett", 
	"child": "Balázsi Dénes 1938-", 
	"village": "", 
	"hint": "lásd II.126/b.lap"}, 
{
	"page": "", 
	"main": "Vas Juliánna 1905-1995", 
	"child": "Balázsi Dezső 1942-", 
	"village": "", 
	"hint": "lásd II.164/b.lap"}, 
{
	"page": "", 
	"main": "Lókodi", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.101", 
	"main": "id.Bencze Albert 1914-1979", 
	"child": "Bencze Aranka 1939-", 
	"village": "", 
	"hint": "lásd II.158/a.lap"}, 
{
	"page": "", 
	"main": "Lőrincz Dáné Jolán 1921-2014", 
	"child": "ifj.Bencze Albert 1941", 
	"village": "", 
	"hint": "lásd II.127/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Mózes 1945-2015", 
	"village": "", 
	"hint": "lásd II.164/a.lap"}, 
{
	"page": "II.102", 
	"main": "id.Nagy Márton 1909-1950", 
	"child": "Nagy Vilmos 1940-", 
	"village": "", 
	"hint": "lásd II.140/a.lap"}, 
{
	"page": "", 
	"main": "Dáné Biri 1920-", 
	"child": "Nagy Gizella Margit 1946-", 
	"village": "Csíkszereda ", 
	"hint": ""}, 
{
	"page": "II.103", 
	"main": "id.Joó Gyula 1910-1999", 
	"child": "Joó Dénes 1937-", 
	"village": "", 
	"hint": "lásd II.156/a.lap"}, 
{
	"page": "", 
	"main": "Bomhér Vilma 1908-1993", 
	"child": "Joó Margit 1939-1941", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Recsenyédi", 
	"child": "Joó Margit 1942-", 
	"village": "Lőrincz Ákosné", 
	"hint": "lásd II.155/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó József 1951-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.104", 
	"main": "Zólya Márton 1905-1982", 
	"child": "Zólya Margit 1934-", 
	"village": "Csíkszenttamás", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Ferencz Juliánna 1912-", 
	"child": "Zólya Jolán 1936-", 
	"village": "György Andrásné", 
	"hint": "lásd II.121/a.lap"}, 
{
	"page": "", 
	"main": "Csíkdánfalvi", 
	"child": "Zólya Pál 1938-1981", 
	"village": "Brassó", 
	"hint": "Bágyba temetve"}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Ilona 1941-", 
	"village": "Segesvár", 
	"hint": "Szatmári Csabáné"}, 
{
	"page": "II.105", 
	"main": "Bencze M.Dénes 1910-1983", 
	"child": "Bencze Etelka 1941-", 
	"village": "Homoródszentmárton", 
	"hint": "Bencze Ödönné"}, 
{
	"page": "", 
	"main": "lásd II.5.lap", 
	"child": "Bencze Magda 1942-", 
	"village": "Balázsi Dénesné", 
	"hint": "lásd II.126/b.lap"}, 
{
	"page": "", 
	"main": "Joó Irma 1920-1998", 
	"child": "Bencze Dénes 1944-1945", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.29.lap", 
	"child": "Bencze Dénes 1947-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Ábel 1949-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Irma 1954-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.106", 
	"main": "Balázsi József(alsó)1915-1989", 
	"child": "Balázsi Juliánna 1940-1941", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.14.lap", 
	"child": "Balázsi Juliánna 1942-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Margit 1921-2000", 
	"child": "Balázsi József 1944-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Béla 1946-2001", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Margit 1948-", 
	"village": "Brassó", 
	"hint": "Bencze Attiláné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Pál 1950-", 
	"village": "Olthévíz", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Árpád 1952-", 
	"village": "Olthévíz", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Ferencz 1956-", 
	"village": "Olthévíz", 
	"hint": ""}, 
{
	"page": "II.107", 
	"main": "id.Bencze Dezső 1913-1995", 
	"child": "Bencze József 1940-", 
	"village": "", 
	"hint": "lásd II.125/a.lap"}, 
{
	"page": "", 
	"main": "Balázsi Vilma 1919-1995", 
	"child": "ifj.Bencze Dezső 1952-", 
	"village": "", 
	"hint": "lásd II.167/b.lap"}, 
{
	"page": "II.108", 
	"main": "Fehér Jenő 1910-1941", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Tőzsér Ilona 1915-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.109", 
	"main": "Nagy Ferencz 1912-1966", 
	"child": "Nagy Lajos 1940-", 
	"village": "Homoródszentpál", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zólya Róza 1921-", 
	"child": "Nagy Zsigmond 1942-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Ferencz 1943-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Mária 1946-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Vernika 1950-", 
	"village": "Homoródszentpál", 
	"hint": "Béla Domokosné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Benjámin 1952-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.110", 
	"main": "id.Barczán Lajos 1910-1984", 
	"child": "Barczán Lidia 1940-1940", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Orbán Juliánna 1921-2005", 
	"child": "Barczán Irma 1942-", 
	"village": "ifj.Bencze Albertné", 
	"hint": "lásd II.127/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Emma 1943-1943", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Barczán Lajos 1944-2021", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.111", 
	"main": "id.Joó Imre 1906-1998", 
	"child": "Joó Erzsébet 1929-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bán Rebeka 1907-1992", 
	"child": "Joó Ilona 1938-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Székelymuzsnai", 
	"child": "Joó Gábor 1942-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Joó Imre 1943-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Magdolna 1949-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Viktor 1951-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Elvira 1954-", 
	"village": "Köpec", 
	"hint": "Benedek Ernőné"}, 
{
	"page": "II.112", 
	"main": "Joó Gábor 1913-1941", 
	"child": "Joó Dezső 1941-", 
	"village": "Székelykeresztúr", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zólya Ilka 1919-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.113/a", 
	"main": "id.Dáné Dezső 1912-1992", 
	"child": "Dáné Ilka 1941-", 
	"village": "Dáné Zsigmondné", 
	"hint": "lásd II.160/a.lap"}, 
{
	"page": "", 
	"main": "Bencze Matild 1919-2009", 
	"child": "Dáné Róza 1944-2016", 
	"village": "Beke Zsigmondné", 
	"hint": "lásd II.149/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Sándor 1954-2003", 
	"village": "", 
	"hint": "lásd II.113/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Dáné Dezső 1956-2001", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.113/b", 
	"main": "Dáné Sándor1954-2003", 
	"child": "Dáné Csilla 1989-", 
	"village": "Bencze Magorné", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bak Júlia 1966-2012", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.114", 
	"main": "id.Bató Áron 1913-1991", 
	"child": "Bató Erzsébet 1944-1945", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Berta 1916-1999", 
	"child": "ifj.Bató Áron 1945-", 
	"village": "Székelyudvarhely", 
	"hint": "lásd II.151/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Albert 1948-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Elvira 1950-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Dezső 1952-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Lajos 1954-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Tilda 1956-", 
	"village": "Székelyudvarhely", 
	"hint": "nincs utód"}, 
{
	"page": "II.115", 
	"main": "Pál Ferencz 1917-", 
	"child": "Pál Ferencz 1942-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "háborúba elesett", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Eszter 1921-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.116", 
	"main": "ifj.Bencze Dénes 1916-1991", 
	"child": "Bencze Rózália 1942-1942", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Irma 1922-2010", 
	"child": "Bencze Imre 1943-1944", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Sándor 1944-2010", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Irma 1947-", 
	"village": "Balázsi Dezsőné", 
	"hint": "lásd II.164/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "id.Bencze H. Zoltán 1950-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Dénes 1954-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.117", 
	"main": "ifj.Fodor Lajos 1906-1951", 
	"child": "Fodor Lenke 1942-", 
	"village": "Zólya Jenőné ", 
	"hint": "lásd II.161/a.lap"}, 
{
	"page": "", 
	"main": "lásd I.168.lap", 
	"child": "Fodor Lujza 1945-", 
	"village": "Joó Tiborné ", 
	"hint": "lásd II.148/a.lap"}, 
{
	"page": "", 
	"main": "Bencze Eszter 1921-2009", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "elkülönöztek", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.118", 
	"main": "Joó Zsigmond (alsó)1900-1976", 
	"child": "Joó Katalin 1943-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Lőrincz Irma 1922-", 
	"child": "Joó Erzsébet Berta 1946-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Irma 1948- ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Regina 1950-", 
	"village": "Bencze Józsefné(Csillag)", 
	"hint": "lásd II:169/a.lap "}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Elza 1953-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Irén 1936-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Attila Ferencz 1956-", 
	"village": "", 
	"hint": "lásd II.177/b.lap"}, 
{
	"page": "II.119", 
	"main": "Zsombori Zsigmond 1900-", 
	"child": "", 
	"village": "Budapesten halt meg", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Baczó Juliánna 1906-", 
	"child": "", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "II.120", 
	"main": "Gellérd József 1913-", 
	"child": "Gellérd Ágnes 1943-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Sándor Juliánna 1914-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.121/a", 
	"main": "György András 1925-2007", 
	"child": "ifj.György András 1956-", 
	"village": "Kézdivásárhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Lövétei", 
	"child": "György Zoltán 1962-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zólya Jolán 1936-", 
	"child": "György Ervin 1970-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.104.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.121/b", 
	"main": "Nagy Albert1937-1981", 
	"child": "Nagy Gizella 1964-", 
	"village": "Székelyudvarhely", 
	"hint": "Menyhárt Lászlóné"}, 
{
	"page": "", 
	"main": "Bencze Ottilia 1943-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.férje ", 
	"main": "Szász Miklós 1949-2022", 
	"child": "Szász ottilia 1985-", 
	"village": "Bencze Zoltánné", 
	"hint": "lásd II.175/b.lap"}, 
{
	"page": "II.122/a", 
	"main": "id.Bencze Jakab 1934-2004", 
	"child": "ifj. Bencze Jakab 1958-", 
	"village": "", 
	"hint": "lásd II.184/c.lap"}, 
{
	"page": "", 
	"main": "Bencze Irma 1936-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.122/b", 
	"main": "Zólya István 1911-1995", 
	"child": "Zólya Emil 1947-1989", 
	"village": "Brassó", 
	"hint": "felakasztotta magát"}, 
{
	"page": "", 
	"main": "Bencze Eszter 1923-", 
	"child": "Zólya Csaba 1950-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "II.123", 
	"main": "Nagy(Bencze)János 1930-1995", 
	"child": "Nagy Magda 1958-", 
	"village": "Máréfalva ", 
	"hint": "Olasz Béláné"}, 
{
	"page": "", 
	"main": "lásd II.27.lap", 
	"child": "Nagy Irén 1962-", 
	"village": "Székelyudvarhely", 
	"hint": "Török Bálintné"}, 
{
	"page": "", 
	"main": "Péter Mária 1938-2002", 
	"child": "Nagy Miklós 1974-1997", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Ülkei", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.123/b", 
	"main": "id.Barczán Balázs 1929-1982", 
	"child": "Barczán Margit 1957-", 
	"village": "Bencze Aladárné", 
	"hint": "lásd II.165/b.lap"}, 
{
	"page": "", 
	"main": "Benedek Katalin 1940-2009", 
	"child": "Barczán László 1964-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Barczán Balázs 1967-", 
	"village": "", 
	"hint": "lásd II.179/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Barczán Gyula 1973-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.124/a", 
	"main": "Baczó Mózes 1913-2002", 
	"child": "Baczó Edit 1946-", 
	"village": "Lakatos Józsefné", 
	"hint": "lásd II.124/c.lap"}, 
{
	"page": "", 
	"main": "Molnár Vilma 1921-2014", 
	"child": "Baczó Mózes István 1948-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Gábor 1953-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.124/b", 
	"main": "Baczó Áron 1898-1983", 
	"child": "Baczó István 1926-1978", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Kandó Margit 1901-1978", 
	"child": "Baczó Ludmilla 1928- ", 
	"village": "Csíkszépvíz ", 
	"hint": "Csáki Károlyné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Margit 1932-", 
	"village": "Homoródalmás", 
	"hint": "Oláh Béláné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Baczó Sándor 1933-", 
	"village": "Szentegyháza ", 
	"hint": ""}, 
{
	"page": "II.124/c", 
	"main": "Lakatos József ", 
	"child": "ifj.Baczó Edit 1968-", 
	"village": "Csíkszereda ", 
	"hint": "Kövendi Leventéné"}, 
{
	"page": "", 
	"main": "Baczó Edit 1946-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.125/a ", 
	"main": "Bencze József 1940-2017", 
	"child": "Bencze Jolán 1963-", 
	"village": "Budapest", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Bencze Emma 1941-", 
	"child": "Bencze Sándor 1968-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.125/b", 
	"main": "Sorbán Mihály 1929-1979", 
	"child": "Sorbán Mihály Vencel 1962-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Kelemen Ida 1940-2016", 
	"child": "Sorbán Zoltán Csaba 1963-", 
	"village": "Csíkszereda ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Sorbán Árpád 1969-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.126/a", 
	"main": "id. Zsombori Lajos 1928-2012", 
	"child": "ifj. Zsombori Lajos 1953-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Elza 1931-2004", 
	"child": "Zsombori Irma 1956-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Emese-Ida 1969-", 
	"village": "Orbán Dénesné", 
	"hint": "lásd II.170/b.lap"}, 
{
	"page": "II.126/b", 
	"main": "Balázsi Dénes 1938-", 
	"child": "Balázsi Aladár 1963-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Magda 1942-", 
	"child": "Balázsi Zoltán 1967-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Angela 1975-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.127/a ", 
	"main": "Pál Albert 1936-2001", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "lásd II.2.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Mária 1939-2001", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.127/b ", 
	"main": "Ifj.Bencze Albert 1941- ", 
	"child": "Bencze Levente 1967-", 
	"village": "", 
	"hint": "lásd II.174/b.lap"}, 
{
	"page": "", 
	"main": "Barczán Irma 1942-2018", 
	"child": "Bencze Piroska 1972-", 
	"village": "Homoródszentmárton", 
	"hint": "JakabLeventéné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze András 1973-", 
	"village": "", 
	"hint": "lásd II.174/c.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "legifj.Bencze Albert 1976-", 
	"village": "Magyarország", 
	"hint": ""}, 
{
	"page": "II.128/a", 
	"main": "Joó Jenő 1909-1978", 
	"child": "Joó Etelka 1948-", 
	"village": "Sepsiszentgyörgy", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Róza 1923-1995", 
	"child": "Joó Ilona 1950-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Dénes 1951-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Ida 1955-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.128/b", 
	"main": "Zólya Bálint 1925-1987", 
	"child": "Zólya Mózes 1957-2003", 
	"village": "öngyilkosság ", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Bencze Póli 1926-2009", 
	"child": "Zólya Gizella 1961-", 
	"village": "Bibarcfalva", 
	"hint": "Szabó Attiláné"}, 
{
	"page": "II.129/a", 
	"main": "id.Fodor Gábor 1909-1972", 
	"child": "Fodor Erzsébet 1934-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gáll Vilma Mária 1910-1977", 
	"child": "Fodor Vilma 1941-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Fodor Gábor 1943-1983", 
	"village": "felakasztotta magát", 
	"hint": "nincs utód"}, 
{
	"page": "II.129/b", 
	"main": "Barczán József 1933-", 
	"child": "Barczán Attila 1960-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Emma 1942-", 
	"child": "Barczán Edith 1965-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.130/a", 
	"main": "Barczán Pál 1911-1990", 
	"child": "Barczán József 1933-", 
	"village": "Székelyudvarhely", 
	"hint": "lásd II.129/b.lap"}, 
{
	"page": "", 
	"main": "Gegő Marcella 1910-2000", 
	"child": "Barczán Magda 1947-", 
	"village": "Bencze Ákosné", 
	"hint": "lásd II.133/a.lap"}, 
{
	"page": "II.130/b", 
	"main": "Zólya János 1909-2007", 
	"child": "Zólya Emma 1952-", 
	"village": "Székelyudvarhely", 
	"hint": "Péter Jánosné"}, 
{
	"page": "", 
	"main": "Dáné Irma 1923-2006", 
	"child": "Zólya Ádám 1953-", 
	"village": "Sepsiszentgyörgy", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Győző 1957-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "II.131/a", 
	"main": "ifj.Balássi József 1935-", 
	"child": "Balássi Lajos 1960-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Margit 1941-", 
	"child": "Balássi Zsigmond 1961-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balássi Vilma Emese 1968-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balássi Berta Emilia 1975-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "Ii.131/b", 
	"main": "ifj.Balássi Miklós 1909-1987", 
	"child": "Balássi Sándor 1954-", 
	"village": "Felsőboldogfalva", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd I.186.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Egyed Vilma 1922-1959", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Homoródújfalusi", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.132/a", 
	"main": "id.Balássi Áron 1911-1976", 
	"child": "ifj.Balássi Áron 1935-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Orbán Juliánna 1908-1961", 
	"child": "Balássi Vilmos 1944-", 
	"village": "Szentegyháza ", 
	"hint": "lásd II.160/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balássi Imre 1946-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Bencze Juliánna 1911-1996", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.132/b", 
	"main": "Dáné Aladár 1933-", 
	"child": "Dáné Ilona 1960-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Erzsébet 1934-2014", 
	"child": "Dáné Szilárd 1966-", 
	"village": "Felsőboldogfalva", 
	"hint": ""}, 
{
	"page": "II.133/a", 
	"main": "Bencze Ákos 1937-", 
	"child": "Bencze Hajnalka 1964-", 
	"village": "Magyarország", 
	"hint": "Potyond"}, 
{
	"page": "", 
	"main": "Barczán Magda 1947-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.133/b", 
	"main": "Nagy Béla 1934-2015", 
	"child": "Péter Irénke 1961-", 
	"village": "Zetelaka", 
	"hint": "az anyáé"}, 
{
	"page": "", 
	"main": "Péter Marcella 1944-2019", 
	"child": "Nagy Ildikó 1965-", 
	"village": "Lupény", 
	"hint": "Miklós Imréné"}, 
{
	"page": "", 
	"main": "Ülkei", 
	"child": "Nagy Edit-Éva 1967", 
	"village": "Székelyudvarhely", 
	"hint": "Szász Ferenczné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Nagy Károly 1969-", 
	"village": "", 
	"hint": "lásd II.176/a.lap"}, 
{
	"page": "II.134/a", 
	"main": "ifj.Zsombori József 1939-2002", 
	"child": "Zsombori Dénes 1963-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Csoma Anna 1942-2013", 
	"child": "Zsombori Melinda 1968-", 
	"village": "Kénos ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Erzsébet 1970-", 
	"village": "Dálya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Katalin-Szidónia 1972-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.134/b", 
	"main": "Balássi Lajos 1931-", 
	"child": "Balássi Erzsébet 1956-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Beke Erna 1935-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.135/a ", 
	"main": "id.Bencze Béla 1925-2007", 
	"child": "ifj.Bencze Béla 1951-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zsombori Irma 1927-2016", 
	"child": "Bencze Adél-Irma 1959-", 
	"village": "Homoródszentmárton", 
	"hint": ""}, 
{
	"page": "II.135/b", 
	"main": "Bencze István I.1890-1958", 
	"child": "Bencze Margit 1921-", 
	"village": "Balázsi Józsefné", 
	"hint": "lásd II.106.lap"}, 
{
	"page": "", 
	"main": "Deák Ilona 1900-1976", 
	"child": "Bencze Eszter 1926-", 
	"village": "Zólya Istvánné", 
	"hint": "lásd II.122/b.lap"}, 
{
	"page": "", 
	"main": "Keményfalvi", 
	"child": "Bencze Béla 1925-", 
	"village": "", 
	"hint": "lásd II.135/a.lap "}, 
{
	"page": "II.135/c", 
	"main": "Joó Aladár 1928-", 
	"child": "Joó Lajos 1959-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Szabó Berta 1935-", 
	"child": "Joó Ibolya 1966-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.136/a", 
	"main": "Bencze Pál 1922-2002", 
	"child": "Bencze Elek 1949-1950", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Balázsi Magda 1928-1959", 
	"child": "Bencze Dénes 1951-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Jolán 1953-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Olga 1954-", 
	"village": "Jásfalva ", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Joó Emma 1925-2006", 
	"child": "Joó Teréz 1950-", 
	"village": "Székelyudvarhely", 
	"hint": "Drágos Lászlóné"}, 
{
	"page": "II.136/b", 
	"main": "ifj.Joó Zsigmond 1928-2000", 
	"child": "Joó László 1963-2011", 
	"village": "Székelyudvarhely", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Péter Regina 1942-1994", 
	"child": "Joó Levente-Árpád 1965-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Zoltán 1969-", 
	"village": "", 
	"hint": "lásd II.136/c.lap "}, 
{
	"page": "II.136/c", 
	"main": "Joó Zoltán 1969-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "György Edit 1967-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.137/a", 
	"main": "Bencze Imre 1924-2005", 
	"child": "Bencze Ibolya 1950-1950", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.4.lap", 
	"child": "Bencze Zsigmond 1952-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Beke Vilma 1926-2002", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.137/b", 
	"main": "id.Kelemen Sándor 1921-1990", 
	"child": "Kelemen Magda 1949-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Gergely Erzsébet 1926-2014", 
	"child": "Kelemen Erzsébet 1951-", 
	"village": "Székelyudvarhely", 
	"hint": "Orbán Gyuláné"}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Kelemen Sándor 1958-2022", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.138/a", 
	"main": "Bencze Gyula IV. 1919-2001", 
	"child": "Bencze Zakariás 1948-2021", 
	"village": "Székelyudvarhely", 
	"hint": "lásd II.166/a.lap"}, 
{
	"page": "", 
	"main": "Joó Juliánna 1925-2001", 
	"child": "Bencze Gyula 1949-1968", 
	"village": "", 
	"hint": "balesetben hunyt el"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Gábor 1951-", 
	"village": "Székelyudvarhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Magdolna 1952-", 
	"village": "Kovács Béláné ", 
	"hint": "elváltak"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Vilma ", 
	"village": "Jakabfi istvánné", 
	"hint": "lásd Ii.178/a.lap"}, 
{
	"page": "II.138/b", 
	"main": "ifj.Dáné Károly 1930-1993", 
	"child": "Dáné Márta 1950-", 
	"village": "Székelyudvarhely", 
	"hint": "Járai Szabó Ferenczné"}, 
{
	"page": "", 
	"main": "Joó Juliánna 1930-2000", 
	"child": "Dáné Antal 1954-1985", 
	"village": "", 
	"hint": "felakasztotta magát"}, 
{
	"page": "II.139/a", 
	"main": "Dáné Lajos 1927-2018", 
	"child": "Dáné Elvira 1955-", 
	"village": "Székelyudvarhely", 
	"hint": "Józsi Csabáné "}, 
{
	"page": "", 
	"main": "Joó Ella 1929-2011", 
	"child": "Dáné Zita 1959-", 
	"village": "Brüsszel", 
	"hint": ""}, 
{
	"page": "II.139/b", 
	"main": "id.Dáné Ferenc 1933-2013", 
	"child": "Dáné Emma 1961-2004", 
	"village": "Bencze Dezsőné", 
	"hint": "lásd II.167/b.lap"}, 
{
	"page": "", 
	"main": "lásd II.72.lap", 
	"child": "Dáné Dénes 1963-2017", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Hodgyai Irma 1940-", 
	"child": "Dáné Irma 1970-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Mihály 1974-2021", 
	"village": "", 
	"hint": "felakasztotta magát"}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Dáné Ferenc 1981", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Mária Erzsébet 1981", 
	"village": "Szentegyháza ", 
	"hint": "ikrek"}, 
{
	"page": "II.140/a", 
	"main": "Nagy Vilmos 1940-2018", 
	"child": "Nagy Hajnalka 1967-", 
	"village": "Magyarország", 
	"hint": "Lendvai Csabáné"}, 
{
	"page": "", 
	"main": "Bencze Márta 1941-", 
	"child": "Nagy Márton 1969-", 
	"village": "elváltak", 
	"hint": "lásd II.177/a.lap"}, 
{
	"page": "II.140/b", 
	"main": "id.Orbán Sándor 1923-2011", 
	"child": "Orbán Gyula 1948-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Etelka 1926-2013", 
	"child": "Orbán Dezső 1950-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Etelka 1952-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Orbán Sándor 1958-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "II.141/a", 
	"main": "ifj.Beke Lajos 1924-1986", 
	"child": "Beke Ilona 1947-", 
	"village": "Beke Viktorné ", 
	"hint": "lásd II.89/b.lap"}, 
{
	"page": "1.neje ", 
	"main": "Bencze Ida 1923-1970", 
	"child": "Beke Gyula 1949-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Beke Lajos 1952-1957", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje", 
	"main": "Bencze Juliánna 1920-", 
	"child": "", 
	"village": "", 
	"hint": "lásd II.99.lap"}, 
{
	"page": "II.141/b", 
	"main": "ifj.Balázsi János 1939-2009", 
	"child": "Balázsi Berta 1964-2016", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Bara Juliánna 1942-", 
	"child": "Balázsi Edit 1967-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Ildikó 1973-", 
	"village": "Német Leventéné", 
	"hint": "lásd II.181/a.lap"}, 
{
	"page": "II.142", 
	"main": "Zsombori János 1923-2011", 
	"child": "Zsombori Béla 1946-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Beke Berta 1923-2007", 
	"child": "Zsombori Zoltán 1947-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Elza 1952-", 
	"village": "Székelyudvarhely ", 
	"hint": "nincs utód"}, 
{
	"page": "II.143/a", 
	"main": "Bencze István 1933-", 
	"child": "Bencze Emil 1958-", 
	"village": "Dálya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balássi Vilma 1937-", 
	"child": "Bencze István 1959-1959", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze József 1962-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.143/b", 
	"main": "Bató Géza 1917-1999", 
	"child": "Bató Ilona Lenke 1946-2012", 
	"village": "", 
	"hint": "szellemileg fogyatékos "}, 
{
	"page": "", 
	"main": "Bencze Juliánna 1921-1999", 
	"child": "Bató Zoltán 1948-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Jolán 1951-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Géza 1955-1969", 
	"village": "", 
	"hint": "szellemileg fogyatékos "}, 
{
	"page": "", 
	"main": "", 
	"child": "Bató Mária Magdolna 1959-", 
	"village": "Farcád", 
	"hint": "Dobai Endréné"}, 
{
	"page": "II.144/a", 
	"main": "Bencze Gábor 1917-1985", 
	"child": "Bencze Berta 1948-1948", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Bató Juliánna 1922-1986", 
	"child": "", 
	"village": "elváltak", 
	"hint": "lásd II.144/b.lap"}, 
{
	"page": "II.144/b", 
	"main": "Tőzsér Károly 1910-1994", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Bató Juliánna 1922-1986", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.145/a", 
	"main": "Balássi Ferencz 1925-2012", 
	"child": "Balássi Irma 1954-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zsombori Eszter 1935-2002", 
	"child": "Balássi Katalin 1957-", 
	"village": "Székelyudvarhely ", 
	"hint": "Deák Mártonné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balássi Jenő 1958-2022", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.145/b", 
	"main": "Bencze Berta 1916-1999", 
	"child": "Bencze László 1941-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Menyecske Berta ", 
	"child": "Bencze Lujza 1946-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "II.145/c", 
	"main": "Dobai Ernő 1955-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bató Mária Magdolna 1958-", 
	"child": "", 
	"village": "", 
	"hint": "lásd II.143/b.lap"}, 
{
	"page": "II.146/a", 
	"main": "Zsombori Sándor 1921-2004", 
	"child": "Zsombori Sarolta 1950-", 
	"village": "Székelyudvarhely ", 
	"hint": "Györgydeák Ágostonné"}, 
{
	"page": "", 
	"main": "Beke Mária 1929-1984", 
	"child": "Zsombori László 1952-1968", 
	"village": "", 
	"hint": "öngyilkosság "}, 
{
	"page": "", 
	"main": "", 
	"child": "Zsombori Pál 1959-2015", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.146/b", 
	"main": "Joó Géza 1933-", 
	"child": "Joó Ferencz 1957-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Ida 1932-2012", 
	"child": "Joó Szerénke 1960-", 
	"village": "", 
	"hint": "lásd II.174/a.lap"}, 
{
	"page": "II.147/a", 
	"main": "Páll Gábor 1933-", 
	"child": "Páll András 1959-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Kelemen Zelma 1939-", 
	"child": "Páll Teréz 1963-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Páll Zoltán 1967-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Páll Gyöngyike 1968-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "II.148/a", 
	"main": "Joó Tibor 1943-", 
	"child": "Joó Márta 1966-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Fodor Lujza 1945-", 
	"child": "Joó Irén 1970-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "?", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.148/b", 
	"main": "Páll Elek 1927-", 
	"child": "Páll Emma 1956-2021", 
	"village": "Marosvásárhely", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.2.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Tőzsér Vilma 1934-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.148/c ", 
	"main": "Zólya(Lukács)Dénes 1925-", 
	"child": "Zólya Benjámin 1950-", 
	"village": "Barót ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Emma 1928-", 
	"child": "Zólya Viola 1960-", 
	"village": "Barót ", 
	"hint": ""}, 
{
	"page": "II.149/a", 
	"main": "Beke Zsigmond 1940-1972", 
	"child": "Beke Dénes 1965-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Róza 1944-2016", 
	"child": "Beke Ibolya 1967", 
	"village": "Magyarország", 
	"hint": ""}, 
{
	"page": "II.149/b", 
	"main": "Páll Imre 1935-2008", 
	"child": "Páll Csaba 1960-", 
	"village": "Gyergyó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Magda 1940-", 
	"child": "Páll Lajos 1968-", 
	"village": "", 
	"hint": "lásd II.169/b.lap"}, 
{
	"page": "II.1560/a ", 
	"main": "Dáné Albert 1929-1998", 
	"child": "Dáné Gizella 1951-", 
	"village": "Bencze Zakariásné", 
	"hint": "lásd II.166/a.lap"}, 
{
	"page": "", 
	"main": "Páll Irma 1931-2015", 
	"child": "Dáné Attila 1957-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "II.150/b", 
	"main": "Joó Béla 1925-", 
	"child": "Joó Erzsébet 1951-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balássi Juliánna 1929-", 
	"child": "Joó Elvira 1954-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Aranka 1956-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "II.151/a", 
	"main": "id.Joó Sándor 1941-2008", 
	"child": "ifj.Joó Sándor 1964-", 
	"village": "Patakfalva ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Vilma ( Vera)1941-2013", 
	"child": "Joó Emese 1969-", 
	"village": "Árvátfalva ", 
	"hint": "Csiszár Gáborné "}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Dénes 1981-", 
	"village": "", 
	"hint": "lásd II.184/a.lap"}, 
{
	"page": "II.151/b", 
	"main": "ifj. Bató Áron 1945", 
	"child": "Bató Ildikó1966-", 
	"village": "Ausztria ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Mária 1946-", 
	"child": "Bató Éva 1969-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.152/a ", 
	"main": "Joó Gyula 1935-2020", 
	"child": "Joó Zoltán 1963-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Szabó Jolán 1935-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Etédi", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.152/b", 
	"main": "Bencze Zsigmond 1934-2012", 
	"child": "Bencze Ibolya 1959-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balássi Juliánna 1938-2017", 
	"child": "Bencze Katalin 1967-", 
	"village": "Székelyudvarhely ", 
	"hint": "Demeter Dénesné "}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Csilla 1971-", 
	"village": "Homoródszentpál", 
	"hint": "Kovács Elemérné"}, 
{
	"page": "II.153/a", 
	"main": "ifj.Bencze Lajos 1937-", 
	"child": "Bencze Margit 1961-", 
	"village": "Magyarország", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Fodor Vilma 1937-", 
	"child": "Bencze Emilia 1967-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "II.153/b", 
	"main": "Bencze Antal 1936-", 
	"child": "Bencze Tivadar 1960-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Kelemen Emma 1938-", 
	"child": "Bencze Lajos 1961", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Judit 1971-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "II.154/a", 
	"main": "Simó Zakariás 1934-", 
	"child": "Simó Dénes 1955-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Irén 1936-", 
	"child": "Simó Zsolt 1970-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "II.154/b ", 
	"main": "ifj.Zólya Zsigmond 1930-", 
	"child": "Zólya Kálmán 1957-", 
	"village": "Székelyudvarhely ", 
	"hint": "lásd II.154/c.lap"}, 
{
	"page": "", 
	"main": "Bara Jolán 1935-2016", 
	"child": "Zólya Attila Zsigmond 1959-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Zólya Mária 1960-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "II.154/c", 
	"main": "Zólya Kálmán 1957-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Tófalvi Mária 1959-2022", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.155/a", 
	"main": "id.Bencze Rezső 1929-2000", 
	"child": "ifj.Bencze Rezső 1957-", 
	"village": "Hollandia", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Ella 1939-", 
	"child": "Bencze Erzsébet 1960-", 
	"village": "Magyarország", 
	"hint": "Simonyi Csabáné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Zsigmond 1964-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.155/b", 
	"main": "Lőrincz Ákos 1934-2004", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Joó Margit 1942-", 
	"child": "", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.156/a", 
	"main": "Joó Gy.Dénes 1937-", 
	"child": "legifj.Joó Gyula 1964-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zólya Ottilia 1942-", 
	"child": "Joó Csaba 1973-", 
	"village": "", 
	"hint": "lásd II.180/b.lap"}, 
{
	"page": "II.156/b ", 
	"main": "Joó Á.Dénes 1925-2007", 
	"child": "Joó József 1959-", 
	"village": "Csíkszereda ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.3.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Nagy Ida 1936-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.157/a", 
	"main": "Bató Gábor 1917-1974", 
	"child": "Bató András 1948-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Csoma Cecilia 1929-2002", 
	"child": "Bató Erzsébet 1951-", 
	"village": "Magyarország", 
	"hint": "Fazakas Józsefné "}, 
{
	"page": "II.157/b", 
	"main": "Balássi Rezső 1920-1996", 
	"child": "Balássi Ildikó 1946-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Irma 1923-1996", 
	"child": "Balássi Dénes 1948-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balássi Csaba 1954-2003", 
	"village": "", 
	"hint": "lásd II.181/b.lap"}, 
{
	"page": "II.158/a", 
	"main": "ifj.Bara Sándor 1937-1993", 
	"child": "Bara Lajos 1964-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Aranka 1939-", 
	"child": "Bara Árpád 1968-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bara Attila 1970-", 
	"village": "", 
	"hint": "lásd II.180/a.lap"}, 
{
	"page": "II.158/b", 
	"main": "Balássi András 1925-1998", 
	"child": "Balássi Gyula 1952-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Anna 1925-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.159/a", 
	"main": "Bencze Endre 1906-1969", 
	"child": "Bencze Mihály 1932-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Kányádi Irma 1911-1981", 
	"child": "Bencze Erzsébet 1934-", 
	"village": "Dáné Aladárné", 
	"hint": "lásd II.132/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Irén 1936-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Endre 1941-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Árpád 1943-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.159/b", 
	"main": "ifj. Orbán Elek 1941-", 
	"child": "Orbán Elza 1968-", 
	"village": "Városfalva ", 
	"hint": "Gedő Józsefné "}, 
{
	"page": "", 
	"main": "Szabó Mária 1944-", 
	"child": "Orbán Juliánna 1969-", 
	"village": "Székelyudvarhely ", 
	"hint": "Orbán Gáborné "}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Vilmos Dénes 1971-", 
	"village": "", 
	"hint": "lásd II.170/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Kálmán 1980-", 
	"village": "", 
	"hint": "lásd II.182/b.lap"}, 
{
	"page": "II.160/a", 
	"main": "ifj.Dáné Zsigmond 1935-2011", 
	"child": "Dáné Elza 1960-", 
	"village": "Kecset", 
	"hint": "Elekes Béláné "}, 
{
	"page": "", 
	"main": "Dáné Ilka 1941-", 
	"child": "Dáné Zsigmond 1961-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Matild 1963-", 
	"village": "Székelyudvarhely ", 
	"hint": "Szabó Imréné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Emese 1967-", 
	"village": "Magyarország", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné József 1971-", 
	"village": "Magyarország", 
	"hint": ""}, 
{
	"page": "II.160/b", 
	"main": "Balássi Vilmos 1944-", 
	"child": "Balássi Levente 1963-", 
	"village": "Szentegyháza ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zólya Irén 1943-", 
	"child": "Balássi Emese ", 
	"village": "Szentegyháza ", 
	"hint": ""}, 
{
	"page": "II.161/a", 
	"main": "Zólya Jenő 1939-", 
	"child": "Zólya Dénes Lajos 1961-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Fodor Lenke 1942-", 
	"child": "Zólya Vencel ", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "II.161/b", 
	"main": "id. Páll Sándor 1923-1980", 
	"child": "Páll Elza 1948-1948", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Páll Eltelka 1928-1996", 
	"child": "Páll Elza 1949-", 
	"village": "Székelyudvarhely ", 
	"hint": "lásd II.92/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Páll Sándor Aladár 1959-", 
	"village": "Székelyudvarhely ", 
	"hint": "nincs utód"}, 
{
	"page": "II.162/a", 
	"main": "Bencze Gyula III.1912-1977", 
	"child": "Bencze Magdolna 1940-", 
	"village": "Pál Imréné", 
	"hint": "lásd II.149/b.lap"}, 
{
	"page": "", 
	"main": "Vajda Róza 1919-", 
	"child": "Bencze Erzsébet 1942-", 
	"village": "Brassó", 
	"hint": ""}, 
{
	"page": "", 
	"main": "egyházilag nem esküdtek,", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "elváltak 51-ben", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.162/b", 
	"main": "Nagy Zsigmond 1925-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Regina 1926-1986", 
	"child": "Balázsi Béla 1952-", 
	"village": "Székelyudvarhely ", 
	"hint": "szerelem gyerek"}, 
{
	"page": "II.163/a", 
	"main": "Bencze Pál 1922-2002", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Emma 1924-2006", 
	"child": "Joó Teréz 1950-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.163/b", 
	"main": "id.Joó Árpád 1929-1989", 
	"child": "ifj.Joó Árpád 1970-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Menyhárt Berta 1941-", 
	"child": "Joó Elza 1971-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Szenttamási", 
	"child": "Joó Ibolya 1976-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "II.164/a", 
	"main": "Bencze Mózes 1945-2015", 
	"child": "Bencze Arnold 1972-", 
	"village": "", 
	"hint": "lásd II.169/c.lap"}, 
{
	"page": "", 
	"main": "Sándor Erzsébet 1945-", 
	"child": "Bencze Zsuzsánna 1973-", 
	"village": "JoóCsabáné", 
	"hint": "lásd II.180/b.lap"}, 
{
	"page": "II.164/b", 
	"main": "Balázsi Dezső 1942-", 
	"child": "Balázsi Enikő 1968-", 
	"village": "Homoródszentmárton", 
	"hint": "Nágó Domokosné"}, 
{
	"page": "", 
	"main": "Bencze Irma 1947-", 
	"child": "Balázsi Adél 1971-", 
	"village": "Homoródszentmárton", 
	"hint": "Demeter Ferencné"}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Éva 1976-", 
	"village": "Székelyudvarhely ", 
	"hint": ""}, 
{
	"page": "II.165/a", 
	"main": "id.Dáné Ézsaiás 1932-1991", 
	"child": "Dáné Ibolya 1968-", 
	"village": "Zólya Attiláné ", 
	"hint": "lásd II.173/b.lap"}, 
{
	"page": "", 
	"main": "Kádár Ibolya 1938-", 
	"child": "ifj.Dáné Ézsaiás 1970-", 
	"village": "Almás ", 
	"hint": "lásd II.176/b.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Dáné Tamás 1975-", 
	"village": "", 
	"hint": "lásd II.176/c.lap"}, 
{
	"page": "II.165/b", 
	"main": "Bencze Aladár 1941-", 
	"child": "Bencze János 1975", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "Barczán Margit 1957-", 
	"child": "Bencze Vilmos 1976-", 
	"village": "", 
	"hint": "lásd II.182/a.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Szilvia 1979-", 
	"village": "Dálya", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Katalin 1980-", 
	"village": "", 
	"hint": "lásd II.174/c.lap"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Aladár 1982-", 
	"village": "", 
	"hint": "nincs utód"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze László 1983-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Vencel 1985-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.166/a", 
	"main": "Bencze Zakariás 1948-2021", 
	"child": "Bencze Gyula Elemér 1969-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Gizella 1951-", 
	"child": "Bencze Csaba 1971-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Zakariás 1948-2021", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.166/b", 
	"main": "Balázsi Lajos 1930-", 
	"child": "Balázsi Jolán 1963-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Czerják Aranka          Lókodi ", 
	"child": "Balázsi Lajos 1967-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Sándor 1972-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Balázsi Csaba 1977-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.167/a", 
	"main": "Dáné Béla 1938-", 
	"child": "Tamás Tünde 1971-", 
	"village": "Gyepes", 
	"hint": "szerelem gyerek"}, 
{
	"page": "", 
	"main": "lásd II.72.lap", 
	"child": "Dáné Júlia 1973-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Molnár Ida 1953-2019", 
	"child": "Dáné Ágnes 1974-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.167/b ", 
	"main": "köz.Bencze Dezső 1952-", 
	"child": "Bencze Attila 1979-", 
	"village": "Homoródszentpál", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Emma 1961-2001", 
	"child": "ifj.Bencze Dezső 1982-", 
	"village": "Szentegyháza ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.139/b.lap", 
	"child": "Bencze Orsolya-Beáta 1985-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.169/a", 
	"main": "Bencze Csillag József 1943-", 
	"child": "Bencze Sándor 1970-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Joó Regina 1950-", 
	"child": "Bencze Ezster 1971-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.169/b", 
	"main": "Pál Lajos 1968-", 
	"child": "Pál Krisztina 1993-", 
	"village": "Székelyudvarhely ", 
	"hint": "lásd II.184/b.lap"}, 
{
	"page": "", 
	"main": "lásd II.149/b.lap ", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Pál Edit 1974-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.169/c", 
	"main": "Bencze Arnold 1972-", 
	"child": "Bencze Csongor 2011-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Ibolya 1978-", 
	"child": "Bencze Melánia 2003-", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Attila 2003", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "II.170/a", 
	"main": "ifj.Lőrincz Gyula 1929-2021", 
	"child": "Orbán Gábor 1964-", 
	"village": "", 
	"hint": "szerelem gyerek"}, 
{
	"page": "", 
	"main": "Bálint Piroska 1943-", 
	"child": "Lőrincz Tünde 1969-", 
	"village": "Budapest", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Telekfalvi", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.170/b", 
	"main": "Orbán Vilmos Dénes 1971-", 
	"child": "Orbán Beáta 1990-", 
	"village": "Németország ", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zsombori Emese Ida 1969-", 
	"child": "Orbán Anita 1996-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.172/a", 
	"main": "ifj.Joó Gyula 1964-", 
	"child": "Joó Dénes Lóránd 1987-", 
	"village": "Sopron", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Jónás Erika 1971-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "Nagy Hajnal 1975-", 
	"child": "Joó Roland ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "elváltak", 
	"child": "Joó Botond ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Joó Barnabás ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.172/b", 
	"main": "Joó Árpád-Levente 1965-", 
	"child": "Joó Levente-Loránd 1988-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Szakács Adél 1969-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Homoródszentpáli", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.172/c", 
	"main": "Bencze Vilmos 1976-", 
	"child": "Bencze Vilmos-Szabolcs 1999-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "György Erzsébet 1972-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Lókodi", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.173/a", 
	"main": "Orbán Gábor 1964-", 
	"child": "Orbán Norbert 1988", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Orbán Juliánna 1969-", 
	"child": "Orbán Nóra 19", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.173/b", 
	"main": "id.Zólya Attila 1959-", 
	"child": "ifj.Zólya Attila 1988-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Ibolya 1968-", 
	"child": "Zólya Hunor 1992-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.174/a", 
	"main": "Joó Szerénke 1960", 
	"child": "Simó Zsolt 1985-", 
	"village": "", 
	"hint": "természetes apától"}, 
{
	"page": "II.174/b", 
	"main": "Bencze Levente 1967-", 
	"child": "Bencze Botond 1991-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Júlia 1973-", 
	"child": "Bencze Enikő-Krisztina 1997-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.174/c", 
	"main": "Bencze András 1973-", 
	"child": "Bencze Renáta 2000-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.127/b.lap", 
	"child": "Bencze Arabella 2001-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Katalin 1980-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.165/b.lap", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.175/a", 
	"main": "Bara Balázsi Árpád 1968-", 
	"child": "Bara Balázsi Izabella 1991-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Zsombori Katalin Szidónia 1972-", 
	"child": "Bara Balázsi Tihamér 1996-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.175/b", 
	"main": "Szász Miklós 1949-2022", 
	"child": "Szász Ottilia 1985-", 
	"village": "", 
	"hint": "lásd II.183/a.lap"}, 
{
	"page": "", 
	"main": "Bencze Ottilia 1943-", 
	"child": "", 
	"village": "egyházilag nem esküdtek meg", 
	"hint": ""}, 
{
	"page": "II.175/c", 
	"main": "Joó Jenő 1930-2021", 
	"child": "Joó Jolán ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.29.lap", 
	"child": "Joó Dénes 1962-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Csortán Borbála 1937-2016", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Oroszhegyi", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.176/a", 
	"main": "Nagy Károly 1970-", 
	"child": "Nagy Tünde 1992-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Nagy Emilia 1971-", 
	"child": "Nagy Annamária 1993-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Recsenyédi", 
	"child": "Nagy Csaba 1995-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.176/b", 
	"main": "ifj.Dáné Ézsaiás 1970-", 
	"child": "Dáné Csenge 1992-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Birtalan Erika 1973-", 
	"child": "Dáné Szende-Kincső 1995-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Homoródszentpáli", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.176/c", 
	"main": "id.Dáné Tamás 1975-", 
	"child": "Dáné Barbara 2005-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "lásd II.165/a.lap", 
	"child": "ifj.Dáné Tamás 2006-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Tamás Erika 1978-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Miklósfalvi", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.177/a", 
	"main": "Nagy Márton 1969-", 
	"child": "Nagy Róbert 1993-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Márton Anikó 1971-", 
	"child": "Nagy Anita 1996-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dályai", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.177/b", 
	"main": "Joó Attila-Ferenc 1956-", 
	"child": "Joó Attila 1993-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázs Rózália 1950-", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Csíkszereda Jakabfalva", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.178/a", 
	"main": "id.Jakabfi István 1952-", 
	"child": "Lőrincz Zsolt 1979-", 
	"village": "első házaságból", 
	"hint": "lásd II.183/b.lap"}, 
{
	"page": "", 
	"main": "Bencze Vilma 1958-", 
	"child": "Jakabfi Éva 1981-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "ifj.Jakabfi István 1982-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.178/b", 
	"main": "Demeter Ferenc 1972-", 
	"child": "Demeter Henrietta ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Adél 1971-", 
	"child": "Demeter Hajnalka ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.179/a", 
	"main": "Sorbán Árpád 1969-", 
	"child": "Sorbán Nóra ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Simó Melinda 1970-", 
	"child": "Sorbán Szilárd ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.179/b", 
	"main": "köz.Barczán Balázs 1967-", 
	"child": "ifj.Barczán Balázs 1990-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Tamás Edit 1971-", 
	"child": "Barczán Szilveszter 1995-2011", 
	"village": "", 
	"hint": "öngyilkos lett fiatalon"}, 
{
	"page": "", 
	"main": "egyházilag nem esküdtek", 
	"child": "", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.180/a", 
	"main": "Bara Balázsi Attila 1970-", 
	"child": "Bara Balázsi Sándor Botond 1994-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Sata Anna Edit 1975-", 
	"child": "Bara Balázsi Lehel 1998-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.180/b", 
	"main": "Joó Csaba 1973-", 
	"child": "Joó Nándor 1996-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Zsuzsánna 1973-", 
	"child": "Joó Zoltán Csaba 1999-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.181/a", 
	"main": "Német Levente 1970-", 
	"child": "Német Róbert 1998-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Balázsi Ildikó 1973-", 
	"child": "Német Andrea 2003-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.182/b", 
	"main": "Orbán Kálmán 1980-", 
	"child": "Orbán Botond 2002-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Kovács Magda-Gabriella 1979-", 
	"child": "Orbán Orsolya 2007-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Orbán Előd 2016-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.182/b ", 
	"main": "id.Bencze Attila 1979-", 
	"child": "Bencze Szilárd 2003-", 
	"village": "Homoródszentpál", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Benő Mária ", 
	"child": "ifj.Bencze Attila 2005-", 
	"village": "Homoródszentpál", 
	"hint": ""}, 
{
	"page": "", 
	"main": "", 
	"child": "Bencze Boglárka ", 
	"village": "Homoródszentpál", 
	"hint": ""}, 
{
	"page": "II.183/a", 
	"main": "Bencze Zoltán 1978-", 
	"child": "Bencze Dávid 2008-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Szász Ottilia 1985-", 
	"child": "Bencze Dóra 2010-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.183/b", 
	"main": "Lőrincz Zsolt 1979-", 
	"child": "Lőrincz Márk 2012-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Bencze Enikő 1989-", 
	"child": "Lőrincz Rita 2016-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.184/a", 
	"main": "Joó S. Dénes 1981-", 
	"child": "Joó Nórbert 2012-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Fülöp Mária 1981-", 
	"child": "Joó Melánia ", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "", 
	"main": "Székelykeresztúri", 
	"child": "Joó Nóra ", 
	"village": "", 
	"hint": "ikrek"}, 
{
	"page": "II.184/b", 
	"main": "Magyari Attila 1988-", 
	"child": "Magyari Gergő 2017-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Pál Krisztina 1993-", 
	"child": "Magyari Rebeka ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.184/c", 
	"main": "ifj.Bencze Jakab 1958-", 
	"child": "Bencze Boróka 1982-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "1.neje", 
	"main": "Pál Berta 1968-", 
	"child": "Bencze Magor 1986-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "2.neje ", 
	"main": "?", 
	"child": "Lea ", 
	"village": "", 
	"hint": ""}, 
{
	"page": "3.neje", 
	"main": "Ildikó", 
	"child": "Horka", 
	"village": "", 
	"hint": ""}, 
{
	"page": "II.185", 
	"main": "Bencze Magor 1986-", 
	"child": "Bencze Hanga 2012-", 
	"village": "", 
	"hint": ""}, 
{
	"page": "", 
	"main": "Dáné Csilla 1989-", 
	"child": "Bencze Ármin 2016-", 
	"village": "", 
	"hint": ""}
];
